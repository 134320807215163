//This file contain cookie name and detils.

const cookieDetails = [
  {
    name: "Performance Cookies",
    details: `⁠Performance Cookies: Track user behavior and site performance to enhance the experience.`,
  },
  {
    name: "Functional Cookies",
    details: `Functionality Cookies: Store your preferences and settings to personalize content.`,
  },
  {
    name: "Targeting & Advertising Cookies",
    details: `Targeting/Advertising Cookies: Deliver relevant advertisements based on your browsing history.`,
  },
];

export default cookieDetails;
