import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import ReactCountryFlag from "react-country-flag";
import ImageTag from "../../image-tag";
import { renderValidUrl } from "../../../utils/constants";
import {
  smallYellowLinkedinIcon,
  smallYellowTwitterIcon,
  PlayVideoIcon,
  instructor3,
  smallWhiteLinkedInIcon,
  smallWhiteTwitterIcon,
  whiteFacebookIcon,
  whiteInstagramIcon,
  ukFlag,
  usFlag,
  canadaFlag,
} from "../../../images";
import styles from "./studentVideos.module.css";
import { getTestimonials } from "../../../redux/action";

const SOCIAL_ICONS = {
  "LinkedIn": smallWhiteLinkedInIcon,
  "Twitter": smallWhiteTwitterIcon,
  "FaceBook": whiteFacebookIcon,
  "Instagram": whiteInstagramIcon,
}
// Mapping of country names to country codes used by `react-country-flag`
const COUNTRY_CODES = {
  UK: 'GB',
  USA: 'US',
  Canada: 'CA',
  Nigeria: 'NG',
  Australia: 'AU',
  Germany: 'DE',
  France: 'FR',
  Italy: 'IT',
  Spain: 'ES',
  Japan: 'JP',
  China: 'CN',
  India: 'IN',
  Brazil: 'BR',
  SouthAfrica: 'ZA',
  Mexico: 'MX',
  Argentina: 'AR',
  Chile: 'CL',
  Peru: 'PE',
  Colombia: 'CO',
  Russia: 'RU',
  Turkey: 'TR',
  SaudiArabia: 'SA',
  UAE: 'AE',
  Thailand: 'TH',
  Singapore: 'SG',
  Malaysia: 'MY',
  Indonesia: 'ID',
  Vietnam: 'VN',
  Philippines: 'PH',
  SouthKorea: 'KR',
  Taiwan: 'TW',
  HongKong: 'HK',
  NewZealand: 'NZ',
  Greece: 'GR',
  Portugal: 'PT',
  Poland: 'PL',
  Netherlands: 'NL',
  Sweden: 'SE',
  Norway: 'NO',
  Denmark: 'DK',
  Finland: 'FI',
  CzechRepublic: 'CZ',
  Hungary: 'HU',
  Austria: 'AT',
  Switzerland: 'CH',
};

const StudentVideos = () => {
  const [isSelected, setIsSelected] = useState(false);
 
  const [playing, setPlaying] = useState('');
  const dispatch = useDispatch();
  const {testimonials} = useSelector(state => state);

  useEffect(() => {
    if(testimonials.length === 0){
      dispatch(getTestimonials());
    }
  }, []);

    const videoRef = useRef([]);

  // set playing to the given id
  const play = playerId => setPlaying(playerId);

  // unset the playing player
  const pause = () => setPlaying(false);

  // returns true if the given playerId is playing
  const isPlaying = playerId => playerId === playing;


  return (
    <section className={styles["section_full_black_background"]}>
      <div
        className={`${styles["section_no_background"]} ${styles["video_row"]}`}
      >
        {testimonials && testimonials?.slice(0, 3)?.map((data, index) => (
          <div
            className={styles["play_block"]}
            key={data?.id}
          >
            <div 
              className={styles["video"]}
              onClick={() => setIsSelected(!isSelected)}
              >
              <ReactPlayer
                ref={(ref) => (videoRef.current[index] = ref)}
                url={data?.videoUrl}
                width="389"
                height="100%"
                onPause={() => pause(data?.id)}
                onEnded={() => pause(data?.id)}
                onClickPreview={() => play(data?.id)}
                config={{
                  youtube: {
                    playerVars: { showinfo: 1 }
                  }
                }}
                light={data?.posterImg && renderValidUrl(data?.posterImg)}
                playing={isPlaying(data?.id)}
                controls
              /> 
            </div>
            <div className={`${styles["video-content"]}`}>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <h1 className="font-bold font-poppins text-white text-base leading-6">
                    {data?.fullName}
                  </h1>
                  {data?.social?.map((icon, index) => (
                    <a className={"ml-2"} href={icon?.url} key={index}>
                     <img src={SOCIAL_ICONS[icon?.network]} alt={icon?.network} />
                    </a>
                  ))}
                </div>
                 <ReactCountryFlag 
                  countryCode={COUNTRY_CODES[data.country] || 'US'} 
                  svg 
                  style={{
                    width: '1.5em',
                    height: '1.5em',
                  }}
                />
              </div>
              <div className="flex justify-between mb-4">
                <p className="font-poppins font-normal text-[#FCFCF5] text-xs leading-6">
                  {data?.occupation}
                </p>
                <p className="font-poppins font-normal text-[#FCFCF5] text-xs leading-6">
                  {data?.date}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default StudentVideos;