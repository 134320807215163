import styled from 'styled-components'
import { SEO, PageLayout, AccordionContainer } from "../components";
import { faqQ1, faqQ2 } from '../images'
import { FAQ_DATA } from '../data';

const TopBanner = styled.div`
  background-color: #F8D210;
  background-image: url(${faqQ1}), url(${faqQ2});
  background-repeat: no-repeat, no-repeat;
  background-size: 98px 98px, 117px 117px;
  background-position: top 8px left 59px, bottom 18px right 45px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 30px;
  width: 100%;
  height: 220px;
  gap: 8px;
  border-radius: 4px;

  @media only screen and (max-width: 992px) {
    margin-top: 70px;
    background-image: none;
    border-radius: 0;
  }
`

const FaqContainer = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
  border-radius: 4px;
  margin: 50px auto;
  padding: 10px;
`

function FAQ() {
  return (
    <>
      <SEO
        url="/faq" 
        openGraphType="website"
        schemaType="website"
        title="FAQ"
        description="We help you transition into tech seamlessly."
      />
      <PageLayout>
         <div className="bg-gray w-full">
          <div className='max-w-[1200px] m-auto'>
            <TopBanner>
              <h1 className='font-bold text-2xl sm:text-4xl text-center'>
                Frequently Asked <br />Questions
              </h1>
            </TopBanner>
            <div className='px-4'>
              <FaqContainer>
                <AccordionContainer data={FAQ_DATA} openFirst />
              </FaqContainer>

            </div>
            <div className='bg-black py-20 px-6 w-full text-white my-16 text-center 
            text-[20px] sm:text-[28px] leading-[30px] sm:leading-[36px] sm:rounded-lg rounded-none'>
              <h2>Can’t find the answer to your question? <br />
                Feel free to contact us at <span className='text-[#F8D210]'>info@theskillgarage.com</span>
              </h2>
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  );
}

export default FAQ;