import { SEO, PageLayout, Contact } from "../components";

function ContactPage() {
    return (
        <>
          <SEO
            url="/contact-partner"
            openGraphType="website"
            schemaType="website"
            title="Contact Page (Partner With Us)"
            description="Contact Us Page (Partner With Us)"
          />
          <PageLayout>
            <div className="bg-[#FCFCF5] w-full">
              <Contact title={"Partner with us"} partner={"partner"}/>
            </div>
          </PageLayout>
        </>
      );
  }
  
  export default ContactPage;