import React, { useEffect, useState } from 'react';
import Carousel from '../../carousel';
import styles from './start-learning.module.css';
import { useSelector, useDispatch } from "react-redux";
import { getCourseCarouselData } from '../../../redux/action';

const StartLearning = () => {
  const dispatch = useDispatch();
  const { courseCarouselData } = useSelector(state => state);

  useEffect(() => {
    dispatch(getCourseCarouselData());
  }, []);

  const { courseCards } = courseCarouselData || {};

  const sliderBlockClasses = [styles['slider_block']];

  if (courseCards && courseCards.length < 3) {
    sliderBlockClasses.push(styles['justify-end']);
  }

  return (
    <div className={styles['courses_row']}>
      <div className={styles['description_courses_block']}>
        <div className={styles['description_courses_title']} id="start__learning__block">
          Start Learning
        </div>
        <div className={styles['description_courses_text']}>
          Take control of your future. Gain in-demand tech skills with our expert-led live bootcamps. Benefit from hands-on learning and ongoing support. Start your journey today and elevate your career to the next level.
        </div>
      </div>
      <div className={sliderBlockClasses.join(' ')}>
        <Carousel items={courseCards} arrowColor={"white"} />
      </div>
    </div>
  );
};

export default StartLearning;
