import { SEO, PageLayout, RefundPolicy } from "../components";

export default function Refund() {
  return (
    <>
      <SEO
        url="/refund-policy"
        title="Refund Policy"
      />
      <PageLayout>
        <div className="bg-gray w-full">
          <RefundPolicy />
        </div>
      </PageLayout>
    </>
  )
}