import React from 'react';
import styles from './banner.module.css';

const Banner = () => {
  return (
    <section className={styles["section_no_background"]}>
    <div className={styles["top_banner"]}>
      <div className={styles["inf_block"]}>
        <div className={styles["banner_title"]}>
          Students Success Stories
        </div>
        <div className={styles["banner_text"]}>
          TheSkillGarage has empowered over 300 professionals around the world, helping them develop the digital skills they need to boost their careers.
        </div>
      </div>
    </div>
  </section>
  )
}

export default Banner;