import React from 'react';
import styled from 'styled-components';
import Card from './cards';
import NewsletterForm from '../../subscribeNewsletter';
import DropdownButton from '../dropdown';
import { BLOG_CARD_DATA } from '../../../data';

const CardContainer = ({ visibleCardCount }) => {
  const [selectedCategories, setSelectedCategories] = React.useState([]);

  const handleCategorySelect = (categories) => {
    setSelectedCategories(categories);
  };

  // Filter the cards based on the selected categories
  const filteredCards = selectedCategories.length > 0 
    ? BLOG_CARD_DATA.filter(card => selectedCategories.includes(card.category))
    : BLOG_CARD_DATA;

  return (
    <>
      <DropdownStyle>
        <DropdownButton onCategorySelect={handleCategorySelect} />
      </DropdownStyle>

      <StyledCardContainer>
        {filteredCards.slice(0, visibleCardCount).map((cardData, index) => (
          <Card key={index} {...cardData} />
        ))}
        <div className="newsletter">
          <NewsletterForm />
        </div>
      </StyledCardContainer>
    </>
  );
};

const DropdownStyle = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: right;
  margin-top: 24px;
  margin-bottom: 24px;

  @media only screen and (max-width: 992px) {
    padding: 0px 16px;
  }
`;

const StyledCardContainer = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  row-gap: 40px;
  column-gap: 30px;

  .newsletter {
    grid-column: 3 / 4; /* Position the newsletter in the third column */
    grid-row: 2 / 3; /* Position the newsletter in the second row */
  }

  @media only screen and (max-width: 992px) {
    flex-flow: wrap;
    width: 100vw;
    align-items: center;
    padding: 0px 16px;
    display: flex;
    justify-content: space-around;

    .newsletter {
      grid-column: 
      grid-row: 
    }
  }
`;

export default CardContainer;
