import React from "react";
import styled from "styled-components";
import LiveQAForm from "./live-qa-form";

const LiveWrapper = () => {
  return (
    <Container>
      <LeftDiv>
        <h1>Join us for a Live Q & A Session</h1>
        <p>
          Your opportunity to seek clarification about the training, cost,
          practicum, experience, career support, coaching, etc. You can ask any
          question you would like to ask.
        </p>
      </LeftDiv>
      <LiveQAForm />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 144px;
  width: min(100%, 1200px);
  margin: 120px auto;
  padding: 32px;
  background: #f8d210;
  box-sizing: border-box;

  @media (max-width: 1340px) {
    gap: 20px;
  }
  
  @media (max-width: 1200px) {
    margin: 74px auto;
  }

  @media (max-width: 940px) {
    display: block;
    padding-right: 74px;
    padding-left: 74px;
  }

  @media (max-width: 642px) {
    margin: 0;
    padding-left: 38px;
    padding-right: 38px;
  }

  @media (max-width: 440px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const LeftDiv = styled.div`
  margin-top: 74px;
  margin-left: 74px;

  @media (max-width: 1164px) {
    margin-left: 0;
  }

  @media (max-width: 940px) {
    margin-top: 0;
    margin-bottom: 24px;
  }

  @media (max-width: 642px) {
    margin-top: 74px;
  }

  h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    color: #000000;

    @media(max-width: 450px) {
      font-size: 35px;
      line-height: 42px;
    }
  }

  p {
    margin-top: 16px;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #000000;

    @media(max-width: 450px) {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;

export default LiveWrapper;
