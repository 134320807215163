import React from "react";
import { PASSWORDLIST } from "../../data";
import { BlackEllipse } from "../../images";

const PasswordChecker = ({ isValid, passwordValue }) => {

  // Function to check if a password meets a requirement
  const meetsRequirement = (value, requirement) => {
    switch (requirement) {
      case "length":
        return value.length >= 8;
      case "uppercase n lowercase":
        return /(?=.*[a-z])(?=.*[A-Z])/.test(value);
      case "specialCharacter":
        return /[@#$%^&+=!]/.test(value);
      default:
        return true;
    }
  };

  return (
    <>
      {" "}
      {!isValid && (
        <>
          <p className="text-sm text-[#D8D8D8] mt-[-0.5rem]">
            Password must include
          </p>
          {PASSWORDLIST.map((requirement) => (
            <div
              key={requirement.name}
              className="flex items-center mt-[0.3rem]"
            >
              <img
                src={
                  passwordValue?.length === 0
                    ? BlackEllipse
                    : meetsRequirement(passwordValue, requirement.name)
                    ? requirement.icon
                    : BlackEllipse
                }
                alt={requirement.name}
                loading="lazy"
                className="w-[8px] h-[8px]"
              />
              <p className="text-sm ml-[0.5rem] text-[#D8D8D8]">
                {requirement.description}
              </p>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default PasswordChecker;
