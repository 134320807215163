import React, {useRef, useEffect, useState}  from "react";
import styles from "./about-acheivement.module.css";

const Achievements = () => {
  const [isVisible, setIsVisible] = useState(false);
  const animatedRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        root: null,
        threshold: 0.5, // adjust this value as needed
      }
    );

    observer.observe(animatedRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  
  return (
    <section
      className={`${styles["section_card_background"]} ${styles["three_section_margin"]}`}
    >
      <div className={styles["section_no_background"]}>
        <div className={styles["body_card_section"]}>
          <div className={styles["inf_block_in_body"]}>
            <div className={styles["title_two_type"]}>Achievements</div>

            <div className={styles["text_story"]}>
              We have taught and helped hundreds launch their 
              tech careers through our free classes  and paid bootcamp programs. 
            </div>
          </div>
          <div className={styles["big_size_inf_row"]} ref={animatedRef}>
            <div className={styles["sub_block"]}>
              <div className={styles["item_block"]}>
                <div className={styles["number_block"]}>
  <div className={`${styles["item"]} ${styles["num"]}`}>
    <div className={isVisible ? styles["roll_first"] : ""}>
      <div style={{ marginLeft: "0.0rem" }}>8</div> {/* First digit: 8 */}
    </div>
  </div>
  <div className={`${styles["item"]} ${styles["num"]}`}>
    <div className={isVisible ? styles["roll_second"] : ""}>
      <div>4</div> {/* Second digit: 4 */}
    </div>
  </div>
  <div className={`${styles["item"]} ${styles["num"]}`}>
    <div className={isVisible ? styles["roll_third"] : ""}>
      <div>7</div> {/* Third digit: 7 */}
    </div>
  </div>
  <div className={styles["item"]}>+</div>
</div>
<div className={styles["item_text"]}>Training done</div>

              </div>

              <div
                className={`${styles["item_block"]} ${styles["second_item"]}`}
              >
                <div className={styles["number_block"]}>
                  <div className={`${styles["item"]} ${styles["num"]}`}>
                    <div className={isVisible ? styles["roll_three"] : ""}>
                      <div style={{ marginLeft: "0.0rem" }}>4</div>
                      <div>0</div>
                      <div style={{ textAlign: "right" }}>4</div>
                      <div>2</div>
                      <div>3</div>
                      <div>2</div>
                      <div>0</div>
                    </div>
                  </div>
                  <div className={`${styles["item"]} ${styles["num"]}`}>
                    <div className={isVisible ? styles["roll_foo"] : ""}>
                      <div>0</div>
                      <div>7</div>
                      <div>6</div>
                      <div>5</div>
                      <div>4</div>
                      <div>3</div>
                      <div>2</div>
                      <div style={{ textAlign: "right" }}>0</div>
                      <div>5</div>
                    </div>
                  </div>
                  <div className={`${styles["item"]} ${styles["num"]}`}>
                    <div className={isVisible ? styles["roll_five"] : ""}>
                      <div>0</div>
                      <div>8</div>
                      <div>7</div>
                      <div>6</div>
                      <div>5</div>
                      <div>4</div>
                      <div>3</div>
                      <div>2</div>
                      <div style={{ textAlign: "right" }}>1</div>
                      <div>5</div>
                      <div style={{ textAlign: "right" }}>1</div>
                      <div>2</div>
                      <div>3</div>
                      <div>4</div>
                      <div>5</div>
                    </div>
                  </div>
                  <div className={styles["item"]}>+</div>
                </div>

                <div
                  className={styles["item_text"]}
                  style={{ marginLeft: "1.2rem" }}
                >
                  Global Alumni
                </div>
              </div>
            </div>
            <div className={styles["sub_block"]}>
              <div className={styles["item_block"]}>
                <div className={styles["number_block"]}>
                  <div className={`${styles["item"]} ${styles["num"]}`}>
                    <div className={isVisible ? styles["roll_five"] : ""}>
                      <div>8</div>
                      <div>8</div>
                      <div>7</div>
                      <div>6</div>
                      <div>5</div>
                      <div>4</div>
                      <div>3</div>
                      <div>2</div>
                      <div style={{ textAlign: "right" }}>1</div>
                      <div>0</div>
                      <div style={{ textAlign: "right" }}>1</div>
                      <div>2</div>
                      <div>3</div>
                      <div>4</div>
                      <div>5</div>
                    </div>
                  </div>
                  <div className={`${styles["item"]} ${styles["num"]}`}>
                    <div className={isVisible ? styles["roll_foo"] : ""}>
                      <div>4</div>
                      <div>7</div>
                      <div>6</div>
                      <div>5</div>
                      <div>4</div>
                      <div>3</div>
                      <div>2</div>
                      <div style={{ textAlign: "right" }}>1</div>
                      <div>0</div>
                    </div>
                  </div>
                  <div className={styles["item"]}>%</div>
                </div>

                <div className={styles["item_text"]}>Graduate Employment</div>
              </div>

              <div className={styles["item_block"]}>
                <div className={styles["number_block"]}>
                  <div className={`${styles["item"]} ${styles["num"]}`}>
                    <div className={isVisible ? styles["roll_six"] : ""}>
                      <div style={{ marginLeft: "1rem" }}>1</div>
                      <div>0</div>
                      <div>1</div>
                      <div>2</div>
                      <div>3</div>
                      <div>2</div>
                      <div>0</div>
                    </div>
                  </div>
                  <div className={`${styles["item"]} ${styles["num"]}`}>
                    <div className={isVisible ? styles["roll_five"] : ""}>
                      <div>0</div>
                      <div>9</div>
                      <div>8</div>
                      <div>7</div>
                      <div>6</div>
                      <div>5</div>
                      <div>4</div>
                      <div>3</div>
                      <div>2</div>
                      <div style={{ textAlign: "right" }}>1</div>
                      <div>0</div>
                      <div style={{ textAlign: "right" }}>1</div>
                      <div>2</div>
                      <div>3</div>
                      <div>4</div>
                    </div>
                  </div>
                  <div className={`${styles["item"]} ${styles["num"]}`}>
                    <div className={isVisible ? styles["roll_seven"] : ""}>
                      <div>0</div>
                      <div>9</div>
                      <div>8</div>
                      <div>7</div>
                      <div>6</div>
                      <div>5</div>
                      <div>4</div>
                      <div>3</div>
                      <div>2</div>
                      <div style={{ textAlign: "right" }}>1</div>
                      <div>0</div>
                      <div style={{ textAlign: "right" }}>1</div>
                      <div>2</div>
                      <div>3</div>
                      <div>4</div>
                      <div>5</div>
                      <div>6</div>
                      <div>7</div>
                      <div>8</div>
                    </div>
                  </div>
                  <div className={styles["item"]}>%</div>
                </div>

                <div
                  className={styles["item_text"]}
                  style={{ marginLeft: "1.2rem" }}
                >
                  Experienced instructors
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Achievements;