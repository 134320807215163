import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import ArrowRight from '../../images/bg/arrowCircleRight.svg'
import ArrowLeft from '../../images/bg/arrowCircleLeft.svg'

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './aboutStory.module.css'

const AboutStoryCarousel = ({ items }) => {
  const sliderRef = useRef(null);
  const topStrokeRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setActiveIndex(next);
      const direction = next > current ? 'right' : 'left';
      scrollTopStroke(direction); // Trigger scroll based on slide direction
    },
    afterChange: setCurrentSlide,
  };

  // Function to scroll top stroke container
  const scrollTopStroke = (direction) => {
    const container = topStrokeRef.current;
    if (container) {
      const scrollAmount = direction === 'left' ? -100 : 100;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const barWidth = 100 / items.length;
  const barPosition = (currentSlide + 0) * barWidth;

  const nextSlide = () => {
    sliderRef.current.slickNext();
    scrollTopStroke('right');
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
    scrollTopStroke('left');
  };

  useEffect(() => {
    const leftArrow = document.querySelector("#arrow_left");
    const rightArrow = document.querySelector("#arrow_right");

    if (currentSlide === 0) {
      leftArrow.style.display = "none";
    } else {
      leftArrow.style.display = "block";
    }

    if (currentSlide === items.length - 1) {
      rightArrow.style.display = "none";
    } else {
      rightArrow.style.display = "block";
    }
  }, [currentSlide, items.length]);

  // Handle click on bar (pagination)
  const handleClickBar = (event, index) => {
    event.preventDefault();
    const direction = index > currentSlide ? 'right' : 'left';
    scrollTopStroke(direction); // Trigger scroll based on click
    sliderRef.current.slickGoTo(index);
    setCurrentSlide(index);
    setActiveIndex(index);
  };

  const handleClickPag = (event) => {
    const barContainerWidth = event.currentTarget.offsetWidth;
    const clickPositionX = event.clientX - event.currentTarget.getBoundingClientRect().left - (barContainerWidth / 2);
    const clickPercent = clickPositionX / barContainerWidth + 0.5;
    const newSlideIndex = Math.floor(clickPercent * items.length);
    
    const direction = newSlideIndex > currentSlide ? 'right' : 'left';
    scrollTopStroke(direction); // Trigger scroll based on click
    
    const newBarPosition = (newSlideIndex * barWidth) + (barWidth / 2);
    setCurrentSlide(newSlideIndex);
    sliderRef.current.slickGoTo(newSlideIndex);

    const bar = document.querySelector(`.${styles.bar}`);
    bar.style.left = `${newBarPosition}%`;
  };

  return (
    <section className={styles["section_no_background"]}>
      <h3 className="sss_title"> Our Story </h3>
      <div ref={topStrokeRef} className={styles['top_stroke']}>
        {items.map((item, index) => (
          <button
            key={index}
            onClick={(e) => handleClickBar(e, index)}
            className={index === activeIndex ? styles['active'] : ''}
          >
            {item.year}
          </button>
        ))}
        <div className={styles['bottom_line']}></div>
      </div>

      <div className={styles["student_success_stories"]}>
        <div className={styles["sss_carousel_row"]}>
          <div className="carousel_body">
            <div className="slider-container">
              <Slider ref={sliderRef} {...settings}>
                {items.map((item) => (
                  <>
                    <div className={styles['top_margin']}>
                      <h3 className={styles['year_title']}>{item.yearTitle}</h3>
                      <span className={styles['long_stroke']}></span>
                    </div>
                    <div className={styles['content_container']}>
                      <div className={styles['desc_wrapper']}>
                        <p className={styles['first_desc']}>{item.firstDesc}</p>
                        {item.secondDesc && <p className={styles['second_desc']}>{item.secondDesc}</p>}
                      </div>
                      <div className={styles['content_right']}>
                        <img className={styles['content_img']} src={item.imageUrl} alt="heroImg" loading='lazy'/>
                      </div>
                    </div>
                  </>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <div className={styles["pagination"]}>
        <div className={styles.barContainer} onClick={handleClickPag}>
          <div className={styles.bar} style={{ width: `${barWidth}%`, left: `${barPosition}%` }} />
        </div>
      </div>

      <div id='arrow_left' className={styles["flex_btn_left"]}>
        <button onClick={prevSlide}>
          <img src={ArrowLeft} alt="arrow left" loading='lazy'/>
        </button>
      </div>
      <div id='arrow_right' className={styles['flex_btn_right']}>
        <button onClick={nextSlide}>
          <img src={ArrowRight} alt="arrow right" loading='lazy'/>
        </button>
      </div>
    </section>
  );
};

export default AboutStoryCarousel;
