import React from 'react';
import styles from './numberRoll.module.css';

const NumberRoll = () => {
  return (
    <div className={styles["numbers_block"]}>
    <div className={styles["sub_block"]}>
      <div className={styles["item_block"]}>
        <div className={styles["number_block"]}>
          <div className={`${styles['item']} ${styles['num']}`}>
            <div className={styles["roll_first"]}>
              <div style={{marginLeft: "0"}}>
                8
              </div>
              <div style={{textAlign: "right"}}>
                1
              </div>
              <div>
                4
              </div>
            </div>
          </div>
          <div className={`${styles['item']} ${styles['num']}`}>
            <div className={styles["roll_second"]}>
              <div>
                4
              </div>
              <div>
                0
              </div>
              <div style={{textAlign: "right"}}>
                1
              </div>
              <div>
                2
              </div>
              <div>
                7
              </div>
              <div>
                8
              </div>
              <div>
                9
              </div>
              <div>
                0
              </div>
              <div style={{textAlign: "right"}}>
                1
              </div>
              <div>
                2
              </div>
              <div>
                3
              </div>
            </div>
          </div>
          <div className={`${styles['item']} ${styles['num']}`}>
            <div className={styles["roll_second"]}>
              <div>
                7
              </div>
              <div>
                0
              </div>
              <div style={{textAlign: "right"}}>
                1
              </div>
              <div>
                2
              </div>
              <div>
                7
              </div>
              <div>
                8
              </div>
              <div>
                9
              </div>
              <div>
                0
              </div>
              <div style={{textAlign: "right"}}>
                1
              </div>
              <div>
                2
              </div>
              <div>
                3
              </div>
            </div>
          </div>
          <div className={`${styles['item']} ${styles['num']}`}>
            <div className={styles["roll_second"]}>
             <div className={styles["item"]}>+</div>
              <div>
                0
              </div>
              <div style={{textAlign: "right"}}>
                1
              </div>
              <div>
                2
              </div>
              <div>
                7
              </div>
              <div>
                8
              </div>
              <div>
                9
              </div>
              <div>
                0
              </div>
              <div style={{textAlign: "right"}}>
                1
              </div>
              <div>
                2
              </div>
              <div>
                3
              </div>
            </div>
          </div>
        </div>

        <div className={styles["item_text"]}>
          Training done
        </div>
      </div>

      <div className={`${styles['item_block']} ${styles['second_item']}`}>
        <div className={styles["number_block"]}>
          <div className={`${styles['item']} ${styles['num']}`}>
            <div className={styles["roll_three"]}>
              <div style={{marginLeft: "0"}}>
                4
              </div>
              <div>
                0
              </div>
              <div style={{textAlign: "right"}}>
                1
              </div>
              <div>
                2
              </div>
              <div>
                3
              </div>
              <div>
                2
              </div>
              <div>
                0
              </div>
            </div>
          </div>
          <div className={`${styles['item']} ${styles['num']}`}>
            <div className={styles["roll_foo"]}>
              <div>
                0
              </div>
              <div>
                7
              </div>
              <div>
                6
              </div>
              <div>
                5
              </div>
              <div>
                4
              </div>
              <div>
                3
              </div>
              <div>
                2
              </div>
              <div style={{textAlign: "right"}}>
                1
              </div>
              <div>
                0
              </div>
            </div>
          </div>
          <div className={`${styles['item']} ${styles['num']}`}>
            <div className={styles["roll_five"]}>
              <div>
                0
              </div>
              <div>
                8
              </div>
              <div>
                7
              </div>
              <div>
                6
              </div>
              <div>
                5
              </div>
              <div>
                4
              </div>
              <div>
                3
              </div>
              <div>
                2
              </div>
              <div style={{textAlign: "right"}}>
                1
              </div>
              <div>
                0
              </div>
              <div style={{textAlign: "right"}}>
                1
              </div>
              <div>
                2
              </div>
              <div>
                3
              </div>
              <div>
                4
              </div>
              <div>
                5
              </div>
            </div>
          </div>
          <div className={styles["item"]}>+</div>
        </div>

        <div className={styles["item_text"]} style={{marginLeft: '1.3rem'}}>
          Global Alumni
        </div>
      </div>
    </div>
    <div className={styles["sub_block"]}>
      <div className={styles["item_block"]}>
        <div className={styles["number_block"]}>
          <div className={`${styles['item']} ${styles['num']}`}>
            <div className={styles["roll_five"]}>
              <div>
                8
              </div>
              <div>
                8
              </div>
              <div>
                7
              </div>
              <div>
                6
              </div>
              <div>
                5
              </div>
              <div>
                4
              </div>
              <div>
                3
              </div>
              <div>
                2
              </div>
              <div style={{textAlign: "right"}}>
                1
              </div>
              <div>
                0
              </div>
              <div style={{textAlign: "right"}}>
                1
              </div>
              <div>
                2
              </div>
              <div>
                3
              </div>
              <div>
                4
              </div>
              <div>
                5
              </div>
            </div>
          </div>
          <div className={`${styles['item']} ${styles['num']}`}>
            <div className={styles["roll_foo"]}>
              <div>
                4
              </div>
              <div>
                7
              </div>
              <div>
                6
              </div>
              <div>
                5
              </div>
              <div>
                4
              </div>
              <div>
                3
              </div>
              <div>
                2
              </div>
              <div style={{textAlign: "right"}}>
                1
              </div>
              <div>
                0
              </div>
            </div>
          </div>
          <div className={styles["item"]}>%</div>
        </div>

        <div className={styles["item_text"]}>
          Graduate Employment
        </div>
      </div>

      <div className={styles["item_block"]}>
        <div className={styles["number_block"]}>
          <div className={`${styles['item']} ${styles['num']}`}>
            <div className={styles["roll_six"]}>
              <div style={{marginLeft: "1rem"}}>
                1
              </div>
              <div>
                0
              </div>
              <div>
                1
              </div>
              <div>
                2
              </div>
              <div>
                3
              </div>
              <div>
                2
              </div>
              <div>
                0
              </div>
            </div>
          </div>
          <div className={`${styles['item']} ${styles['num']}`}>
            <div className={styles["roll_five"]}>
              <div>
                0
              </div>
              <div>
                9
              </div>
              <div>
                8
              </div>
              <div>
                7
              </div>
              <div>
                6
              </div>
              <div>
                5
              </div>
              <div>
                4
              </div>
              <div>
                3
              </div>
              <div>
                2
              </div>
              <div style={{textAlign: "right"}}>
                1
              </div>
              <div>
                0
              </div>
              <div style={{textAlign: "right"}}>
                1
              </div>
              <div>
                2
              </div>
              <div>
                3
              </div>
              <div>
                4
              </div>
            </div>
          </div>
          <div className={`${styles['item']} ${styles['num']}`}>
            <div className={styles["roll_seven"]}>
              <div>
                0
              </div>
              <div>
                9
              </div>
              <div>
                8
              </div>
              <div>
                7
              </div>
              <div>
                6
              </div>
              <div>
                5
              </div>
              <div>
                4
              </div>
              <div>
                3
              </div>
              <div>
                2
              </div>
              <div style={{textAlign: "right"}}>
                1
              </div>
              <div>
                0
              </div>
              <div style={{textAlign: "right"}}>
                1
              </div>
              <div>
                2
              </div>
              <div>
                3
              </div>
              <div>
                4
              </div>
              <div>
                5
              </div>
              <div>
                6
              </div>
              <div>
                7
              </div>
              <div>
                8
              </div>
            </div>
          </div>
          <div className={styles["item"]}>%</div>
        </div>

        <div className={styles["item_text"]} style={{marginLeft: '1.5rem'}}>
          Experienced instructors
        </div>
      </div>
    </div>
  </div>
  )
}

export default NumberRoll