import {
  blackLinkedInIcon,
  blackTwitterIcon,
  blackFacebookIcon,
  blackInstagramIcon,
} from "../../../images";

const SOCIAL_ICONS = {
  FaceBook: blackFacebookIcon,
  Instagram: blackInstagramIcon,
  Twitter: blackTwitterIcon,
  LinkedIn: blackLinkedInIcon,
};

export const ArrayOfImages = (data) => {
  const arrayList = data?.map((data) => {
    return {
      id: data?.id,
      name: data?.name,
      photo: data?.photo,
    };
  });
  return arrayList;
};

export const SocialIcons = (social) => {
  return (
    <img src={SOCIAL_ICONS[social.network]} alt={`${social?.network} icon`} loading="lazy"/>
  );
};
