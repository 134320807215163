import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import {
  callIcon,
  mailIcon,
  linkedinIcon,
  instagramIcon,
  telegramIcon,
  whatsappIcon,
  facebookIcon,
  twitterIcon,
} from '../../images';
import styles from './footer.module.css';
import { MENU_FOOTER } from '../../data';
import Button from '../button';
import Promotion from '../modals/Promotion/Promotion';
import { postRequest } from '../../redux/action';

const Footer = () => {
  const [isPromoPopUp, setPromoPopUp] = useState(false);
  const [email, setEmail] = useState('');

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };
  function popUpTest() {
    setPromoPopUp(true);
  }

  function onClose() {
    setPromoPopUp(false);
  }

const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const [success, responseData] = await postRequest("/api/news-letters", { data: { email } });
    
    if (success) {
      toast.success("Subscription successful");
      setEmail(''); // Clear the email input
    } else {
      console.log("Subscription failed: ", JSON.stringify(responseData));  // Log the full response
      
      // Use JSON.stringify for a readable format in the toast
      const errorMessage = responseData.error?.message || JSON.stringify(responseData.error) || "Email Already Exists";
      toast.error(errorMessage);  // Display the error message
    }
  } catch (error) {
    console.error("Error during subscription:", error);
    toast.error("An unexpected error occurred");  // Handle unexpected errors
  }
};




  return (
    <section className={styles['footer']}>
      <div className={styles['first_footer_row']}>
        <div className={styles['first_row_info']}>
          {MENU_FOOTER.map((item, index) => (
            <div key={index} className={styles['info_block']}>
              <div key={index} className={styles['info_block_title']}>
                {item.name}
              </div>

              {item.multi ? (
                <div
                  key={index}
                  className={`${styles['multi_item']} ${styles['info_block']}`}
                >
                  {item.subMenu.map((sub_item, index) => (
                    <div key={index} className={styles['sub_menu']}>
                      <div className={styles['sub_sub']}>
                        <div className={styles['sub_title']}>
                          {sub_item.name}
                        </div>
                        {sub_item.menu &&
                          sub_item.menu.map((menu, index) => (
                            <Link
                              key={index}
                              to={menu.link}
                              className={styles['sub_sub_item_text']}
                            >
                              {menu.name}
                            </Link>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  key={index}
                  className={`${styles['no_multi_item']} ${styles['info_block']}`}
                >
                  {item.subMenu.map((sub_item, index) => (
                    <Link
                      key={index}
                      to={sub_item.link}
                      className={styles['sub_sub_item_text']}
                    >
                      {sub_item.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className={styles['contacts_info']}>
          <div className={styles['subscribe_box']}>
            <div className={styles['description']}>
              Get exclusive news about upcoming programs, panels and events.
            </div>

           <form onSubmit={handleSubmit}>
              <div className={styles['email_input']}>
                <label htmlFor="email">Email</label>

                <input
                  type="email"
                  id="email"
                  placeholder="Example@example.com"
                  className={styles['input']}
                  onChange={handleInputChange}
                  value={email}
                />
              </div>
            <Button
              size
              variant="secondary"
              icon="blackArrow"
              type='submit'
            >
              Subscribe for Newsletter
            </Button>
            </form>

          </div>
          <div className={styles['email_and_phone']}>
            <a href="tel:204-202-7829">
              <img src={callIcon} title="icon" alt="call-icon" loading='lazy' />
              204-202-7829
            </a>

            <a href="mailto:info@theskillgarage.com">
              <img src={mailIcon} title="icon" alt="mail-icon" loading='lazy' />
              info@theskillgarage.com
            </a>
          </div>
        </div>
      </div>
      <div className={styles['last_footer_row']}>
        <div className={styles['last_row_info']}>
          <div className={styles['info_title']}>
            {new Date().getFullYear()} TheSkillGarage
          </div>

          <Link to="/terms-and-conditions" className={styles['info_title']}>
            Terms of use
          </Link>

          <Link to="/refund-policy" className={styles['info_title']}>
           Refund Policy
          </Link>
          <Link to="/privacy-policy" className={styles['info_title']}>
           Privacy Policy
          </Link>
          <Link to="/cookie-policy" className={styles['info_title']}>
           Cookie Policy
          </Link>
        </div>

        <div className={styles['social_block']}>
  <a href="https://api.whatsapp.com/message/V2IGTURYUJC2B1" target="_blank" title="WhatsApp" className={styles['btn social_icon']}>
    <img src={whatsappIcon} title="WhatsApp" alt="whatsapp-icon" loading='lazy' />
  </a>

  <a href="https://x.com/theskillgarage" title="Twitter" target="_blank" className={styles['btn social_icon']}>
    <img src={twitterIcon} title="Twitter" alt="twitter-icon" loading='lazy'/>
  </a>

  <a href="https://www.linkedin.com/company/theskillgarage" target='_blank' title="LinkedIn" className={styles['btn social_icon']}>
    <img src={linkedinIcon} title="LinkedIn" alt="linkedin-icon" loading='lazy'/>
  </a>

  <a href="https://www.instagram.com/theskillgarage" target='_blank' title="Instagram" className={styles['btn social_icon']}>
    <img src={instagramIcon} title="Instagram" alt="instagram-icon" loading='lazy'/>
  </a>

  <a href="https://www.facebook.com/theskillgarage/" target='_blank' title="Facebook" className={styles['btn social_icon']}>
    <img src={facebookIcon} title="Facebook" alt="facebook-icon" loading='lazy'/>
  </a>

  {/* <a href="https://t.me/+PT-H1KZ1G1QzODc0" title="Telegram" target='_blank' className={styles['btn social_icon']}>
    <img src={telegramIcon} title="Telegram" alt="telegram-icon" />
  </a> */}
</div>

      </div>
      {isPromoPopUp && <Promotion onClose={onClose} />}
    </section>
  );
};

export default Footer;
