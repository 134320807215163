import { SEO, PageLayout, TermsCondition } from "../components";

export default function TermsAndCondition() {
  return (
    <>
    <SEO
      url="/terms-and-conditions" 
      title="Terms and Condition"
    />
    <PageLayout>
      <div className="bg-gray w-full">
        <TermsCondition />
      </div>
    </PageLayout>
  </>
  )
}