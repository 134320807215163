import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './tsg.module.css'

const BG_STYLES = {
  slopeLeftLow: style['bg-left-slope'],
  slopeLeftHigh: style['bg-left-slope-high'],
  slopeRight: style['bg-right-slope']
}

export default function TSGBg({children, bgStyle='slopeLeftHigh', className, ...rest}) {
  const classes = classNames(
    BG_STYLES[bgStyle],
    className
  )
  return (
    <section className={classes} {...rest} >
      {children}
    </section>
  )
}

TSGBg.propTypes = {
  bgStyle: PropTypes.string
}