import React from 'react';
import Button from '../button';
import styles from './telegram.module.css';

const TelegramFooter = () => {
  return (
    <section className={styles['section_no_background']}>
        <div className={styles['footer_banner']}>
            <div className={styles['cta_wrapper']}>
                <p>For free resources and trainings </p>
                <a href="https://chat.whatsapp.com/Eh8Cw4HDftp1nTQq9NftsB" target="_blank" rel="noopener noreferrer">
                  <Button icon={'telegram'} outline="white" className={styles['cta_button']}>Join our community</Button>
                  </a>
            </div>
        </div> 
    </section>
  )
}

export default TelegramFooter;