import { 
  Card1,
  card2,
  card3,
  card4,
  card5,
  card6,
  card7, 
  card8
} from "../images";

export const BLOG_CARD_DATA = [
  {
    category: "Self Development",
    image: Card1, 
    author: "Anthony Chow",
    date: "July 25, 2022",
    readTime: "5 min to read",
    title: "Business Analysis Bootcamp Business",
    description: "Course description goes here description here Course descript here description here Course...",
    link: `/resources/blog/${encodeURIComponent("Business Analysis Bootcamp Business")}`
  },
  {
    category: "Design",
    image: card2, 
    author: "Isabel Cokely",
    date: "July 28, 2022",
    readTime: "3 min to read",
    title: "Design Thinking Bootcamp",
    description: "Course description goes here description here Course descript here description here Course...",
    link: `/resources/blog/${encodeURIComponent("Design Thinking Bootcamp")}`
  },
  {
    category: "Business",
    image: card3, 
    author: "Michael Alphon",
    date: "July 30, 2022",
    readTime: "6 min to read",
    title: "Business Analysis Bootcamp",
    description: "Course description goes here description here Course descript here description here Course...",
    link: `/resources/blog/${encodeURIComponent("Business Analysis Bootcamp")}`
  },
  {
    category: "Management",
    image: card4, 
    author: "Michael Alphon",
    date: "July 20, 2024",
    readTime: "6 min to read",
    title: "How to Land a Six-Figure Business Analysis Job",
    description: "Transitioning from Another Career to Business Analysis",
    link: `/resources/blog/${encodeURIComponent("How to Land a Six-Figure Business Analysis Job")}`
  },
  {
    category: "Self Development",
    image: card5, 
    author: "Michael Akpan",
    date: "July 30, 2024",
    readTime: "6 min to read",
    title: "Self Development Bootcamp",
    description: "In-Demand Business Analyst Career Paths and Common Challenges",
    link: `/resources/blog/${encodeURIComponent("Self Development Bootcamp")}`
  },
  // {
  //   category: "Business",
  //   image: card6, 
  //   author: "Michael Alphon",
  //   date: "July 30, 2022",
  //   readTime: "6 min to read",
  //   title: "Advanced Business Analysis",
  //   description: "Course description goes here description here Course descript here description here Course...",
  //   link: `/resources/blog/${encodeURIComponent("Advanced Business Analysis")}`
  // },
  // {
  //   category: "Business",
  //   image: card7, 
  //   author: "Michael Alphon",
  //   date: "July 30, 2022",
  //   readTime: "6 min to read",
  //   title: "Leadership and Business Strategy",
  //   description: "Course description goes here description here Course descript here description here Course...",
  //   link: `/resources/blog/${encodeURIComponent("Leadership and Business Strategy")}`
  // },
  // {
  //   category: "Business",
  //   image: card8, 
  //   author: "Michael Alphon",
  //   date: "July 30, 2022",
  //   readTime: "6 min to read",
  //   title: "Global Business Strategy",
  //   description: "Course description goes here description here Course descript here description here Course...",
  //   link: `/resources/blog/${encodeURIComponent("Global Business Strategy")}`
  // },
  // {
  //   category: "Design",
  //   image: card7, 
  //   author: "Michael Alphon",
  //   date: "July 30, 2022",
  //   readTime: "6 min to read",
  //   title: "Global Business Strategy",
  //   description: "Course description goes here description here Course descript here description here Course...",
  //   link: `/resources/blog/${encodeURIComponent("Global Business Strategy")}`
  // },
  // {
  //   category: "Self Development",
  //   image: card6, 
  //   author: "Michael Alphon",
  //   date: "July 30, 2022",
  //   readTime: "6 min to read",
  //   title: "Global Business Strategy",
  //   description: "Course description goes here description here Course descript here description here Course...",
  //   link: `/resources/blog/${encodeURIComponent("Global Business Strategy")}`
  // },
  {
    category: "Management",
    image: card5, 
    author: "Michael Alphon",
    date: "July 30, 2024",
    readTime: "6 min to read",
    title: "Breaking into Tech",
    description: "How Business Analysis and Product Management Training Can Help You Transition into Tech",
    link: `/resources/blog/${encodeURIComponent("Breaking into Tech")}`
  },
  {
    category: "Business",
    image: card3, 
    author: "Michael Alphon",
    date: "Nov 30, 2024",
    readTime: "6 min to read",
    title: "Breaking into Tech with Business Analysis",
    description: "Are you looking to break into tech but don’t know where to start?",
    link: `/resources/blog/${encodeURIComponent("Breaking into Tech with Business Analysis")}`
  }
];


