import { SEO, PageLayout, LiveWrapper } from "../components";

function LiveQA() {
  return (
    <>
      <SEO
        url="/resources/qa"
        openGraphType="website"
        schemaType="website"
        title="Q and A Session"
        description="Join us for a Live Q & A Session"
      />
      <PageLayout>
        <div className="bg-[#FCFCF5] w-full">
          <LiveWrapper />
        </div>
      </PageLayout>
    </>
  );
}

export default LiveQA;