import React, { useState } from 'react';
import styles from './satisfaction.module.css';
import { star } from '../../images';
import EnrolNow from '../modals/fastTrack';
import Button from '../button';

const Satisfaction = ({ satisfaction, paymentSectionRef }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

const handleClick = () => {
  if (paymentSectionRef.current) {
    paymentSectionRef.current.scrollIntoView({ behavior: "smooth" });
  }
};


  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <section className={styles['section']}>
      <div className={styles['block']}>
        {satisfaction ? (
          <img src={star} alt="star" loading="lazy" />
        ) : (
          <div className={styles["tag_black"]}>Refresher Course</div>
        )}
        {satisfaction ? (
          <div className={styles['text']}>
            "100% satisfaction guarantee"
          </div>
        ) : (
          <>
            {/* Display heading for Mobile */}
            <h2 className="font-bold text-[#ffffff] text-xl text-center md:hidden">
              This course will help you launch your Business Analysis career in the shortest possible time
            </h2>
            {/* Display heading for desktop */}
            <div className="hidden font-bold text-[#ffffff] text-center md:flex md:flex-col md:text-[28px] md:w-[790px]">
              <p>Accelerate your path to Business Analysis & Product Ownership success—start now, succeed faster!</p>
            </div>
          </>
        )}

        <span className={styles['span']}>
          {satisfaction ? "5.0 Star Google Review" : "Be Agile about your transition to a tech career"}
        </span>

        {!satisfaction && (
          <Button
            className={styles['button']}
            icon="blackArrow"
            onClick={handleClick}
          >
            Register Now
          </Button>
        )}

      </div>
      {isModalOpen && <EnrolNow onClose={handleClose} />}
    </section>
  );
};

export default Satisfaction;
