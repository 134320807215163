import React, { useState } from 'react';
import { logoWhiteImg } from '../../images';
import Button from '../button';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

const VerificationCode = () => {
  
  const {
    register,
    handleSubmit,
    formState: {},
  } = useForm({ mode: 'all' });

  const [otp, setOtp] = useState(new Array(4).fill(''));
  const [otpError, setotpError] = useState(true);
  const [error, setError] = useState(false);

  const handleOtp = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([
      ...otp.map((value, idx) => (index === idx ? element.value : value)),
    ]);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }

    otp.join('');    
    if (index === 3) setotpError(false);    
  };

  const handleCancel = (event) => {
    setOtp([...otp.map((value) => '')]);
    setError(false);
    setotpError(true)
  };

  const onSubmit = () => {
    //code to verify the OTP code, currently hardcoding to check functionality. Later can verify as passed from backend.
     if(otp.join('') === "1234") alert('Entered otp is: ' + otp.join(''));
    else{
      setotpError(true)
      setError(true)
    }
  };

  return (
    <section className="bg-black w-full h-[100vh] flex flex-col items-center justify-center ">
      <div className="flex flex-col gap-6 text-[rgb(255,255,255)] items-center justify-center ">
        <div className="flex flex-col gap-3 items-center justify-center">
        <Link to={'/'}>
            <img src={logoWhiteImg} alt="logo"  className="w-[80px] h-[35px]" loading='lazy'/>
           </Link>
          <h2 className="font-bold text-3xl">Please check your email</h2>
          <p className="text-base font-normal">
            We have sent a code to email.com@gmail.com
          </p>
        </div>
        <form
          className="flex flex-col items-center justify-center w-[484px] py-8 bg-[#FFFFFF1A]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex gap-2 mb-3">
            {otp.map((data, index) => {
              return (
                <input
                  type="text"
                  maxLength="1"
                  name="otp"
                  key={index}
                  value={data}
                  onChange={(event) => handleOtp(event.target, index)}
                  onFocus={(event) => event.target.select()}
                  className={error ? "border-[#E91E26] border-2 border-solid h-[64px] text-3xl text-[#D8D8D8] pl-[10%] w-[99px]"
                  : "border-2 border-solid  border-white h-[64px] text-3xl text-[#D8D8D8] pl-[10%] w-[99px]"}
                />
              );
            })}
          </div>
          {error && (
            <span className="text-[#E91E26] text-xs w-[87%] mb-3">
              The code you entered is incorrect. Please try again or request a
              new one if you cannot find it
            </span>
          )}

          <label className="text-[#D8D8D8] font-normal text-xs leading-5">
            Do not get a code?{' '}
            <href className="underline cursor-pointer">Click to resend</href>
          </label>

          <div className="flex gap-6 w-[88%]">
            <Button
              size
              variant="transparent"
              outline="white"
              className="mt-10"
              onClick={(event) => handleCancel(event)}
            >
              Cancel
            </Button>
            <Button
              size
              variant={otpError ? 'disabled' : 'secondary'}
              type="submit"
              className="mt-10 bg-[#D8D8D8]"
            >
              Verify
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default VerificationCode;
