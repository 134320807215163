import {SEO, VerificationCode} from '../components'


function VerificationCodePage(){
    return (
        <>
          <SEO
            url="/verificationcode"
            openGraphType="website"
            schemaType="website"
            title="verificationcode"
            description="Verification Code Page"
          />
         <VerificationCode />
        </>
      );
    }
export default VerificationCodePage