import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import Slider from "react-slick";
import {
  whiteLeftArrow,
  whiteRightArrow,
  YellowArrowRight,
  YellowArrowLeft,
  placeholder,
} from "../../images";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./carousel.module.css";
import { renderValidUrl } from "../../utils/constants";
import { generateLongDuration } from "../../utils/time";
import { Link, useLocation } from "react-router-dom";

const Carousel = React.memo(({ items = [], arrowColor }) => {
  const { pathname } = useLocation();
  const sliderRef = useRef(null);
  const [imageLoading, setImageLoading] = useState(true);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  // Custom shuffle function using native JavaScript
  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  // Shuffle items on initial render and slice to get the first 6 items
  const courses = useMemo(() => shuffleArray(items).slice(0, 6), [items]);

  // Update window size on resize with throttling
  useEffect(() => {
    const handleResize = () => setWindowSize(window.innerWidth);
    const throttleResize = handleResize;
    window.addEventListener("resize", throttleResize);
    return () => window.removeEventListener("resize", throttleResize);
  }, []);

  // Scroll to top when pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Define carousel settings
  const settings = useMemo(
    () => ({
      appendDots: (dots) => (
        <div style={{ padding: "13px 13px 13px 0px" }}>
          <ul style={{ display: "flex", justifyContent: "center" }}>{dots}</ul>
        </div>
      ),
      customPaging: (i) => <div style={{ width: "100%", color: "transparent" }}>{i + 1}</div>,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToScroll: 3,
      slidesToShow: 3,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 300,
          },
        },
      ],
    }),
    []
  );

  // Handle image loading and errors
  const handleImageLoad = useCallback(() => setImageLoading(false), []);
  const handleImageError = useCallback((e) => (e.target.src = placeholder), []);

  // Carousel navigation
  const nextSlide = useCallback(() => sliderRef.current.slickNext(), []);
  const prevSlide = useCallback(() => sliderRef.current.slickPrev(), []);

  return (
    <div className={styles.carousel_body}>
      <div className={styles.view_port}>
        <div
          id="slideContainer"
          className={windowSize < 992 || items.length > 3 ? styles.body_items : styles.body_items2}
        >
          {windowSize < 992 || items.length > 3 ? (
            <Slider ref={sliderRef} {...settings}>
              {courses.map((item, index) => (
                <Link to={item.ctaUrl || item.courseUrl || ""} className={styles.slide_div} key={index}>
                  <div className={styles.item}>
                    <div className={styles.img_body}>
                      {imageLoading && <img src={placeholder} alt="Default" loading="lazy" />}
                      <img
                        src={renderValidUrl(item.image || item.imageUrl)}
                        alt={item.title || "Course Image"}
                        loading="lazy"
                        onLoad={handleImageLoad}
                        onError={handleImageError}
                      />
                    </div>
                    <div className={styles.slide_info}>
                      <div className={styles.time}>
                        {item.regionPrice && item.regionPrice[0]?.cohorts.length ? (
                          <span>
                            {generateLongDuration(
                              item.regionPrice[0].cohorts[0].startDate,
                              item.regionPrice[0].cohorts[0].endDate
                            )}
                          </span>
                        ) : (
                          <span>Coming Soon...</span>
                        )}
                      </div>
                      <div className={styles.slide_title}>{item.title}</div>
                      <div className={styles.slide_text}>{item.courseDescription || item.description}</div>
                    </div>
                  </div>
                </Link>
              ))}
            </Slider>
          ) : (
            <>
              {courses.map((item, index) => (
                <Link to={item.ctaUrl || item.courseUrl} className={styles.slide_div} key={index}>
                  <div className={styles.item}>
                    <div className={styles.img_body}>
                      {imageLoading && <img src={placeholder} alt="Default" loading="lazy" />}
                      <img
                        src={renderValidUrl(item.image || item.imageUrl)}
                        alt={item.title || "Course Image"}
                        loading="lazy"
                        onLoad={handleImageLoad}
                        onError={handleImageError}
                      />
                    </div>
                    <div className={styles.slide_info}>
                      <div className={styles.time}>
                        {item.regionPrice && item.regionPrice[0]?.cohorts.length ? (
                          <span>
                            {generateLongDuration(
                              item.regionPrice[0].cohorts[0].startDate,
                              item.regionPrice[0].cohorts[0].endDate
                            )}
                          </span>
                        ) : (
                          <span>Coming Soon...</span>
                        )}
                      </div>
                      <div className={styles.slide_title}>{item.title}</div>
                      <div className={styles.slide_text}>{item.courseDescription || item.description}</div>
                    </div>
                  </div>
                </Link>
              ))}
            </>
          )}
        </div>
      </div>

      {courses.length > 1 && (
        <>
          <div className={`${styles.arrow_section} ${styles.left_section}`}>
            <button
              title="Previous Slide"
              className={`${styles.btn} ${styles.circle_arrow_btn}`}
              onClick={prevSlide}
            >
              <img
                src={arrowColor === "white" ? whiteLeftArrow : YellowArrowLeft}
                alt="Previous Slide"
                loading="lazy"
              />
            </button>
          </div>
          <div className={`${styles.arrow_section} ${styles.right_section}`}>
            <button
              title="Next Slide"
              className={`${styles.btn} ${styles.circle_arrow_btn}`}
              onClick={nextSlide}
            >
              <img
                src={arrowColor === "white" ? whiteRightArrow : YellowArrowRight}
                alt="Next Slide"
                loading="lazy"
              />
            </button>
          </div>
        </>
      )}
    </div>
  );
});

export default Carousel;
