import styles from "./modal.module.css";

export default function Modal({ isOpen, children, onClose }) {
  return (
    <section className={styles["blur_background"]} onClick={onClose}>
      <div
        className={`${!isOpen ? "hidden" : styles["modal-shadow"]}`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
          {children}
      </div>
    </section>
  );
}