import {SEO, PageLayout, ReferFriend} from '../components'

function ReferAFriend(){
    return (
        <>
          <SEO
            url="/resources/refer"
            openGraphType="website"
            schemaType="website"
            title="Refer A Friend"
            description="Refer A Friend"
          />
          <PageLayout>
            <ReferFriend/>
          </PageLayout>
        </>
      );
    }
export default ReferAFriend