import { nbcLogo, foxLogo, cbsLogo, usaTodayLogo } from "../images";

export const FEATURED_IN = [
  { id: 1, name: 'NBC', image: nbcLogo, url: 'https://www.nbcnews.com/' },
  { id: 2, name: 'ABC', image: foxLogo, url: 'https://www.foxnews.com/' },
  { id: 3, name: 'CBS', image: cbsLogo, url: 'https://www.cbsnews.com/' },
  {
     id: 4,
     name: 'usaToday',
     image: usaTodayLogo,
     url: 'https://www.usatoday.com/',
  },
];
