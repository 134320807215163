const settings = {
  api: {
    uri: process.env.REACT_APP_API_URL,
  },
  meta: {
    rootUrl: process.env.REACT_APP_PUBLIC_URL,
    title: "TheSkillGarage",
    description: "We help you transition into tech seamlessly. Our Business Analysis training is the perfect blend of theory and hands-on live projects that prepares you for",
    social: {
      graphic:
        "https://res.cloudinary.com/acushlakoncepts/image/upload/v1678305064/tsg-logo_ddqnaj.png",
      twitter: "@theskillgarage",
    },
  },
  routes: {
    authenticated: {
      pathAfterFailure: "/login",
    },
    public: {
      pathAfterFailure: "/documents",
    },
  },
};

export default settings;