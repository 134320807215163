import styles from './feature.module.css';

const FeaturedIn = ({ data }) => {
   return data ? (
      <section className={styles['section_no_background']}>
         <div className={styles['as_feature_in']}>
            <div className={styles['as_feature_in_title']}>As Featured In</div>
            <div className={styles['news_logo_row']}>
               {data.map((d) => (
                  <div key={d.id}>
                     <img
                        className={styles['feature_img']}
                        src={d.image}
                        alt={d.name}
                        loading='lazy'
                     />
                  </div>
               ))}
            </div>
         </div>
      </section>
   ) : null
};

export default FeaturedIn;
