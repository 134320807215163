

export const regionPrice = {
  "Europe - Africa": {
    cohorts: ["cohort1", "cohort2"],
    payments: [
      {
        id: 10,
        price: 49,
        slashPrice: null,
        buttonLabel: "Start For Free",
        buttonUrl: "https://tsg-stage.vercel.app/free-course",
        description: "Register for our free fast-track course. No payment required",
        pillText: null,
        type: "Basic",
        defaultPrice: 0,
        defaultSlash: null,
      },
      {
        id: 5,
        price: 849,
        slashPrice: 1399,
        buttonLabel: "Make Full Payment",
        buttonUrl: "https://buy.stripe.com/fZe6qV8KY4WfaK415H",
        description: "Save 20% when you choose a one-time payment.",
        pillText: "Recommended",
        type: "Recommended",
        defaultPrice: 849,
        defaultSlash: 1399,
      },
      {
        id: 30,
        price: 299,
        slashPrice: 499,
        buttonLabel: "Pay in Installments ",
        buttonUrl: "https://buy.stripe.com/7sIbLf2mA88r3hC29K",
        description: "Save 15% when you make a three-time payment.",
        pillText: null,
        type: "Installment",
        defaultPrice: 299,
        defaultSlash: 499,
      },
    ],
  },
  "US & Canada": {
    cohorts: ["cohort3", "cohort4"],
    payments: [
      {
        id: 20,
        price: 49,
        slashPrice: null,
        buttonLabel: "Register Now",
        buttonUrl: "https://buy.stripe.com/eVa02x7GU3SbcSc8yE",
        description: [
          "- Access to Live session",
          "- No access to recording",
          "- No certificate",
        ].join("\n"),
        pillText: null,
        type: "Basic",
        defaultPrice: 0,
        defaultSlash: null,
      },
      {
        id: 40,
        price: 79,
        slashPrice: 199,
        buttonLabel: "Register Now",
        buttonUrl: "https://buy.stripe.com/eVa8z39P2fAT4lG2ah",
        description: [
          "- Access to Live Session",
          "- Access to recording",
          "- Certificate",
        ].join("\n"),
        pillText: null,
        type: "Premium",
        defaultPrice: 499,
        defaultSlash: 549,
      },
         {
        id: 15,
        price: 149,
        slashPrice: 299,
        buttonLabel: "Register Now",
        buttonUrl: "https://buy.stripe.com/bIYbLf9P2agz19ueX4",
       description: [
  "- Access to Live Session",
  "- Lifetime Access to recording",
  "- Certificate",
  "- Access to business analysis self-paced course", 
  "- 1-on-1 Resume Review Session"
].join('\n')
,
        pillText: "Recommended",
        type: "Platinum",
        defaultPrice: 1299,
        defaultSlash: 1599,
      },
    ],
  },
};

export const formData = {
  defaultRegion: "US & Canada", // Change to "US & Canada" for testing other regions
};
