import { SEO, PageLayout, ProfileIndex } from "../components";

function Profile() {
  return (
    <>
      <SEO
        url="/profile"
        openGraphType="website"
        schemaType="website"
        title="Profile"
        description="User Profile Page"
      />
      <PageLayout>
        <div className="bg-[#FCFCF5] w-full">
          <ProfileIndex />
        </div>
      </PageLayout>
    </>
  );
}

export default Profile;