import React, { useEffect, lazy, Suspense, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./course-custom-carousel.module.css";
import { getCourseCarouselData } from '../../../redux/action';

// Lazy load Carousel component
const Carousel = lazy(() => import("../../carousel"));

const CourseCarousel = memo(({ exempt = null }) => {
  const dispatch = useDispatch();
  const { courseCarouselData } = useSelector(state => state);

  useEffect(() => {
    dispatch(getCourseCarouselData());
  }, [dispatch]);

  const { heading, description, courseCards } = courseCarouselData || {};
  const cards = exempt ? courseCards?.filter((c) => c.id !== exempt) : courseCards;
  const sliderBlockClasses = [styles["slider_block"]];

  if (cards?.length < 3) {
    sliderBlockClasses.push(styles['justify-end']);
  }

  if (!courseCarouselData) return null;

  return (
    <section className={styles["section_no_background"]}>
      <div className={styles["section_no_background"]}>
        <h2 className={styles["sss_title"]}>{heading}</h2>
        <div className={styles["description_courses_text"]}>
          {description}
        </div>
        <div className={sliderBlockClasses.join(' ')}>
          {courseCards && (
            <Suspense fallback={<div>Loading carousel...</div>}>
              <Carousel items={cards} arrowColor="yellow" />
            </Suspense>
          )}
        </div>
      </div>
    </section>
  );
});

CourseCarousel.displayName = 'CourseCarousel';

export default CourseCarousel;