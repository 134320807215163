import { SEO, NotifyEmailVerification } from "../components";

function EmailVerificationSuccess() {
  return (
    <>
      <SEO
        url="/email-verification-success"
        openGraphType="website"
        schemaType="website"
        title="Email Verification Success"
        description="Your email has been verified"
      />
        <div className="bg-[black] w-full">
          <NotifyEmailVerification />
        </div>
    </>
  );
}

export default EmailVerificationSuccess;
