import styled from 'styled-components'
import { bgTerms1, bgTerms2 } from '../../images'

const TopBanner = styled.div`
  background-color: #F8D210;
  background-image: url(${bgTerms1}), url(${bgTerms2});
  background-repeat: no-repeat, no-repeat;
  background-size: 98px 98px, 117px 117px;
  background-position: top 8px left 59px, bottom 18px right 45px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  width: 100%;
  height: 220px;
  gap: 8px;
  border-radius: 4px;

  @media only screen and (max-width: 992px) {
    margin-top: 40px;
    height: 244px;
    background-image: none;
    border-radius: 0;
  }
`

const InfoBlock = styled.div`
  width: 653px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media only screen and (max-width: 992px) {
    width: 343px;
  }
`

const ContentWrap = styled.div`
  line-height: 3rem;
  margin-top: 2rem;

  h2, h4 {
    font-weight: 700;
  }

  h2 {
    font-size: 28px;
  }

  h4 {
    font-size: 16px;
  }

  p {
    line-height: 2rem;
    margin-bottom: 1.5rem;
  }

  .decor {
    font-weight: 700;
    text-decoration: underline;
  }

  footer {
    line-height: 1.5rem;
    font-weight: 700;
    margin: 2.5rem 0;

    a {
      color: #333333;
    }
  }
`

const CookiePolicy = () => {
  return (
    <div className='max-w-[1200px] m-auto'>
      <TopBanner>
        <InfoBlock>
          <h1 className='font-bold text-xl sm:text-4xl'>
            Cookie Policy
          </h1>
        </InfoBlock>
        <p className='text-center'> Last Updated: September 25, 2024</p>
      </TopBanner>

      <div className='max-w-[800px] m-auto p-8'>

        <ContentWrap>
          <h2>Cookie Policy</h2>
          <p>TheSkillGarage Ltd is committed to providing a transparent and secure browsing experience for all users. This Cookie Policy explains what cookies are, how we use them, and your rights regarding cookie usage on our website.</p>
          <p>
            <h2>1. What Are Cookies?</h2> <br />
          Cookies are small text files that are stored on your device (computer, tablet, smartphone) when you visit a website. They allow the website to recognize your device and store information about your preferences or past actions. Cookies are essential for improving your browsing experience, providing functionality, and enhancing security.
          </p>
          <h2>2. Types of Cookies We Use</h2>

          <p>
            We use the following types of cookies on our website:
          <li><b>Essential Cookies:</b> These cookies are necessary for the functioning of our website and cannot be disabled. They enable core features like security, authentication, and page navigation.</li>
          <li><b>Performance Cookies: </b> These cookies collect anonymous information about how users interact with our website. This data helps us improve website functionality and identify areas for enhancement.</li>
          <li><b>Functionality Cookies:</b> These cookies remember your preferences and settings (e.g., language preferences, login information) to personalize your browsing experience.</li>
          <li><b>Targeting/Advertising Cookies:</b> These cookies are used to track your browsing habits across multiple websites, enabling us and third-party advertisers to deliver content relevant to your interests.</li>
          </p>

        </ContentWrap>
        <ContentWrap>
        <h2>3. Third-Party Cookies</h2>
        <p>In addition to our own cookies, we may allow third-party service providers, such as analytics providers and advertisers, to set cookies on your device. These cookies help us understand user behavior, deliver advertisements, and monitor site performance. We only work with reputable third-party providers that adhere to strict data privacy laws.
Third-party cookies are subject to the respective privacy policies of the external providers, and we recommend reviewing these policies for more information.
</p>
        </ContentWrap>
        <ContentWrap>
<h2>4. Consent and Control of Cookies</h2>
<p>By using our website, you consent to the use of cookies in accordance with this policy. You have the right to manage or block cookies at any time through your browser settings. However, disabling certain types of cookies may affect the functionality and performance of the website, potentially limiting your user experience.</p>
          </ContentWrap>
        <ContentWrap>
<h2>Managing Cookies:</h2>
<p>You can configure your browser to accept or reject cookies, delete cookies, or notify you when a cookie is being set.
Each browser has different settings for cookie management. Please consult your browser's help section for specific instructions on how to manage cookies.
</p>
          </ContentWrap>
        <ContentWrap>
<h2>5. Cookie Duration</h2>
<p>Cookies may either be:
  <li><b>Session Cookies:</b> Temporary cookies that are erased when you close your browser. These cookies are used to remember information during your visit and are essential for the basic functionality of the site.
</li>
  <li><b>Persistent Cookies:</b> Cookies that remain on your device for a specified period or until you manually delete them. These cookies help us recognize you when you return to our site, ensuring a more seamless experience.</li>
</p>
          </ContentWrap>
        <ContentWrap>
<h2>6. Data Security and Confidentiality</h2>
<p>We take appropriate measures to ensure that cookies do not compromise your privacy. The cookies we use are designed to collect information in a way that does not personally identify you unless you voluntarily provide such information (e.g., by completing a form). We do not store sensitive personal information such as login credentials or payment details in cookies.
</p>
          </ContentWrap>
        <ContentWrap>
<h2>7. Your Rights</h2>
<p>
  As a user, you have the right to:
<li>Withdraw your consent to the use of cookies at any time.</li>
<li>Request the deletion of cookies stored on your device.</li>
<li>⁠Block specific types of cookies through your browser settings.</li>
To exercise any of these rights, refer to your browser's settings or consult the support documentation provided by your browser provider.
</p>
          </ContentWrap>
        <ContentWrap>
<h2>8. Changes to the Cookie Policy</h2>
<p>We may update this Cookie Policy periodically to reflect changes in technology, legal requirements, or the functionality of our website. Any updates will be posted on this page, and we will notify you of any material changes. Continued use of our website after such changes constitutes your acceptance of the updated policy.</p>
          </ContentWrap>

        <ContentWrap>
        <footer>
         If you have any questions or concerns about our use of cookies or this Cookie Policy, please do not hesitate to contact us at the following e-mail address: <a href="mailto:info@theskillgarage.com">info@theskillgarage.com</a>
        </footer>
        </ContentWrap>
      </div>
    </div>
  )
}

export default CookiePolicy;