import React from 'react'
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { postRequest } from '../../../redux/action';
import { useNavigate } from 'react-router-dom';
import styles from './recentEmail.module.css';
import { logoWhiteImg } from '../../../images';
import Button from '../../button';

const RecentEmail = () => {
    const userEmail = useSelector((state) => state.userEmail);
  const navigate = useNavigate();

 const handleResendEmailClick = async () => {
    const [success, responseData] = await postRequest("/api/auth/send-email-confirmation", {
      email: userEmail,
    });

    if (responseData?.error) {
      toast.error(responseData.error.message);
    } else {
      navigate('/resend-email');
      toast.success("Verification Email has been sent Successful.")
    }
  };

  return (
    <div className={styles["main_container"]}>
    <div className={styles["top_layout"]}>
    <div><img src={logoWhiteImg} alt="logo" loading='lazy'/></div>
    <h2 className={styles["header"]}>Email has been resent!</h2>
    <p className={styles["sub_heading"]}>We've resent a verification email to the address <span className={styles["email_address"]}>{userEmail}.</span></p>
    <p className={styles["top_text"]}>Please open the email and click the verification link to confirm your account.</p>
    </div>
    <div className={styles["sub_layout"]}>
    <p className={styles["sub_text"]}>If you don't see the email, make sure to check your spam or junk folder. If you still can't find it, you can request a new verification email.</p>
    <Button type='button' onClick={handleResendEmailClick} outline='white' variant='primary' className={styles["outline"]}>
      Resend Email
    </Button>
    <div className={styles["bottom_frame"]}>
     <a href="/signup" className={styles["bottom_link"]}>Update email address</a>
    </div>
    </div>
    </div>
  )
}

export default RecentEmail;