import { avatar1, avatar2, avatar3 } from "../images"

export const SUCCESS_STORIES = [
  {
  text: ' Actively learn by live discussions. Apply what you learn through activities, and get instant feedback from peers. Actively learn by live discussions. Apply what you learn through activities, and get instant feedback from peers.Actively learn by live discussions. Apply what you learn through.',
  imageUrl: avatar1,
  fullName: 'Name Surname',
  occupation: 'Business Analyst at Google',
},
{
  text: ' Actively learn by live discussions. Apply what you learn through activities, and get instant feedback from peers. Actively learn by live discussions. Apply what you learn through activities, and get instant feedback from peers.Actively learn by live discussions. Apply what you learn through.',
  imageUrl: avatar2,
  fullName: 'Name Surname',
  occupation: 'Business Analyst at Amazon',
},
{
  text: ' Actively learn by live discussions. Apply what you learn through activities, and get instant feedback from peers. Actively learn by live discussions. Apply what you learn through activities, and get instant feedback from peers.Actively learn by live discussions. Apply what you learn through.',
  imageUrl: avatar3,
  fullName: 'Name Surname',
  occupation: 'Business Analyst at The Skills Garage',
}
]