import {SEO, PasswordSuccess} from '../components'


function PasswordSuccessPage(){
    return (
        <>
          <SEO
            url="/password-success"
            openGraphType="website"
            schemaType="website"
            title="PasswordSuccess"
            description="Password Success Page"
          />
         <PasswordSuccess />
        </>
      );
    }
export default PasswordSuccessPage
