import React, { useState } from "react";
import StudentStoriesList from "../studentStoriesList";
import styles from "./studentStories.module.css";

const StudentStories = ({data}) => {
  const [open, setOpen] = useState(false);


  const isOpen = () => {
    setOpen(true);
  };

  return (
    <section className={styles["section_no_background"]}>
      <StudentStoriesList isOpen={isOpen} open={open} data={data}/>
    </section>
  );
};

export default StudentStories;
