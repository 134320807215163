import React, { useState, useRef, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Slider from "react-slick";
import {
  arrowYellowLeft,
  arrowYellowRight,
  whiteLeftArrow,
  whiteRightArrow,
  ArrowLeft,
  ArrowRight,
} from "../../images";
import { renderValidUrl } from "../../utils/constants";
import { ArrayOfImages, SocialIcons } from "./shared";
import { useResizeWindows } from "../../hooks/useResize";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-modal-video/scss/modal-video.scss";
import styles from "./instructors.module.css";

const InstructorCarousel = ({ items, yellow }) => {
  const sliderRef = useRef(null);
  const imageRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlide2, setCurrentSlide2] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const isMobile = useResizeWindows();
   const location = useLocation();

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setActiveIndex(next),
    afterChange: setCurrentSlide,
  };

  const smallAvatarSliderSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 900,
    slidesToShow: 6,
    slidesToScroll: 1,
    swipeToSlide: true,
    // focusOnSelect: true,
    afterChange: setCurrentSlide2,
    responsive: [
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 4.2,
        },
      },
    ],
  };

  const barWidth = 100 / items.length;
  const barPosition = (currentSlide + 0) * barWidth;
  const seperation = isMobile ? 4 : 6

  const nextSlide = () => {
    sliderRef.current.slickNext();
    const nextImageSlide = currentSlide2 + 1
    imageRef.current.slickGoTo(nextImageSlide)
    setCurrentSlide2(nextImageSlide)
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
    const nextImageSlide = currentSlide2 - 1
    imageRef.current.slickGoTo(nextImageSlide)
    setCurrentSlide2(nextImageSlide)
  };

  const nextSlide2 = () => {
    imageRef.current.slickGoTo(Math.min(currentSlide2 + 6, items.length - 6))
    // setCurrentSlide2(prevSlide => Math.min(prevSlide + 6, items.length - 1));
  };

  const prevSlide2 = () => {
    imageRef.current.slickGoTo(Math.max(currentSlide2 - 6, 0));
    // setCurrentSlide2(prevSlide => Math.max(prevSlide - 6, 0));
  };

  useEffect(() => {
    const leftArrow = document.querySelector("#arrow_left1");
    const rightArrow = document.querySelector("#arrow_right1");

    if (currentSlide === 0) {
      leftArrow.style.display = "none";
    } else {
      leftArrow.style.display = "block";
    }

    if (currentSlide === items.length - 1) {
      rightArrow.style.display = "none";
    } else {
      rightArrow.style.display = "block";
    }
  }, [currentSlide, items.length]);

  const handleClickImg = (index) => {
    sliderRef.current.slickGoTo(index);
    setCurrentSlide(index); // update the current slide
    imageRef.current.slickGoTo(index);
    setCurrentSlide2(index);
  };

  const handleClickBar = (event) => {
    const barContainerWidth = event.currentTarget.offsetWidth;
    const clickPositionX =
      event.clientX -
      event.currentTarget.getBoundingClientRect().left -
      barContainerWidth / 2; // calculate the click position relative to the center of the bar container
    const clickPercent = clickPositionX / barContainerWidth + 0.5; // calculate the click percentage relative to the center of the bar container
    const newSlideIndex = Math.floor(clickPercent * items.length);
    const newBarPosition = newSlideIndex * barWidth + barWidth / 2; // calculate new position of the styled bar
    setCurrentSlide(newSlideIndex); // update the current slide
    sliderRef.current.slickGoTo(newSlideIndex); // move the slider to the new slide
    imageRef.current.slickGoTo(newSlideIndex);
    setCurrentSlide2(newSlideIndex);
    const bar = document.querySelector(`.${styles.bar}`);
    bar.style.left = `${newBarPosition}%`; // update the position of the styled bar
  };

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      nextSlide();
    }

    if (isRightSwipe) {
      prevSlide();
    }
  };

  const ShowImage = ({ img, index }) => (
    <div className={styles["small_avatar"]} key={img.id}>
      <div
        className={styles["parent_img"]}
        onClick={() => handleClickImg(index)}
      >
        <img
          src={renderValidUrl(img.photo)}
          alt={img.name}
          loading="lazy"
          onClick={() => handleClickImg(index)}
          className={`${index === activeIndex
            ? styles["gold_border"]
            : ""
            }`}
        />
      </div>
    </div>
  )

  return (
    <section className={styles["section_first_background"]}>
      <div className={styles["section_no_background_carousel"]}>
        <div className={styles["student_success_stories"]}>
            <h3 className="sss_title">
            {location.pathname.includes('/courses/') ? 'Course Instructors' : 'Our Team'}
          </h3>
          <div className={styles["parent_carousel_in_section"]}>
            <div className={styles["arrow_wrapper"]}>
              {isMobile || items.length > 6 ? (
                <div className={styles["small_avatars_row"]}>
                  <Slider {...smallAvatarSliderSettings} ref={imageRef}>
                    {ArrayOfImages(items).map((img, index) => (
                      <ShowImage img={img} index={index} key={img.id} />
                    ))}
                  </Slider>
                </div>
              ) : (
                <div
                  className={`${styles["small_avatars_row"]} ${styles["no_slider"]}`}
                >
                  {ArrayOfImages(items).map((img, index) => (
                    <ShowImage img={img} index={index} key={img.id} />
                  ))}
                </div>
              )}
              {items.length > 6 && (
                <>
                  {
                    currentSlide2 > 0 &&
                    <div className={styles["flex_btn_left2"]}>
                      <button onClick={prevSlide2}>
                        <img src={ArrowLeft} alt="arrow left" loading="lazy" />
                      </button>
                    </div>
                  }
                  {
                    currentSlide2 < items.length - 6 &&
                    <div className={styles["flex_btn_right2"]}>
                      <button onClick={nextSlide2}>
                        <img src={ArrowRight} alt="arrow right" loading="lazy" />
                      </button>
                    </div>
                  }
                </>
              )}
            </div>
            <div className={styles["carousel_body"]}>
              <div className={styles["slide_column"]}>
                <div className={styles["view_port"]}>
                  <div className={styles["slide_list"]}>
                    <Slider ref={sliderRef} {...settings}>
                      {items.map((item) => (
                        <div
                          className={`${styles["slide_block"]} ${styles["active"]}`}
                          key={item?.id}
                          onTouchStart={isMobile && onTouchStart}
                          onTouchMove={isMobile && onTouchMove}
                          onTouchEnd={isMobile && onTouchEnd}
                        >
                          <div
                            className={`${styles["big_avatar"]} ${!isMobile && item?.id === activeIndex
                              ? styles["fadeIn"]
                              : styles[""]
                              }`}
                          >
                            <img src={renderValidUrl(item?.photo)} alt={item.name} loading="lazy"/>
                          </div>
                          <div className={styles["inf_block"]}>
                            <div className={styles["name_row"]}>
                              <span>{item?.name}</span>
                              <div className={styles["social_row"]}>
                                {item?.socialNetwork.map((social) => (
                                  <a target="_blank" href={`${social.url}`} key={social.id} rel="noreferrer">
                                    {SocialIcons(social)}
                                  </a>
                                ))}
                              </div>
                            </div>

                            <div className={styles["positions_row"]}>
                              {item?.qualification.map((position) => (
                                <div className={styles["position"]} key={position.id}>
                                  {position.qualification}
                                </div>
                              ))}
                            </div>

                            <div className={styles["instructor_description"]}>
                              {item?.description}
                            </div>

                            <div className={styles["logos"]}>
                              {item?.companies.map((company) => (
                                <img src={renderValidUrl(company.logo)} alt={`${company.name} logo`} key={company.id} loading="lazy"/>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
                <div className={styles["pagination"]} onClick={handleClickBar}>
                  <div className={styles.barContainer} onClick={handleClickBar}>
                    <div
                      className={styles.bar}
                      style={{ width: `${barWidth}%`, left: `${barPosition}%` }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              id="arrow_left1"
              className={`${styles["arrow_column"]} ${styles["left"]}`}
            >
              <button onClick={prevSlide}>
                <img src={(isMobile || yellow) ? arrowYellowLeft : whiteLeftArrow} alt="arrow left" loading="lazy" />
              </button>
            </div>
            <div
              id="arrow_right1"
              className={`${styles["arrow_column"]} ${styles["right"]}`}
            >
              <button onClick={nextSlide}>
                <img src={(isMobile || yellow) ? arrowYellowRight : whiteRightArrow} alt="arrow right" loading="lazy" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InstructorCarousel;
