import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { dates, months, years } from '../../../utils/calender';
import Button from '../../button';
import InputWrapper from '../../inputWrapper';
import { getTokenFromCookie } from '../../../utils/constants';
import { putRequest, updateUser } from '../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const EditProfileInformation = ({ setIsEditing }) => {
  const user = useSelector((state) => state.user);
useEffect(() => {
    // Update default values when user information changes
    if (user) {
      const { username, phoneNumber, address, linkedin_username, twitter_username } = user;

      // Set default values for each input field
      setValue('username', username);
      setValue('phoneNumber', phoneNumber);
      setValue('address', address);
      setValue('linkedin_username', linkedin_username);
      setValue('twitter_username', twitter_username);
    }
  }, [user]);


  const token = getTokenFromCookie();
  const dispatch = useDispatch();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ mode: 'all', defaultValues: {} });

const onSubmit = async (data) => {
  try {
    // Extract day, month, and year from form data
    const { day, month, year, ...restData } = data;

    // Convert date components to a Date object
    const dateOfBirth = new Date(year, month - 1, day);
      // Format dateOfBirth as a string in the desired format
    const formattedDateOfBirth = `${dateOfBirth.getFullYear()}-${(dateOfBirth.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${dateOfBirth.getDate().toString().padStart(2, '0')}`;

    // Make an API request to update user information
    const [success, responseData] = await putRequest(
      `/api/users/${user.id}`,
      {
        ...restData,
        dateOfBirth: formattedDateOfBirth, 
      },
      token
    );

    if (!success || responseData?.error) {
      console.error(responseData?.error?.message);
      // Handle error and show a toast message
      toast.error(responseData?.error.message, {
        autoClose: 2000,
      });
    } else {
      dispatch(updateUser(responseData));

      toast.success('user info updated successfully');
      // Close the edit mode
      setIsEditing(false);
    }
  } catch (error) {
    console.error('An error occurred while updating user profile:', error);
    // Handle error and show a toast message
    toast.error('An error occurred while updating user info', {
      autoClose: 2000,
    });
  }
};

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Title>Profile Information</Title>
        <Button type='submit' variant='bg-transparent' outline='black' className='button-size'>
          Save
        </Button>
      </Header>

      <InputWrapper title='Username' placeholder='Alexlee' fieldName='username' register={register} errors={errors} />

      <p className='date-of-birth'>Date of Birth</p>

      <DateOfBirth>
        <InputWrapper
          inputTag='Select'
          title=''
          fieldName='month'
          className='bear-months'
          register={register}
          errors={errors}
          options={months('option')}
        />

        <InputWrapper 
          inputTag='Select' 
          title='' 
          fieldName='day' 
          className='bear-dates' 
          register={register} 
          errors={errors} 
          options={dates('option')} 
        />

        <InputWrapper
          inputTag='Select'
          title=''
          fieldName='year'
          className='bear-years'
          register={register}
          errors={errors}
          options={years('option', new Date().getFullYear() - 100, new Date().getFullYear())}
        />
      </DateOfBirth>
      <InputWrapper
        inputTag='Phone Input'
        title='Phone Number'
        fieldName='phoneNumber'
        compoundValidation={false}
        control={control}
        register={register}
        errors={errors}
      />

      <InputWrapper
        title='Address'
        placeholder='234 Dundas Street, Toronto, ON, 3H5 B31'
        fieldName='address'
        register={register}
        errors={errors}
      />

      <InputWrapper
        title='Linkedin Profile'
        placeholder='https://www.linkedin.com/in/alex-lee/'
        fieldName='linkedin_username'
        register={register}
        errors={errors}
      />

      <InputWrapper
        title='Twitter Profile'
        placeholder='https://www.twitter.com/alex-lee/'
        fieldName='twitter_username'
        register={register}
        errors={errors}
      />
    </Form>
  );
};

const Form = styled.form`
  align-self: flex-start;
  padding: 40px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 1px 3px 20px 0px rgba(1, 1, 1, 0.07);

  .button-size {
    width: 90px;
  }

  input {
    color: #1e1b2e;
    font-size: 14px;
  }

  p {
    color: #333;
  }

  p.date-of-birth {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  .phone_input {
    background: transparent;
    color: #333;

    ::placeholder {
      color: #d8d8d8;
    }
  }

  .phone_button {
    background-color: transparent;
  }

  .selected-flag .arrow {
    border-top: 4px solid #333;
  }

  @media (max-width: 600px) {
    padding: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Title = styled.p`
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;

  @media (max-width: 424px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

const DateOfBirth = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;

  @media (max-width: 900px) {
    display: block;
  }

  select {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 14px;
    color: #1e1b2e;
    background-color: transparent;

    option {
      color: #d8d8d8;
    }
  }
`;

export default EditProfileInformation;
