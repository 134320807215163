import React from 'react';
import styles from './referFriend.module.css';
import ReferForm from './referForm';

const ReferFriend = () => {
  const formSteps = [
    'Fill the form correctly ',
    'Get a unique code for your referral',
    'Referral submits unique code while registering.',
    'You get rewarded. Simple as A-B-C ',
  ];
  return (
    <section className={styles['container']}>
      <div className={styles['leftDiv']}>
        <h1 className={styles['heading']}>Refer a friend and earn $100</h1>
        <p className={styles['subText']}>
          Earn up to $1500 a month by being our referral ambassador. <br />
          When you refer up to 15 paying students within a month, 
           you will get double the reward
           from the 16th referral if they register within same month.
        </p>

        {/* Stepper */}
        <div className={styles["points_section"]}>
            {formSteps.map((step, index) => (
                <div className={styles["point_item_row"]}>
                    <div className={styles["in_point_item_row"]}>
                      <div className={styles["point_icon"]}>
                      <div className={styles["disk_dot"]}></div>
                      </div>

                    <div className={styles["point_text"]}>{step}</div>
                    </div>
                    <div className={`${styles["vertical_line"]} ${index === 0 ? styles["first"] : ""}
                    ${index === formSteps.length - 1 ? styles["last"] : ""}`}></div>
                </div>
            ))} 
        </div>
        {/* Stepper */}

      </div>
      <div>
        <ReferForm />
      </div>
    </section>
  );
};
export default ReferFriend;
