import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logoWhiteImg, whiteCloseEyeIcon, whiteEyeIcon } from '../../../images';
import Button from '../../button';
import InputWrapper from '../../inputWrapper';
import { logout } from "../../../redux/action";
import PasswordChecker from '../passwordChecker';


const NewPassword = () => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: 'all' });

  const passwordValue = watch("password", "");
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const onSubmit = async (formData) => {
    axios
      .post('/auth/reset-password', {
        code: searchParams.get("code"),
        password: formData.password,
        passwordConfirmation: formData.confirmpassword,
      })
      .then(response => {
        toast.success("Your password has been reset.");
        navigate('/password-success');

        Cookies.remove('token');
        dispatch(logout());
      })
      .catch(error => {
        toast.error(`An error occurred: ${error.response.data.error.message}`);
      });
  };


  return (
    <div className='bg-black w-full h-[100vh] flex flex-col items-center justify-center '>
      <div className='flex flex-col gap-4 items-center py-3 mb-5'>
      <Link to={'/'}>
            <img src={logoWhiteImg} alt="logo"  className="w-[80px] h-[35px]" loading='lazy'/>
           </Link>
        <h2 className='font-bold text-3xl text-[#FFFFFF]'>Enter a new password</h2>
      </div>

      <form className='bg-[#FFFFFF1A] lg:w-[484px] px-6 py-6 flex flex-col'
        onSubmit={handleSubmit(onSubmit)}>

        <div className='mb-3'>
          <InputWrapper
            inputTag="Password"
            title="New Password"
            type={showPassword ? 'text' : 'password'}
            fieldName="password"
            requiredMessage="Password is Required"
            compoundValidation={true}
            register={register}
            errors={errors}
            icon={showPassword || !isDirty ? whiteEyeIcon : whiteCloseEyeIcon}
            onImageClick={handleShowPassword}
            patternValue={/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/}
            patternMessage="Low Password Strength"
            className={'!text-[14px]'}
          />
          <PasswordChecker passwordValue={passwordValue} />
        </div>

        <InputWrapper
          inputTag="Password"
          title="Confirm Password"
          type={showPassword ? 'text' : 'password'}
          fieldName="confirmpassword"
          requiredMessage="Password is Required"
          compoundValidation={true}
          {...register("confirmpassword", { validate: (value) => value === passwordValue || "The new password fields do not match. Please try again." })}
          register={register}
          errors={errors}
          icon={showPassword || !isDirty ? whiteEyeIcon : whiteCloseEyeIcon}
          onImageClick={handleShowPassword}
          className={'!text-[14px]'}
        />

        <Button size variant={isValid ? "secondary" : "disabled"} type="submit" className='mt-4 bg-[#D8D8D8]'>
          Set new password
        </Button>
      </form>
    </div>
  );
};

export default NewPassword;
