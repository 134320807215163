import { SEO, PageLayout, CookiePolicy } from "../components";

export default function Cookie() {
  return (
    <>
      <SEO
        url="/cookie-policy"
        title="Cookie Policy"
      />
      <PageLayout>
        <div className="bg-gray w-full">
          <CookiePolicy />
        </div>
      </PageLayout>
    </>
  )
}