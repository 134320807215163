import React from 'react';
import { FAQ_DATA } from '../../data';
import styles from './faq.module.css';
import TSGBg from '../tsg-bg';
import AccordionContainer from './accordion';

const Faq = ({course}) => {
   return (
      <TSGBg bgStyle='slopeLeftHigh' style={{backgroundSize: '100% 700px'}}>
         <div className={styles['background_section_body_faq']}>
            <div className={styles['sss_title']}>Frequently Asked Questions</div>

            <div className={styles['questions_block']}>
               <AccordionContainer data={course.faqs} />
            </div>
         </div>
      </TSGBg>
   );
};

export default Faq;
