import React, { useState, useRef, useEffect } from "react";
import styles from "./cookiePref.module.css";
import XCloseIcon from "../../../images/svg/xCloseIcon";
import cookieDetails from "./CookieDetails";
import Button from "../../button";

export default function CookiePreference({ onClose, bannerRef }) {
  const [isToggle, setToggle] = useState(false);

  function handleAcceptAll() {
    setToggle(true);
    bannerRef.current.accept();
  }

  function handleReject() {
    setToggle(false);
    bannerRef.current.decline();
  }

  function handleAccept() {
    setToggle(isToggle);
    bannerRef.current.accept();
  }

  return (
    <section className={styles["modal-shadow"]}>
      <div className={styles["modal"]}>
        <span className={styles["top_btn"]}>
          <XCloseIcon onClick={onClose} fill="white" />
        </span>
        <div className={styles["main"]}>
          <div>
            <p className={styles["heading"]}>Cookie Preference</p>
            <p className={styles["subtitle"]}>
              This website uses following type of services. Learn more from our
              <a
                href="/terms-and-conditions"
                style={{ textDecorationLine: "underline" }}
              >
                {" "}
                Cookie Policy.
              </a>
            </p>
          </div>

<div className={styles['policydiv']}>
          <div className={styles["polcies"]}>
            <div className={styles["policydetails"]}>
              <h3 style={{ fontSize: "16px", color: "white" }}>
                Essential Cookies
              </h3>
              <p style={{ fontSize: "14px", color: "#D8D8D8" }}>
                ⁠Essential Cookies: Necessary for the operation of our website and services.
              </p>
            </div>
            <div className={styles["toggle"]}>
              <label class="relative inline-flex items-center mb-5 cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  class="sr-only peer"
                  checked={true}
                />
                <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-yellow-400"></div>
                <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300" />
              </label>
            </div>
          </div>

          {cookieDetails.map((item) => {
            return (
              <div className={styles["polcies"]}>
                <div className={styles["policydetails"]}>
                  <h3 style={{ fontSize: "16px", color: "white" }}>
                    {item.name}
                  </h3>
                  <p style={{ fontSize: "14px", color: "#D8D8D8" }}>
                    {item.details}
                  </p>
                </div>
                <div className={styles["toggle"]}>
                  <label class="relative inline-flex items-center mb-5 cursor-pointer">
                    <input type="checkbox" value="" class="sr-only peer" />
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-yellow-400"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300" />
                  </label>
                </div>
              </div>
            );
          })}
        </div>
          <div className={styles["buttons"]}>
            <div className={styles["leftbuttons"]}>
              <Button className={styles["accept"]} outline="white" onClick={handleAcceptAll}>
                Accept All{" "}
              </Button>
              <Button className={styles["reject"]} onClick={handleReject}>
                Reject All{" "}
              </Button>
            </div>
            <Button  variant="secondary" className={styles["acceptselected"]} onClick={handleAccept}>
              Accept Selected{" "}
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.overlay} onClick={onClose} />
    </section>
  );
}
