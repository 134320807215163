import React from "react";
import { useForm } from "react-hook-form";
import { postRequest } from "../../redux/action";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import InputWrapper from "../inputWrapper";
import Button from "../button";
import styles from "./auth.module.css";
import { logoWhiteImg } from "../../images";

const ResetPassword = () => {
  
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });

  const onSubmit = async (data) => {
    const [success, responseData] = await postRequest("/api/auth/forgot-password", {
      email: data.email,
    });

    if(responseData?.error){
      toast.error(responseData.error.message);
    }else{
      reset();
      toast.success("Reset password link sent to your email.");
    }
  };

  return (
    <section className={styles["authWrapper"]}>
      <div className={styles["authContent"]}>
          <Link to={'/'}>
            <img src={logoWhiteImg} alt="logo"  className="w-[80px] h-[35px]" loading="lazy"/>
           </Link>
        <h2 className={styles["authHeader"]}>Reset password</h2>
        <p className={styles["authSubTitle"]}>
          Enter the email address associated with your account to receive
          instructions on how to reset your password.
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles["formWrapper"]}
        >
          <div className="w-full">
            <InputWrapper
              title="Email"
              type="email"
              placeholder="Example@example.com"
              fieldName="email"
              requiredMessage="Email is required"
              patternMessage="Invalid email address"
              register={register}
              errors={errors}
              patternValue={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
            />
          </div>
          <Button
            type="submit"
            variant={isValid ? "secondary" : "disabled"}
            size
            disabled={!isValid}
          >
            Reset Password
          </Button>
        </form>
        <div className={styles["accountTextWrapper"]}>
          <p className={styles["accountText"]}>Don't have an account?</p>
          <Link
            to={"/"}
            className={`${styles["accountText"]} ${styles["signUpText"]}`}
          >
            Sign Up
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
