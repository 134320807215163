import React, { useState } from 'react';
import classNames from 'classnames';
import { plusIcon, minusIcon } from '../../images';
import styles from './faq.module.css';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import playVidImage from '../../images/play.svg';

const Accordion = ({ title, content, isActive, onClick, className, video }) => {
  const classes = classNames(
    styles['question_answer'],
    className,
  );

  const [showPlayIcon, setShowPlayIcon] = useState(true);

  const onVideoEnd = () => {
    setShowPlayIcon(true);
  };

  const onVideoPlay = () => {
    setShowPlayIcon(false);
  };

  return (
    <div className={classes}>
      <div className={styles['question_item']} onClick={onClick}>
        <div className={styles['icon_div']}>
          {isActive ? (
            <img src={minusIcon} alt="minus icon" loading='lazy' />
          ) : (
            <img src={plusIcon} alt="plus icon" loading='lazy'/>
          )}
        </div>

        <div className={styles['question']}>{title}</div>
      </div>
      {isActive && <div className={styles['answer']}>
        <div>{content}</div>

        {video && <VideoContainer>
          <ReactPlayer
            className="react_player"
            url={video}
            playing={showPlayIcon == false}
            width="100%"
            height="100%"
            controls={true}
            onEnded={onVideoEnd}
            onPlay={onVideoPlay}
            onPause={onVideoEnd}
            onStart={onVideoPlay}
            config={{
              youtube: {
                playerVars: { showinfo: 1 }
              },
            }}
          />

          {showPlayIcon && <PlayVideoIcon onClick={onVideoPlay}><img src={playVidImage} alt="Play video" loading='lazy'/></PlayVideoIcon>}
        </VideoContainer>
        }
      </div>
      }
    </div>
  );
};
const AccordionContainer = ({ data, openFirst }) => {
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(openFirst ? 0 : null);

  const handleAccordionClick = (index) => {
    setActiveAccordionIndex(index === activeAccordionIndex ? null : index);
  };

  return (
    <div>
      {data.map((item, index) => (
        <Accordion
          key={index}
          title={item.title}
          content={item.content}
          video={item.video}
          isActive={index === activeAccordionIndex}
          onClick={() => handleAccordionClick(index)}
          className={index === data.length - 1 && '!border-b-0'}
        />
      ))}
    </div>
  );
};


const VideoContainer = styled.div`
  position: relative;
  margin-top: 12px;
  padding-top: 56.25%;
  
  .react_player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const PlayVideoIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 423px) {
    display: none;
  }

  img {
    filter: invert(100%);
    width: 76.8px;
    height: 76.8px;
  }
`;

export default AccordionContainer;
