import { SEO, PageLayout, Contact } from "../components";

function WorkSkillGaragePage() {
    return (
        <>
          <SEO
            url="/work-skills"
            openGraphType="website"
            schemaType="website"
            title="Work at TheSkillGarage"
            description="Work at TheSkillGarage"
          />
          <PageLayout>
            <div className="bg-[#FCFCF5] w-full">
              <Contact title={"Work with Us"} partner={"work"}/>
            </div>
          </PageLayout>
        </>
      );
  }
  
  export default WorkSkillGaragePage;