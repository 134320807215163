import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { FirstLastName } from "../../styles/common";
import { postRequest } from "../../redux/action";
import Button from '../button';
import { toast } from "react-toastify";
import InputWrapper from "../inputWrapper";
import ThankYou from '../modals/thankYou';

const LiveQAForm = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all", defaultValues: { phone_number: "+1" } });

  const [openMod, setOpenMod] = useState('');

  function handleClose() {
    setOpenMod(false);
  }
  const onSubmit = async (data) => {
    try {
      const [success, responseData] = await postRequest("/api/live-qa-forms", data);
      if (success) {
        toast.success("Form submitted successfully!"); // Show success toast
        setOpenMod(true);
      } else {
        console.error("Error submitting form:", responseData);
        toast.error("Error submitting form!"); // Show error toast
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form!"); // Show error toast
    }
  };
  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FirstLastName>
          <InputWrapper
            title="First Name"
            placeholder="Philips"
            fieldName="firstName"
            requiredMessage="Enter valid firstname"
            register={register}
            errors={errors}
          />

          <InputWrapper
            title="Last Name"
            placeholder="Dankano"
            fieldName="lastName"
            requiredMessage="Enter valid lastname"
            register={register}
            errors={errors}
          />
        </FirstLastName>

        <InputWrapper
          title="Email"
          type="email"
          placeholder="Example@example.com"
          fieldName="email"
          requiredMessage="Email is required"
          patternValue={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
          patternMessage="Invalid email address"
          compoundValidation={true}
          register={register}
          errors={errors}
        />

        <InputWrapper
          inputTag="Phone Input"
          title="Phone Number (optional)"
          fieldName="phone_number"
          compoundValidation={true}
          control={control}
          register={register}
          errors={errors}
        />

        <InputWrapper
          inputTag="Select"
          title="Select program you interested in"
          fieldName="program"
          options={["Select", "Program 1", "Program 2"]}
          requiredMessage="this is required"
          register={register}
          errors={errors}
        />

        <InputWrapper
          inputTag="Select"
          title="Select Live Q and A Session date"
          fieldName="session"
          options={["Select", "November 3rd 6PM", "September 20th 10 AM", "February 28th 2PM"]}
          requiredMessage="this is required"
          register={register}
          errors={errors}
        />

        <InputWrapper
          inputTag="Select"
          title="How did you hear about us?"
          fieldName="howDidYouHearAboutUs"
          options={["Select", "LinkedIn", "Facebook", "Instagram"]}
          requiredMessage="this is required"
          register={register}
          errors={errors}
        />

        <InputWrapper
          inputTag="Check Box"
          register={register}
          errors={errors}
        />

        <Button type="submit" size variant="secondary" >
          Register Now
        </Button>
      </form>
      {openMod && <ThankYou questAns onClose={handleClose} />}
    </Container>
  );
};

const Container = styled.div`
  background: #000000;
  border-radius: 4px;
  padding: 32px;

  form {
    width: 100%;
  }
`;

export default LiveQAForm;
