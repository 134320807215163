import React, { useState } from 'react';
import styles from './fastTrackHero.module.css';
import { fastTrackImg } from '../../images';
import { CARD_SECOND_COLUMN } from '../../data';
import { FAST_TRACK_DATA } from '../../data/fastTrack';
import Button from '../button';
import EnrolNow from '../modals/fastTrack';
import FastTrackSchedule from '../fast-track-schedule/fastTrackSchedule'

const FastTrackHero = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

   const handleClick = () => {
    setIsModalOpen(true)
  };
  const handleClose = () => {
    setIsModalOpen(false)
  };
  const { courseDuration, courseTime, bonus, rating } = FAST_TRACK_DATA[0];

  return (
     <><section className={ styles["section_no_background"] }>
      <div className={ styles["top_banner"] }>
        <div className={ styles["banner_info"] }>
          <div className={ styles["banner_text"] }>
            <div className='flex gap-2'>
              <div className={ styles["tag"] }>
                Free
              </div>
              <div className={ styles["tag"] }>
                For a complete novice!
              </div>
            </div>

            <div className={ styles["banner_title"] }>
              Free Business Analysis Crash Course 
            </div>

            <div className={ styles["banner_message"] }>
              Step confidently into the world of Business Analysis with our free, 
              beginner-friendly, fast-track crash course. Perfect for first-timers or 
              those brushing up on essentials. Register to join live sessions or 
              access recorded ones anytime for free.
            </div>
          </div>

          <div className={ styles["banner_button"] }>
            <Button outline="black" variant="secondary" className={ styles["enrol_now"] } onClick={ handleClick }>
              Register Now
            </Button>
          </div>
        </div>

        <div className={ styles["banner_photo"] }>
          <img src={ fastTrackImg } alt="banner"/>
        </div>
      </div>
      <div className={ styles["first_course_inf"] }>

        <div className={ styles["inf_point"] }>
          <div className={ styles["point"] }>Course Date</div>

          <div className={ styles["info"] }>{courseDuration}</div>
        </div>
        <div className={ styles["inf_point"] }>
          <div className={ styles["point"] }>Course Time</div>

          <div className={ styles["info"] }>{courseTime}</div>
        </div>
        { <>
          <div className={ styles["inf_point"] }>
            <div className={ styles["point"] }>Includes Bonus</div>

            <div className={ styles["info"] }>{bonus}</div>
          </div>
          <div className={ styles["inf_point"] }>
            <div className={ styles["point"] }>Satisfaction Guarantee</div>

            <div className={ styles["info"] }>{rating}</div>
          </div>
        </> }
      </div>
      { isModalOpen && (
        <EnrolNow onClose={ handleClose } />
      ) }
    </section><section className={ styles["section_first_background"] }>
        <div className={ styles["background_section_body"] }>
          <div className={ styles["card_row"] }>
            { CARD_SECOND_COLUMN.map(
              (item, index) => item.firstColumn &&
                item.firstColumn.map((item, index) => (
                  <div className={ styles["card_item"] } key={ index }>
                    <div className={ styles["icon_card"] }>
                      <img className={styles['img']} src={ item.icon } alt="item icon" loading='lazy'/>
                      <div className={ styles["card_title"] }>{ item.title }</div>
                      <div className={ styles["card_text"] }> { item.text }</div>
                    </div>
                  </div>
                ))
            ) }
          </div>
        </div>
      </section>
        <FastTrackSchedule/>
      </>
  )
}

export default FastTrackHero;