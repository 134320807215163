import { SEO, PageLayout, WaitListHeader } from "../components";

function WaitList() {
    return (
        <>
          <SEO
            url="/404"
            openGraphType="website"
            schemaType="website"
            title="404 Page"
            description="Page doesn't exist"
          />
          <PageLayout>
            <div className="bg-[#FCFCF5] w-full">
              <WaitListHeader />
            </div>
          </PageLayout>
        </>
      );
  }
  
  export default WaitList;