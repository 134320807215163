import { SEO, NotifyPasswordReset } from "../components";

function ResetPasswordEmailSent() {
  return (
    <>
      <SEO
        url="/reset-password-email-sent"
        openGraphType="website"
        schemaType="website"
        title="Reset Password Email Sent"
        description="Reset password instructions sent to email"
      />
        <div className="bg-[black] w-full">
          <NotifyPasswordReset />
        </div>
    </>
  );
}

export default ResetPasswordEmailSent;
