import { City, Country, State } from 'country-state-city'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { FirstLastName } from '../../../styles/common'
import Button from '../../button'
import InputWrapper from '../../inputWrapper'

const EditPaymentProfile = ({ setIsEditing }) => {
  const [countries, setCountries] = useState([]);
  const [countriesInfo, setCountriesInfo] = useState();
  const [selectedCountryCode, setselectedCountryCode] = useState();
  const [provinces, setProvinces] = useState([]);
  const [provincesInfo, setProvincesInfo] = useState();
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const countriesInfo = Country.getAllCountries();

    setCountries(countriesInfo.map(country => country.name));
    setCountriesInfo(countriesInfo);
  }, [])


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all", defaultValues: {} });

  const onCountrySelect = (e) => {
    const selectedCountry = countriesInfo.find(country => country.name === e.target.value);

    const provincesInfo = State.getStatesOfCountry(selectedCountry.isoCode);

    setProvinces(provincesInfo.map(province => province.name));
    setProvincesInfo(provincesInfo);
    setselectedCountryCode(selectedCountry.isoCode);
  }

  const onProvinceSelect = (e) => {
    const selectedProvince = provincesInfo.find(province => province.name === e.target.value);

    const citiesInfo = City.getCitiesOfState(selectedCountryCode, selectedProvince.isoCode);

    setCities(citiesInfo.map(city => city.name));
  }

  const onSubmit = async (data) => {
    try {
      setIsEditing(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Title>Payment</Title>
        <Button type='submit' variant='bg-transparent' outline='black' className='button-size'>Save New Payment Method</Button>
      </Header>

      <InputWrapper
        title="Name on card"
        placeholder="Alexander Lee"
        fieldName="cardName"
        register={register}
        errors={errors}
      />

      <InputWrapper
        title="Card number"
        placeholder="1234 1234 1234 1234"
        fieldName="cardNumber"
        register={register}
        errors={errors}
      />

      <FirstLastName>
        <InputWrapper
          title="Expiration"
          placeholder="mm/yy"
          fieldName="expiration"
          register={register}
          errors={errors}
        />

        <InputWrapper
          title="CVC"
          placeholder="cvc"
          fieldName="cvc"
          register={register}
          errors={errors}
        />
      </FirstLastName>

      <Title style={{ fontSize: "16px" }}>Billing Address</Title>

      <Spacer></Spacer>

      <InputWrapper
        title="Address line"
        placeholder=""
        fieldName="addressLine"
        register={register}
        errors={errors}
      />

      <FirstLastName>
        <InputWrapper
          inputTag="Select"
          title="Country"
          fieldName="country"
          options={["Select", ...countries]}
          onChangeHandler={onCountrySelect}
          register={register}
          errors={errors}
        />

        <InputWrapper
          inputTag="Select"
          title="Province"
          fieldName="province"
          options={["Select", ...provinces]}
          onChangeHandler={onProvinceSelect}
          register={register}
          errors={errors}
        />
      </FirstLastName>

      <FirstLastName>
        <InputWrapper
          inputTag="Select"
          title="City"
          fieldName="city"
          options={["Select", ...cities]}
          onChangeHandler={onProvinceSelect}
          register={register}
          errors={errors}
        />

        <InputWrapper
          title="Postal code"
          placeholder=""
          fieldName="postalCode"
          register={register}
          errors={errors}
        />
      </FirstLastName>
    </Form>
  )
}

const Form = styled.form`
  margin-top: 24px;
  align-self: flex-start;
  padding: 40px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 1px 3px 20px 0px rgba(1, 1, 1, 0.07);

  .button-size {
    width: 230px;
  }

  input {
    color: #1E1B2E;
    font-size: 14px;
  }

  p {
    color: #333;
  }

  select {
    height: 48px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 14px;
    color: #1E1B2E;
    background-color: transparent;

    option {
      color: #d8d8d8;
    }
  }

  @media (max-width: 600px) {
    padding: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Title = styled.p`
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;

  @media (max-width: 424px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

const Spacer = styled.div`
  margin: 4px 0;
`;

export default EditPaymentProfile