import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/action";
import PageLayout from ".";


const Authenticated = ({ children }) => {
  const { isAuthenticated } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      setInterval(() => {
        const isCookieExpired = !Cookies.get('token');

        if (isCookieExpired) {
          dispatch(logout()); // Dispatch the logout action when the cookie expires
        }
      }, 60000);
    }
  }, []);

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <PageLayout hasFooter={false}>
      {children}
    </PageLayout>
  );
};
export default Authenticated;
