import React from "react";

function MatchDownIcon({color}) {
  return (
    <svg
      width="8"
      height="32"
      viewBox="0 0 8 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={{color: color}}
    >
      <path d="M4 0V25" stroke="currentColor" strokeWidth="2" />
      <circle cx="4" cy="28" r="3.5" fill="currentColor" stroke="currentColor" />
    </svg>
  );
}

export default MatchDownIcon;
