import React, { useState } from 'react';
import styled from 'styled-components';
import EditPasswordProfile from './EditPasswordProfile';
import PasswordProfile from './PasswordProfile';

const PasswordProfileIndex = () => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Wrapper>
      {
        isEditing ?
          <EditPasswordProfile setIsEditing={setIsEditing} /> :
          <PasswordProfile setIsEditing={setIsEditing} />
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
`;

export default PasswordProfileIndex