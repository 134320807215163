import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import {
  MatchDownIcon,
  MatchUpIcon,
  YellowArrowLeft,
  YellowArrowRight,
  ArrowLeft,
  ArrowRight,
} from "../../../images";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./courseSchedule.module.css";

const CourseSchedule = ({ items }) => {
  const sliderRef = useRef(null);
  const weekBlockRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlide2, setCurrentSlide2] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const isDesktop = useMediaQuery({ minWidth: 992 });

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setActiveIndex(next),
    afterChange: setCurrentSlide,
  };
  const smallSliderSettings = {
    dots: false,
    arrows: false,
    swipeToSlide: true,
    width: "100vw",
    height: "auto",
    overflowX: "scroll",
    whiteSpace: "nowrap",
    infinite: false,
    speed: 900,
    slidesToShow: 8,
    slidesToScroll: 8,
    afterChange: setCurrentSlide2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          speed: 300,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 3.3,
          slidesToScroll: 1,
          speed: 300,
        },
      },
    ],
  };

  const averageWidthPerItem = 100 / items.length; // Calculate the average width per item
const minimumWidth = 50; // Set a minimum width (adjust as needed)

const osWidth = Math.max(averageWidthPerItem, minimumWidth); // Determine the final width for the os div
 const barWidth = 100 / items.length;
  const barPosition = (currentSlide + 0) * barWidth;

  const nextSlide = () => {
    sliderRef.current.slickNext();
    if (isDesktop && currentSlide === items.length - 2) {
      setCurrentSlide2(weekBlockRef.current.slickGoTo(items.length - 8));
    }
    if (!isDesktop) {
      setCurrentSlide2(weekBlockRef.current.slickGoTo(currentSlide + 1));
    }
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
    if (isDesktop && currentSlide === 1) {
      setCurrentSlide2(weekBlockRef.current.slickGoTo(0) || 0);
    }
    if (!isDesktop) {
      setCurrentSlide2(weekBlockRef.current.slickGoTo(currentSlide - 1));
    }
  };

  const nextSlide2 = () => {
    weekBlockRef.current.slickNext();
  };

  const prevSlide2 = () => {
    weekBlockRef.current.slickPrev();
  };

useEffect(() => {
  const leftArrow = document.querySelector("#arrow_left");
  const rightArrow = document.querySelector("#arrow_right");

  if (leftArrow) {
    if (currentSlide === 0) {
      leftArrow.style.display = "none";
    } else {
      leftArrow.style.display = "block";
    }
  }

  if (rightArrow) {
    if (currentSlide === items.length - 1) {
      rightArrow.style.display = "none";
    } else {
      rightArrow.style.display = "block";
    }
  }
}, [currentSlide, isDesktop, items.length]);

useEffect(() => {
  const leftArrow = document.querySelector("#arrow_left2");
  const rightArrow = document.querySelector("#arrow_right2");

  if (leftArrow) {
    if (currentSlide2 === 0) {
      leftArrow.style.display = "none";
    } else {
      leftArrow.style.display = "block";
    }
  }

  if (rightArrow) {
    if (currentSlide2 === items.length - weekBlockRef.current.props.slidesToShow) {
      rightArrow.style.display = "none";
    } else {
      rightArrow.style.display = "block";
    }
  }
}, [currentSlide2, items.length]);


  const handleClickBar = (event, index) => {
    event.preventDefault();
    sliderRef.current.slickGoTo(index);
    setCurrentSlide(index);
    setActiveIndex(index);
  };

  const handleClickPag = (event) => {
    const barContainerWidth = event.currentTarget.offsetWidth;
    const clickPositionX =
      event.clientX -
      event.currentTarget.getBoundingClientRect().left -
      barContainerWidth / 2; // calculate the click position relative to the center of the bar container
    const clickPercent = clickPositionX / barContainerWidth + 0.5; // calculate the click percentage relative to the center of the bar container
    const newSlideIndex = Math.floor(clickPercent * items.length);
    const newBarPosition = newSlideIndex * barWidth + barWidth / 2; // calculate new position of the styled bar
    setCurrentSlide(newSlideIndex); // update the current slide
    sliderRef.current.slickGoTo(newSlideIndex); // move the slider to the new slide
    setCurrentSlide2(weekBlockRef.current.slickGoTo(newSlideIndex));
    const bar = document.querySelector(`.${styles.bar}`);
    bar.style.left = `${newBarPosition}%`; // update the position of the styled bar
  };

  const titleUpOrDown = (order) => {
    if (order % 2) {
      return 1;
    }
    return 3;
  };

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      nextSlide();
    }

    if (isRightSwipe) {
      prevSlide();
    }
  };

   if (items.length === 0) {
    // Return null when items.length is 0 to hide the entire section
    return null;
  }

  return (
    <section className={styles["section_no_background"]}>
      <div className={styles["body_section_no_background"]}>
        <h3 className="sss_title"> Course Module Breakdown </h3>
       <div className={`${styles["course_line_view_port"]} ${items.length <= 5 && styles["centered_mobile"]}`}>
          <div className={styles["course_line"]}>
            <Slider ref={weekBlockRef} {...smallSliderSettings}>
              {items.map((item, index) => (
                <div
                  className={styles["week_parent_block"]}
                  key={item.id}
                  onClick={(e) => handleClickBar(e, index)}
                >
                  <div className={styles["week_item"]}>
                    <div
                      className={`${styles["label_match"]} ${
                        titleUpOrDown(item.week) < 2
                          ? styles["match_up"]
                          : styles["match_down"]
                      }`}
                    >
                      <div className={styles["match"]}>
                        {titleUpOrDown(item.week) < 2 ? (
                          <MatchUpIcon
                            color={index === activeIndex ? "#f8d210" : ""}
                          />
                        ) : (
                          <MatchDownIcon
                            color={index === activeIndex ? "#f8d210" : ""}
                          />
                        )}
                      </div>

                      <div
                        className={styles["week_title"]}
                        style={{ order: titleUpOrDown(item.week) }}
                      >
                        {item?.title}
                      </div>
                    </div>

                    <div
                      className={`${styles["week_order"]} ${
                        index === activeIndex
                          ? styles["week_active_background"]
                          : styles[""]
                      }`}
                    >
                      {`Week ${item?.week}`}
                    </div>
                  </div>
                 {index < items.length - 1 && (
                  <div
                    className={styles["os"]}
                    style={{ width: `${osWidth}%` }}
                  ></div>
                )}

                </div>
              ))}
            </Slider>
          </div>
          <div id="arrow_left2" className={styles["flex_btn_left2"]}>
            <button onClick={prevSlide2}>
              <img src={ArrowLeft} alt="arrow left" loading="lazy" />
            </button>
          </div>
          <div id="arrow_right2" className={styles["flex_btn_right2"]}>
            <button onClick={nextSlide2}>
              <img src={ArrowRight} alt="arrow right" loading="lazy" />
            </button>
          </div>
        </div>
      </div>
      <div className={styles["student_success_stories"]}>
        <div className={styles["sss_carousel_row"]}>
          <div className="carousel_body">
            <div className="slider-container">
              <Slider ref={sliderRef} {...settings}>
                {items.map((item, index) => (
                  <div
                  key={item.id}
                    onTouchStart={!isDesktop && onTouchStart}
                    onTouchMove={!isDesktop && onTouchMove}
                    onTouchEnd={!isDesktop && onTouchEnd}
                  >
                    <div className={styles["slide_header"]}>
                      <div className={styles["right_section"]}>
                        <div className={styles["order_week"]}>
                          <p>{`Week ${item.week}`}</p>
                          <div className={styles["week_title1"]}>
                            {item.title}
                          </div>
                        </div>
                      </div>
                      <div className={styles["tag"]}>
                        <span>{item?.hoursToComplete}</span> to complete
                      </div>
                    </div>
                    <div className={styles["slide_body"]}>
                      <div className={styles["description_section"]}>
                        <div className={styles["description"]}>{item.description}</div>
                        {item?.skills.length > 0 ? (
                          <div className={styles["skills_parent"]}>
                            <div className={styles["skills_title"]}>
                              Skills you will learn:{" "}
                            </div>
                            <div className={styles["skills"]}>
                              {item.skills.map((skill, index) => (
                                <div className={styles["skill_item"]} key={skill.id}>
                                  {skill.title}
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className={styles["points_section"]}>
                        {item?.modules.length > 0 ? ( item.modules.map((module, index) => (
                          <div className={styles["point_item_row"]} key={module.id}>
                            <div className={styles["in_point_item_row"]}>
                              <div className={styles["point_icon"]}>
                                <div className={styles["disk_dot"]}></div>
                              </div>

                              <div className={styles["point_text"]}>
                                {module.title}
                              </div>
                            </div>
                            <div
                              className={`${styles["vertical_line"]} ${
                                index === 0 ? styles["first"] : ""
                              } ${
                                index === item.modules.length - 1
                                  ? styles["last"]
                                  : ""
                              }`}
                            ></div>
                          </div>
                        ))) : null}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["pagination"]} onClick={handleClickPag}>
        <div className={styles.barContainer} onClick={handleClickPag}>
          <div
            className={styles.bar}
            style={{ width: `${barWidth}%`, left: `${barPosition}%` }}
          />
        </div>
      </div>
      <div id="arrow_left" className={styles["flex_btn_left"]}>
        {items.length > 0 && (
          <button onClick={prevSlide}>
            <img src={YellowArrowLeft} alt="arrow left" loading="lazy"/>
          </button>
        )}
      </div>
      <div id="arrow_right" className={styles["flex_btn_right"]}>
        {items.length > 0 && (
          <button onClick={nextSlide}>
            <img src={YellowArrowRight} alt="arrow right" loading="lazy"/>
          </button>
        )}
      </div>
    </section>
  );
};

export default CourseSchedule;