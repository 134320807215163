import React, { useState } from 'react';
import './why-choose-us.css';
import StartLearning from '../start-learning';
import { suitcase, rocket, group, flash } from '../../../images';
import TSGBg from '../../tsg-bg';
import Button from '../../button';
import { WhyChooseUsModal } from '../../modals';

const WhyChooseUs = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

   const handleClick = () => {
      setIsModalOpen(true);
   };

   const handleClose = () => {
      setIsModalOpen(false);
   };
   return (
      <TSGBg bgStyle='slopeLeftHigh'>
         <div className="section_background_body">
            <div className="you_should_row">
               <div className="card_block">
                  <div className="card_column card_right">
                     <div className="card">
                        <div className="card_icon">
                           <img src={suitcase} alt="suitcase-icon" loading='lazy' />
                        </div>

                        <div className="card_title">1. Learn by Doing <br />
</div>
<p className='description_text'>Get hands-on experience with real-world projects. Build an impressive portfolio.</p>
                     </div>
                        <div className="card">
                        <div className="card_icon">
                           <img src={rocket} alt="rocket" loading='lazy' />
                        </div>

                        <div className="card_title"> 3. Progress with Mentorship <br />
                     </div>
<p className='discription_text'>Advance your tech skills with guidance from seasoned mentors and thought leaders.</p>
                     </div>
                  </div>

                  <div className="card_column card_left">

                      <div className="card">
                        <div className="card_icon">
                           <img src={flash} alt="flash-icon" loading='lazy'/>
                        </div>

                        <div className="card_title">2. Gain Work Experience <br />
</div>
<p className='description_text'>Apply your knowledge in practical, on-the-job scenarios cultured within our professional environment.</p> 
                     </div>

                     <div className="card">
                        <div className="card_icon">
                           <img src={group} alt="group-icon" loading='lazy' />
                        </div>

                        <div className="card_title">4. Access Support and Community <br />
</div>
    <p className='description_text'> Benefit from ongoing support. Connect with other learners, mentors and a global community of professionals. </p> 
                     </div>
                  </div>
               </div>

               <div className="description_block">
                  <div className="description_title">Why choose us</div>

                  <div className="description_text">
                  Our 4-step approach ensures you learn by doing, 
                  receive personalized mentorship, gain work experience, 
                  and access continuous support within a thriving community, 
                  thereby setting you up for unequivocal success in your tech career.
                  </div>

                  {/* <Button
                     className='py-6 !w-[52%]'
                     icon='video' 
                     onClick={handleClick}
                     variant='transparent' 
                     outline='black'>
                        Watch video
                  </Button> */}
               </div>
            </div>
            <div id='courses'/>
            <StartLearning />
             {isModalOpen && <WhyChooseUsModal isOpen={isModalOpen} onClose={handleClose} /> }
         </div>
      </TSGBg>
   );
};

export default WhyChooseUs;
