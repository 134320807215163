import React from 'react';
import styles from './about-header.module.css';
import  aboutHeroImage  from '../../../images/hero-image.png';

const AboutHeader = () => {
   return (
      <section className={`${styles['section_no_background']} ${styles['first_section_margin']}`}>
         <div className={styles['top_banner']}>
            <div className={styles['banner_info']}>
               <div className={styles['banner_text']}>
                  <div className={styles['banner_title']}>This is TheSkillGarage</div>

                  <div className={styles['banner_message']}>
                   TheSkillGarage is a disruptive upskill and workplace 
                   development platform that addresses industry and technology 
                   talent gaps for the BlPOC (Black, Indigenous and People of 
                   Colour) community.
                  </div>
               </div>
            </div>

            <div className={styles['banner_photo']}>
               <img src={aboutHeroImage} alt="about-hero"/>
            </div>
         </div>
      </section>
   );
};

export default AboutHeader;
