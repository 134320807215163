import React, {useState} from "react";
import { useForm } from "react-hook-form";
import {
  facebookBlackIcon,
  instagramBlackIcon,
  linkedInBlackIcon,
  mailBoxIcon,
  phoneIcon,
  telegramBlackIcon,
  twitterBlackIcon,
  whatsappBlackIcon,
} from "../../images";
import { FirstLastName } from "../../styles/common";
import Button from "../button";
import InputWrapper from "../inputWrapper";
import { postRequest } from "../../redux/action";
import { toast } from "react-toastify";
import ThankYou from "../modals/thankYou";
import { PARTNERDROPDOWN, WORKDROPDOWN } from "../../utils/constants";
import styles from "./contact.module.css";


const Contact = ({ title, partner }) => {
  const [showPopUp, setShowPopUp] = useState(false);
  const toastId = React.useRef(null);

 const socialIcons = [
  // { icon: whatsappBlackIcon, link: "https://api.whatsapp.com/message/V2IGTURYUJC2B1" },
  { icon: twitterBlackIcon, link: "https://www.facebook.com/theskillgarage/" },
  { icon: linkedInBlackIcon, link: "https://www.linkedin.com/company/theskillgarage" },
  { icon: instagramBlackIcon, link: "https://www.instagram.com/theskillgarage" },
  { icon: facebookBlackIcon, link: "https://x.com/theskillgarage" },
  // { icon: telegramBlackIcon, link: "https://t.me/+PT-H1KZ1G1QzODc0" },

];



  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "all", defaultValues: { phone_number: "" } });


const onSubmit = async (data) => {
  toastId.current = toast("Loading...", {
    autoClose: false,
    closeButton: false
  });

  const formData = {
    firstName: data.firstname,
    lastName: data.lastname,
    email: data.email,
    phoneNumber: data.phone_number,
    message: data.your_message,  
    ...(partner && { reason: data.partnership })
  }

  try {
    const apiUrl = `${partner ? (partner === "partner" ? "/api/contact-partners" : "/api/work-with-uses") : "/api/contacts"}`;
    const [success, response] = await postRequest(apiUrl, formData);

    if (success && response.message === "Message sent successfully") {
      reset();
      toast.update(toastId.current, {
        type: toast.TYPE.SUCCESS,
        render: `${response.message}`,
        autoClose: 5000,
        closeButton: null
      });
      setShowPopUp(true);

      const pabblyData = new URLSearchParams({
        First_name: formData.firstName,
        Last_name: formData.lastName,
        Email: formData.email,
        Phone_number: formData.phoneNumber,
        Message: formData.message,
        ...(partner && { Reason: formData.reason })
      }).toString();

      const webhookUrl = process.env.REACT_APP_PABBLY_WEBHOOK_URL_CONTACT;
      const webhookResponse = await fetch(webhookUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: pabblyData,
      });

      if (!webhookResponse.ok) {
        console.error('Webhook submission failed');
      } else {
        const responseText = await webhookResponse.text();
        console.log('Response from Pabbly:', responseText);
      }
    } else {
      throw new Error(response.error.message || 'There was an error submitting your info.');
    }
  } catch (error) {
    console.error('Error submitting form:', error);
    toast.update(toastId.current, {
      type: toast.TYPE.ERROR,
      render: error.message || 'An error occurred submitting your info',
      autoClose: 5000,
      closeButton: null
    });
  }
};


  return (
    <section className={styles["section_no_background"]}>
      <div className={styles["top_banner"]}>
        <div className={styles["banner_info"]}>
          <h2>{title}</h2>
          <p className={styles["banner_info__subtitle"]}>
            Complete the form and a member of our team will be in touch
          </p>
          <div className={styles["contactWrapper"]}>
            <div className={styles["contact_info"]}>
              <img src={phoneIcon} alt="phone icon" loading="lazy" />
              <p>1-437-345-0501</p>
            </div>
            <div className="flex pb-5">
              <img src={mailBoxIcon} alt="mailbox icon" loading="lazy" />
              <a
                href="mailto:info@theskillgarage.com"
                className="ml-2.5 leading-6 text-base text-[#1E1B2E]"
              >
                info@theskillgarage.com
              </a>
            </div>
          </div>
         <div className={styles["socials"]}>
            {socialIcons.map((social, index) => (
              <a
                href={social.link}
                key={index}
                className={styles["social-icon"]}
                target="_blank" rel="noreferrer"
              >
                <img src={social.icon} alt={`Social Icon ${index}`} loading="lazy" />
              </a>
            ))}
          </div>
        </div>

        {/* {form} */}
        <form className={styles["form"]} onSubmit={handleSubmit(onSubmit)}>
          <FirstLastName>
            <InputWrapper
              title="First Name"
              placeholder="Philips"
              fieldName="firstname"
              requiredMessage="Enter valid firstname"
              register={register}
              errors={errors}
            />

            <InputWrapper
              title="Last Name"
              placeholder="Dankano"
              fieldName="lastname"
              requiredMessage="Enter valid lastname"
              register={register}
              errors={errors}
            />
          </FirstLastName>

          <InputWrapper
            title="Email"
            type="email"
            placeholder="Example@example.com"
            fieldName="email"
            requiredMessage="Email is required"
            patternValue={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
            patternMessage="Invalid email address"
            compoundValidation={true}
            register={register}
            errors={errors}
          />

          {partner && (
            <InputWrapper
              inputTag="Select"
              title={partner === "partner" ? "How would you like to partner with us?" : "How would you like to work with us?"}
              fieldName="partnership"
              options={partner === "partner" ? PARTNERDROPDOWN : WORKDROPDOWN}
              requiredMessage="this is required"
              register={register}
              errors={errors}
            />
          )}

          <InputWrapper
            inputTag="Phone Input"
            title="Phone Number"
            fieldName="phone_number"
            requiredMessage="Phone number is required"
            compoundValidation={true}
            control={control}
            register={register}
            errors={errors}
          />

          <InputWrapper
            inputTag="Text Area"
            title="Your Message"
            fieldName="your_message"
            className={styles["textArea"]}
            requiredMessage="Please enter a message"
            register={register}
            errors={errors}
          />

          <Button type="submit" variant="secondary" size>
            Contact us
          </Button>
        </form>
      </div>
      {showPopUp && ( partner ? <ThankYou partner onClose={() => setShowPopUp(false)}/> : <ThankYou contact onClose={() => setShowPopUp(false)}/>)}
    </section>
  );
};

export default Contact;
