import { useState, useEffect } from "react";

export const useResizeWindows = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

    useEffect(() => {
        window.addEventListener("resize", () =>
          setIsMobile(window.innerWidth < 992)
        );
        return () =>
          window.removeEventListener("resize", () =>
            setIsMobile(window.innerWidth < 992)
          );
      }, []);
    return isMobile;
}