import React, { useState, useEffect, useCallback } from 'react';
import XCloseIcon from '../../../images/svg/xCloseIcon';
import styles from './promotion.module.css';
import { promo } from '../../../images';
import Button from '../../button';
import { Link } from 'react-router-dom';

export default function Promotion({ onClose, promoData }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  // Lazy-load close icon SVG component and promo image for optimal loading.
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = promo;
    link.as = 'image';
    document.head.appendChild(link);
  }, []);

  // Handle image load event to toggle imageLoaded state
  const handleImageLoad = useCallback(() => {
    setImageLoaded(true);
  }, []);

  return (
    <section className={styles["modal-shadow"]} onClick={onClose}>
      <div className={styles["inner"]} onClick={(e) => e.stopPropagation()}>
        <span className={styles["top_btn"]}>
          <XCloseIcon onClick={onClose} fill="white" />
        </span>

        <div className={styles['main']}>
          <div className={styles['textdiv']}>
            {/* Availability Text */}
            <div className={styles['tag']}>
              <p>{promoData.availabilityText}</p>
            </div>

            {/* Heading with subtext */}
            <div className={styles['heading']}>
              <p>
                {promoData.heading.text}{' '}
                <span className={styles['subtext']}>{promoData.heading.subtext}</span>
              </p>
            </div>

            {/* Promotional Description */}
            <div className={styles['text']}>
              <p>{promoData.description}</p>
            </div>

            {/* Button Link */}
            <Link to={promoData.buttonUrl}>
              <Button className={styles['detailbtn']} variant="secondary" icon="blackArrow">
                {promoData.buttonLabel}
              </Button>
            </Link>
          </div>

          {/* Promotional Image */}
          <div className={styles['image']}>
            {/* Show placeholder while loading */}
            {!imageLoaded && (
              <div className={styles['placeholder']} aria-hidden="true"></div>
            )}
            <img
              src={promo}
              alt="Promotion"
              className={imageLoaded ? styles['loaded'] : styles['loading']}
              onLoad={handleImageLoad}
            />
          </div>
        </div>
      </div>

      {/* Overlay for modal */}
      <div className={styles.overlay} />
    </section>
  );
}
