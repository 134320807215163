import React from 'react';
import { useParams } from 'react-router-dom';
import { PageLayout, StartYourJourney } from '../components';
import Banner from '../components/blog/banner';
import { BLOG_CARD_DATA } from '../data';
import styles from '../components/blog/banner/banner.module.css';
import styled from 'styled-components';
import { ARTICLE_DATA } from '../data';
import RelatedPosts from '../components/blog/relatedPosts';
import BackToTop from '../components/testimonials/backToTop';
import { useState } from 'react';

function BlogArticle() {
  const { title } = useParams(); // Extract title from URL
  const [showGoTop, setShowGoTop] = useState(false);

  // Find the card data that matches the title from the URL
  const articleData = BLOG_CARD_DATA.find(card => card.title === decodeURIComponent(title));

  // Destructure the required fields from the article data
  const { author, date, readTime, image, category } = articleData || {};
  // Then get the matching article content
  const articleContent = ARTICLE_DATA.find(article => article.title === articleData.title);


  return (
    <PageLayout>
      <Banner
        bannerTitle={title}
        author={author}
        date={date}
        readTime={readTime}
        pageTitleStyle={styles.articleTitle}
        topBanner={styles.topBanner}
        blockBanner={styles.blockBanner}
        iconImg={'shareICon'}
        shareText="Share Post"
      />

      <Content>
        <img src={image} alt={title} loading='lazy'/>
        <div className="section">
          {articleContent && (
            <div className="section__Paragraph">
              <h2>{articleContent.header}</h2>
              {articleContent.paragraphs.map((paragraph, idx) => (
                <p key={idx}>{paragraph}</p>
              ))}
            </div>
          )}
        </div>
      </Content>

      {category && <RelatedPosts currentCategory={category} />}

      <StartYourJourney
        buttonText=""
        outline=""
        buttonClassName="!w-[0px] !h-[0px]"
      />

      <BackToTop 
        showGoTop={showGoTop} 
        setShowGoTop={setShowGoTop} 
        activateNearBottom={true} 
      />
    </PageLayout>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 822px;
  gap: 32px;

  h2 {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
  }

  h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-top: 16px;
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 80px;
  }

  .section__Paragraph {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .bullet__point {
    list-style-type: none; 
    position: relative;
  }

  .bullet__point li {
    position: relative;
    padding-left: 20px; 
    left: -3px;
  }

  .bullet__point li::before {
    content: '•'; 
    font-size: 2.5em; 
    position: absolute;
    left: 0;
    top: -18px;
    color: black;
  }

  .bullet__point li:not(:last-child) {
    margin-bottom: 24px;
  }

  .bullet__subpoint {
    margin-top: 24px;
  }

  .bullet__subpoint li:not(:last-child) {
    margin-bottom: 16px;
  }

  .bullet__subpoint li::before {
    content: '•'; 
    position: absolute;
    left: 0;
    top: 7px;
    color: white; 
    border: 3px solid #D8D8D8;
    border-radius: 50%; 
    width: 12px;  
    height: 12px; 
    display: flex;
  }

  @media only screen and (max-width: 992px) {
    width: 100vw;

    .section {
      padding: 0px 16px;
    }
 }
`;

export default BlogArticle;
