import React, { useRef, useEffect, useState } from 'react';
import styles from './allCourse.module.css';
import { ALL_COURSES } from '../../data/all-courses';
import { dateMDYtoEST } from '../../utils/time';
import StartYourJourney from '../home/start-your-journey';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const businessAnalysisCourses = ALL_COURSES['Business Analysis'];
const projectManagementCourses = ALL_COURSES['Project Management'];
const scrumMasterCourses = ALL_COURSES['Scrum Master'];

const AllCoursePage = () => {
  const leftContentsRef = useRef(null);
  const BA = useRef(null);
  const PM = useRef(null);
  const SM = useRef(null);
  const [activeSection, setActiveSection] = useState('');

  const stopPoint = 280; // Change this value to the desired stop point in pixels
  const mobileBreakpoint = 992; // Adjust this value as needed for your mobile breakpoint
  let sliderRef = useRef(null);

  const scrollToSection = (elementRef, targetLink) => {
    let offset;

    if (window.innerWidth > mobileBreakpoint) {
      // Offset for desktop
      offset = -80; // Adjust this value to the desired offset in pixels for desktop
    } else {
      // Offset for mobile
      offset = -350; // Adjust this value to the desired offset in pixels for mobile
    }

    const top = elementRef.current.offsetTop + offset;

    window.scrollTo({
      top: top,
      behavior: 'smooth',
    });

    // Remove underline class from all text elements
    const textElements = document.querySelectorAll('.text-xs');
    textElements.forEach((element) => {
      element.classList.remove('font-semibold', 'underline');
      element.classList.add('font-medium');
    });

    // Add underline class to the target link
    targetLink.classList.add('font-semibold', 'underline');
    targetLink.classList.remove('font-medium');
  };

  const handleScrollMobile = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;

    const baSectionTop = BA.current.offsetTop - windowHeight / 2; // Adjust the offset to match the desired position
    const pmSectionTop = PM.current.offsetTop - windowHeight / 2; // Adjust the offset to match the desired position
    const smSectionTop = SM.current.offsetTop - windowHeight / 2; // Adjust the offset to match the desired position

    if (scrollTop >= baSectionTop && scrollTop < pmSectionTop) {
      setActiveSection('ba-section');
    } else if (scrollTop >= pmSectionTop && scrollTop < smSectionTop) {
      setActiveSection('pm-section');
    } else if (scrollTop >= smSectionTop) {
      setActiveSection('sm-section');
    } else {
      setActiveSection('');
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.pageYOffset || document.documentElement.scrollTop;

      if (window.innerWidth > mobileBreakpoint) {
        if (currentScroll >= stopPoint) {
          leftContentsRef.current.style.position = 'fixed';
          leftContentsRef.current.style.top = '88px'; // Adjust the top value as desired
        } else {
          leftContentsRef.current.style.position = 'relative';
          leftContentsRef.current.style.top = '-720px';
        }

        const pageHeight = document.documentElement.scrollHeight - window.innerHeight - 480; // Adjust the offset
        if (currentScroll >= pageHeight) {
          leftContentsRef.current.style.position = 'static';
        }

        const baSectionTop = BA.current.offsetTop - 80; // Adjust the offset to match the desired position
        const pmSectionTop = PM.current.offsetTop - 80; // Adjust the offset to match the desired position
        const smSectionTop = SM.current.offsetTop - 80; // Adjust the offset to match the desired position

        if (currentScroll >= baSectionTop && currentScroll < pmSectionTop) {
          setActiveSection('ba-section');
        } else if (currentScroll >= pmSectionTop && currentScroll < smSectionTop) {
          setActiveSection('pm-section');
        } else if (currentScroll >= smSectionTop) {
          setActiveSection('sm-section');
        } else {
          setActiveSection('');
        }
      } else {
        leftContentsRef.current.style.position = 'static';
      }
    };

    const handleResize = () => {
      // Attach or detach the scroll event listener based on the window width
      if (window.innerWidth > mobileBreakpoint) {
        window.addEventListener('scroll', handleScroll);
      } else {
        window.removeEventListener('scroll', handleScroll);
      }
    };

    // Attach the resize event listener
    window.addEventListener('resize', handleResize);

    if (window.innerWidth <= mobileBreakpoint) {
      window.addEventListener('scroll', handleScrollMobile);
    }

    // Initial call to handleResize to set up the appropriate scroll event listener
    handleResize();

    // Cleanup by removing the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('scroll', handleScrollMobile);
    };
  }, [stopPoint, mobileBreakpoint]);

  const toggleUnderline = (event) => {
    event.preventDefault();

    const textElements = document.querySelectorAll('.text-xs');
    textElements.forEach((element) => {
      element.classList.remove('font-semibold');
      element.classList.add('font-medium');
      element.classList.remove('underline');
    });

    const target = event.target;
    target.classList.add('font-semibold');
    target.classList.remove('font-medium');
    target.classList.add('underline');
  };

  const handleSliderAutoScroll = () => {
    const currentSection = activeSection;
    let slideIndex = 0;

    if (currentSection === 'ba-section') {
      slideIndex = 0;
    } else if (currentSection === 'pm-section') {
      slideIndex = 1;
    } else if (currentSection === 'sm-section') {
      slideIndex = 2;
    }

    if (sliderRef && sliderRef.current) {
      sliderRef.current.slickGoTo(slideIndex);
    }
  };

  useEffect(() => {
    handleSliderAutoScroll();
  }, [activeSection]);

  return (
    <section>
      <div className={styles['section_center']}>
        <div className={styles['yellow_bg']}>
          <p className={styles['bg_heading']}>Our Courses & Bootcamps</p>
        </div>
      </div>
      <div className={styles['main_contents']}>
        <div className={styles['ba']}>
          <div className={styles['left_contents']} ref={leftContentsRef}>
            <div className={styles['left_mobile']}>
              {window.innerWidth <= 992 ? (
                <Slider
                  ref={sliderRef}
                  slidesToShow={4} // Adjust the number of visible slides based on your design
                  slidesToScroll={1}
                  infinite={false}
                  dots={false}
                  responsive={[
                    {
                      breakpoint: 992,
                      settings: {
                        slidesToShow: 2.1, // Adjust the number of visible slides for smaller screens
                      },
                    },
                  ]}
                >
                  <a
                    href="#business_analysis"
                    className={`smooth-scroll text-xs font-poppins font-medium pl-4 leading-none underline-none text-black flex-none order-0 ${
                      activeSection === 'ba-section' ? 'font-semibold underline' : 'font-medium'
                    } ${styles['font-size']}`}
                    onClick={(event) => {
                      event.preventDefault();
                      scrollToSection(BA, event.target); // Pass the target link element
                      toggleUnderline(event);
                    }}
                  >
                    Business Analysis
                  </a>

                  <a
                    href="#project_management"
                    className={`smooth-scroll text-xs font-poppins font-medium leading-none underline-none text-black flex-none order-0 ${
                      activeSection === 'pm-section' ? 'font-semibold underline' : 'font-medium'
                    } ${styles['font-size']}`}
                    onClick={(event) => {
                      event.preventDefault();
                      scrollToSection(PM, event.target); // Pass the target link element
                      toggleUnderline(event);
                    }}
                  >
                    Project Management
                  </a>

                  <a
                    href="#scrum_master"
                    className={`smooth-scroll text-xs font-poppins font-medium leading-none underline-none text-black flex-none order-0 ${
                      activeSection === 'sm-section' ? 'font-semibold underline' : 'font-medium'
                    } ${styles['font-size']}`}
                    onClick={(event) => {
                      event.preventDefault();
                      scrollToSection(SM, event.target); // Pass the target link element
                      toggleUnderline(event);
                    }}
                  >
                    Scrum Master
                  </a>
                </Slider>
              ) : (
                <>
                  {/* Render the links without the Slider component on desktop */}
                  <a
                    href="#business_analysis"
                    className={`smooth-scroll text-xs font-poppins font-medium leading-none underline-none text-black flex-none order-0 ${
                      activeSection === 'ba-section' ? 'font-semibold underline' : 'font-medium'
                    } ${styles['font-size']}`}
                    onClick={(event) => {
                      event.preventDefault();
                      scrollToSection(BA, event.target); // Pass the target link element
                      toggleUnderline(event);
                    }}
                  >
                    Business Analysis
                  </a>

                  <a
                    href="#project_management"
                    className={`smooth-scroll text-xs font-poppins font-medium leading-none underline-none text-black flex-none order-0 ${
                      activeSection === 'pm-section' ? 'font-semibold underline' : 'font-medium'
                    } ${styles['font-size']}`}
                    onClick={(event) => {
                      event.preventDefault();
                      scrollToSection(PM, event.target); // Pass the target link element
                      toggleUnderline(event);
                    }}
                  >
                    Project Management
                  </a>

                  <a
                    href="#scrum_master"
                    className={`smooth-scroll text-xs font-poppins font-medium leading-none underline-none text-black flex-none order-0 ${
                      activeSection === 'sm-section' ? 'font-semibold underline' : 'font-medium'
                    } ${styles['font-size']}`}
                    onClick={(event) => {
                      event.preventDefault();
                      scrollToSection(SM, event.target); // Pass the target link element
                      toggleUnderline(event);
                    }}
                  >
                    Scrum Master
                  </a>
                </>
              )}
            </div>
          </div>
        </div>

        <div className={styles['right_contents']}>
          <div className={styles['flex']}>
            <div
              ref={BA}
              className={`font-poppins font-bold text-2xl absolute top-390 leading-9 text-black w-259 h-36 ${
                styles['text_div']
              }`}
            >
              Business Analysis
            </div>
            {businessAnalysisCourses.map((course, index) => (
              <div className={styles['slide_div']} key={index}>
                <div className={styles['item']}>
                  <div className={styles['img_body']}>
                    <img src={course.imageUrl} alt="slide" loading='lazy' />
                  </div>
                  <div className={styles['slide_info']}>
                    <div className={styles['time']}>
                      {course.startDate && course.startDate ? (
                        <span>
                          {`${dateMDYtoEST(course.startDate)} - ${dateMDYtoEST(course.endDate)}`}
                        </span>
                      ) : (
                        <span>Coming Soon...</span>
                      )}
                    </div>
                    <div className={styles['slide_title']}>{course.title}</div>
                    <div className={styles['slide_text']}>{course.description}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={styles['flex']} id="project_management">
            <div
              ref={PM}
              className={`font-poppins font-bold text-2xl absolute top-879 leading-9 text-black w-259 h-36 ${
                styles['text_div1']
              }`}
            >
              Project Management
            </div>
            {projectManagementCourses.map((course, index) => (
              <div className={styles['slide_div']} key={index}>
                <div className={styles['item']}>
                  <div className={styles['img_body']}>
                    <img src={course.imageUrl} alt="slide" loading='lazy' />
                  </div>
                  <div className={styles['slide_info']}>
                    <div className={styles['time']}>
                      {course.startDate && course.startDate ? (
                        <span>
                          {`${dateMDYtoEST(course.startDate)} - ${dateMDYtoEST(course.endDate)}`}
                        </span>
                      ) : (
                        <span>Coming Soon...</span>
                      )}
                    </div>
                    <div className={styles['slide_title']}>{course.title}</div>
                    <div className={styles['slide_text']}>{course.description}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className={styles['flex']} id="scrum_master">
            <div
              ref={SM}
              className={`font-poppins font-bold text-2xl absolute top-1370 leading-9 text-black w-259 h-36 ${
                styles['text_div2']
              }`}
            >
              Scrum Master
            </div>
            {scrumMasterCourses.map((course, index) => (
              <div className={styles['slide_div']} key={index}>
                <div className={styles['item']}>
                  <div className={styles['img_body']}>
                    <img src={course.imageUrl} alt="slide" loading='lazy' />
                  </div>
                  <div className={styles['slide_info']}>
                    <div className={styles['time']}>
                      {course.startDate && course.startDate ? (
                        <span>
                          {`${dateMDYtoEST(course.startDate)} - ${dateMDYtoEST(course.endDate)}`}
                        </span>
                      ) : (
                        <span>Coming Soon...</span>
                      )}
                    </div>
                    <div className={styles['slide_title']}>{course.title}</div>
                    <div className={styles['slide_text']}>{course.description}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <StartYourJourney />
    </section>
  );
};

export default AllCoursePage;
