import { YellowEllipse } from "../images";

export const PASSWORDLIST = [    
    {
    name: 'length',
    description: 'At least 8 characters',
    icon:   YellowEllipse,
  },
  {
    name: 'uppercase n lowercase',
    description: 'At least one uppercase letter and lowercase',
    icon:   YellowEllipse,
  },
  {
    name: 'specialCharacter',
    description: 'At least one special character',
    icon:   YellowEllipse,
  },
]