import {SEO, NewPassword} from '../components'


function NewPasswordPage(){
    return (
        <>
          <SEO
            url="/newPassword"
            openGraphType="website"
            schemaType="website"
            title="NewPassword"
            description="New Password Page"
          />
         <NewPassword />
        </>
      );
    }
export default NewPasswordPage