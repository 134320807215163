import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { YellowCheck } from "../../../images";
import XCloseIcon from "../../../images/svg/xCloseIcon";
import { setFormData, postRequest } from "../../../redux/action";
import { FirstLastName } from "../../../styles/common";
import InputWrapper from "../../inputWrapper";
import ThankYou from "../thankYou";
import styles from "./enrol-now.module.css";
import { convertDateToValidFormat } from "../../../utils/time";
import { filterRegionsByCourseTitle } from "../../../utils/constants";

const EnrolNow = ({ Close, course, regionNames, allCourses, setIsEnrolOpen }) => {
  const modalRef = useRef();
  const { formData } = useSelector((state) => state);
  const [showEnrolNow, setShowEnrolNow] = useState(true);
  const [openThankYou, setOpenThankYou] = useState(false);
  const dispatch = useDispatch();

  const handleEnrolNowClose = () => {
    Close();
  };

  const handleThankYouClose = () => {
    setOpenThankYou(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleEnrolNowClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [courseDates, setCourseDates] = useState([]);
  const [regionCohortDates, setRegionCohortDates] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(formData.defaultRegion);

  useEffect(() => {
    if (course && course.regionPrice) {
      const selectedRegionData = course.regionPrice[formData.defaultRegion];

      if (selectedRegionData && selectedRegionData.cohorts) {
        const cohortsForSelectedRegion = selectedRegionData.cohorts;

        const filteredCourseDates = cohortsForSelectedRegion.map((cohort) => {
          return `${convertDateToValidFormat(cohort.startDate)} - ${convertDateToValidFormat(cohort.endDate)}`;
        });

        setRegionCohortDates(cohortsForSelectedRegion);
        setCourseDates(filteredCourseDates);
      } else {
        console.error(`No cohorts found for ${selectedRegion}.`);
      }
    }
  }, [selectedRegion, course]);

  const { control, register, handleSubmit, reset, formState: { errors } } = useForm({
    mode: "all",
    defaultValues: { phone_number: "+1" },
  });

  const onSubmit = async (formData) => {
    const requestData = {
      firstname: formData.firstname,
      lastname: formData.lastname,
      email: formData.email,
      phoneNumber: formData.phone_number,
      region: formData.region,
      courseDate: formData.course_date,
      referralSource: formData.about_us,
      qaSessionDate: formData.qa_date,
      course: course.id,
    };

    try {
      const enrollResponse = await postRequest("/api/enroll-nows", requestData);

      if (enrollResponse[1]?.details?.errors?.length > 0) {
        toast.error(enrollResponse[1].message);

        for (const error of enrollResponse[1].details.errors) {
          toast.error(error.message);
        }
      } else {
         toast.success("Enrollment successful!");
         setShowEnrolNow(false);
         setTimeout(() => {
           handleEnrolNowClose();
          }, 6000);
          setOpenThankYou(true);
  reset();
      }

      const pabblyData = new URLSearchParams({
        First_name: formData.firstname,
        Last_name: formData.lastname,
        Email: formData.email,
        Phone_number: formData.phone_number,
        Region: formData.region,
        Course_date: formData.course_date,
        About_us: formData.about_us,
        Course: course.title
      }).toString();

      const webhookUrl = process.env.REACT_APP_PABBLY_WEBHOOK_URL;
      const webhookResponse = await fetch(webhookUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: pabblyData,
      });

      if (!webhookResponse.ok) {
        throw new Error('Webhook submission failed');
      }

      const responseText = await webhookResponse.text();
      const responseText1 = await webhookResponse;
      console.log('Response from Pabbly:', responseText);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const availableRegions = filterRegionsByCourseTitle(allCourses, course.title);

  return (
    <>
    {showEnrolNow && (
    <section className={styles["modal-shadow"]}>
      <div className={styles["inner"]} ref={modalRef}>
        <span className={styles["top_btn"]}>
          <XCloseIcon onClick={handleEnrolNowClose} fill="white" />
        </span>
        <div className={styles["modal_body"]}>
          <div className={styles["modal_body_left"]}>
            <div className={styles["top_span"]}>
              No previous work experience needed!
            </div>

            <div className={styles["modal_header"]}>
              <span className={styles["sub_header"]}> {course.title}</span> {" "}
              Registration
            </div>
            <div className={styles["modal_desc"]}>
              Fill out this form to register for the {course.title} {" "}
              Program.
              <br />
            </div>
            {course.points && course.points.length > 0 ? (
              course.points.map((point) => (
                <div key={point.id} className={styles["modal_list"]}>
                  <img src={YellowCheck} alt="yellow check" loading="lazy" />
                  {point.point}
                </div>
              ))
            ) : (
              <>
                {/* Commented out code */}
              </>
            )}
          </div>

          <div className={styles["modal_body_right"]}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FirstLastName>
                <InputWrapper
                  title="First Name"
                  placeholder="Philips"
                  fieldName="firstname"
                  requiredMessage="Enter a valid firstname"
                  register={register}
                  errors={errors}
                />
                <InputWrapper
                  title="Last Name"
                  placeholder="Dankano"
                  fieldName="lastname"
                  requiredMessage="Enter a valid lastname"
                  register={register}
                  errors={errors}
                />
              </FirstLastName>
              <InputWrapper
                title="Email"
                type="email"
                placeholder="Example@example.com"
                fieldName="email"
                requiredMessage="Email is required"
                patternValue={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
                patternMessage="Invalid email address"
                compoundValidation={true}
                register={register}
                errors={errors}
              />

              <InputWrapper
                inputTag="Phone Input"
                title="Phone Number"
                fieldName="phone_number"
                requiredMessage="Phone number is required"
                compoundValidation={true}
                control={control}
                register={register}
                errors={errors}
              />

              <InputWrapper
                inputTag="Select"
                title="Select region"
                fieldName="region"
                options={["Select", ...availableRegions.map((region) => region)]}
                onChangeHandler={(e) => {
                  dispatch(setFormData({ defaultRegion: e.target.value }));
                }}
                requiredMessage="This is required"
                register={register}
                errors={errors}
              />

              <InputWrapper
                inputTag="Select"
                title="Select course date"
                fieldName="course_date"
                options={[
                  "Select",
                  ...courseDates
                ]}
                selectedOption="Select"
                requiredMessage="This is required"
                register={register}
                errors={errors}
              />

              <InputWrapper
                inputTag="Select"
                title="How did you hear about us?"
                fieldName="about_us"
                options={["Select", "LinkedIn", "Facebook", "Instagram", "Word of mouth", "Fast Track Course (Master Class)", "Google or another internet search"]}
                requiredMessage="This is required"
                register={register}
                errors={errors}
              />

              {/* Commented out code */}

              <InputWrapper
                inputTag="Check Box"
                register={register}
                errors={errors}
              />

              <button type="submit" className={styles["submit_btn"]}>
                Register Now
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className={styles.overlay} onClick={(e) => {
          e.stopPropagation();
          handleEnrolNowClose();
        }} />
    </section>
    )}
    {openThankYou && (
      <ThankYou course={course.title} onClose={handleThankYouClose} />
    )}
    </>
  );
};

export default EnrolNow;
