import React from 'react';
import Marquee from 'react-fast-marquee';
import { Link } from 'react-router-dom';

import styles from './marquee.module.css';

const Marque = ({ data }) => {
   const { showBanner, announcements } = data ? { ...data } : { showBanner: null, announcements: null }

   return data && showBanner && announcements.length > 0 && (
      <div
         className={`${styles['parent_announces']} my-5`}
      >
         <div className={styles['announces_section']}>
            <div className={styles['announces_row']}>
               <Marquee pauseOnHover gradient={false}>
                  {announcements.map((data, index) => (
                     <Link className={styles['announce_item']} key={index} to={data.ctaURL}>
                        <span>{data.text}</span>
                        <span className={styles['announce_cta']}>{data.ctaLabel}</span>
                        <span className={styles['elipse_announce']}></span>
                     </Link>
                  ))}
               </Marquee>
            </div>
         </div>
      </div>
   );
};

export default Marque;
