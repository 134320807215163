import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  App,
  About,
  FAQ,
  LiveQA,
  Courses,
  TermsAndCondition,
  Refund,
  PageNotFound,
  Contact,
  ContactPartner,
  Testimonial,
  FreeCourse,
  WaitList,
  ReferAFriend,
  AllCoursePage,
  FastTrack,
  WorkSkillGaragePage,
  SignUpPage,
  EmailVerificationSuccess,
  ResetPasswordEmailSent,
  NewPasswordPage,
  ResendEmailPage,
  VerifyEmailPage,
  ResetPasswordPage,
  PasswordSuccessPage,
  LoginPage,
  VerificationCodePage,
  ResetLinkPage,
  DashboardPage,
  Profile,
  PrivacyPolicy,
  CookiePolicy,
  BlogPage,
  BlogPost,
  RefresherCoursePage
} from "./pages";

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Redirect /practicum to the home page */}
        <Route path="/practicum" element={<Navigate to="/" replace />} />
        <Route path="/enrol-pm" element={<Navigate to="/courses/product-management" replace />} />
        <Route path="/business-analysis" element={<Navigate to="/courses/business-analysis" replace />} />
        <Route path="/schedule" element={<Navigate to="/" replace />} />
        <Route path="/business-analysis-week1-recap" element={<Navigate to="/courses/business-analysis" replace />} />
        <Route path="/cybersecurity" element={<Navigate to="/" replace />} />
        <Route path="/business-analysis-fast-track-course" element={<Navigate to="/courses/business-analysis" replace />} />
        <Route path="/ui/ux-design-bootcamp" element={<Navigate to="/" replace />} />
        <Route path="/blog" element={<Navigate to="/resources/blog" replace />} />
        <Route path="/q-and-a" element={<Navigate to="/resources/faq" replace />} />

        <Route exact path="/" element={<App />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/courses/:slug" element={<Courses />} />
        <Route exact path="/resources/faq" element={<FAQ />} />
        <Route exact path="/resources/blog" element={<BlogPage />} />
        <Route exact path="/resources/blog/:title" element={<BlogPost />} />
        <Route exact path="/resources/qa" element={<LiveQA />} />
        <Route exact path="/resources/refer" element={<ReferAFriend />} />
        <Route exact path="/all-course" element={<AllCoursePage />} />
        <Route exact path="/fast-track" element={<FastTrack />} />
        <Route exact path="/refresher-course" element={<RefresherCoursePage />} />
        <Route exact path="/terms-and-conditions" element={<TermsAndCondition />} />
        <Route exact path="/refund-policy" element={<Refund />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/cookie-policy" element={<CookiePolicy />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/contact-partner" element={<ContactPartner />} />
        <Route exact path="/work-skills" element={<WorkSkillGaragePage />} />
        <Route exact path="/testimonies" element={<Testimonial />} />
        <Route exact path="/free-course" element={<FreeCourse />} />
        <Route exact path="/waitList" element={<WaitList />} />
        <Route exact path="/signup" element={<SignUpPage />} />
        <Route exact path="/email-verification-success" element={<EmailVerificationSuccess />} />
        <Route exact path="/reset-password-email-sent" element={<ResetPasswordEmailSent />} />
        <Route exact path="/newpassword" element={<NewPasswordPage />} />
        <Route exact path="/password-success" element={<PasswordSuccessPage />} />
        <Route exact path="/resend-email" element={<ResendEmailPage />} />
        <Route exact path="/verify-email" element={<VerifyEmailPage />} />
        <Route exact path="/reset-password" element={<ResetPasswordPage />} />
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/verification-code" element={<VerificationCodePage />} />
        <Route exact path="/expired-link" element={<ResetLinkPage />} />
        <Route exact path="/dashboard" element={<DashboardPage />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/404" element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MyRoutes;
