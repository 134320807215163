import { SEO, PageLayout, FastTrackHero, Satisfaction, CourseCarousel,StudentSuccess, } from "../components";
import { INST_DATA } from "../data/instructor";
import InstructorsBlock from "../components/instructorsBlock";
import { useDispatch } from "react-redux";
import { getAnnouncement, getRegions, getCourseSchedules, getTestimonials, getSuccessStory, getCourses, getCourseCarouselData } from "../redux/action";
import { useEffect } from "react";

const FastTrack = () => {
    const dispatch = useDispatch();
   useEffect(() => {
    dispatch(getAnnouncement())
    dispatch(getRegions())
    dispatch(getTestimonials())
    dispatch(getCourses())
    dispatch(getCourseSchedules())
    dispatch(getSuccessStory())
    dispatch(getCourses())
    dispatch(getCourseCarouselData())
  }, [])

 return (
   <>
     <SEO
       url="/fast-track"
       openGraphType="website"
       schemaType="website"
       title= "Fast Track Page"
       description="We help you transition into tech seamlessly."
     />
     <PageLayout>
       <div className="bg-[#FCFCF5] w-full">
         <FastTrackHero/>
       </div>
       <Satisfaction/>
       <InstructorsBlock items={INST_DATA}/>
       {/* <Satisfaction satisfaction/> */}
       <CourseCarousel/>
     </PageLayout>
   </>
 )
}

export default FastTrack;