import React, { useState, useEffect } from 'react';
import './student-work.css';

const StudentWorks = () => {
   const [backgroundOffset, setBackgroundOffset] = useState(0);

   useEffect(() => {
      function handleScroll() {
         const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
         setBackgroundOffset(scrollTop);
      }

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
   }, []);

  const getBackgroundStyle = (index) => {
   const baseOffset = backgroundOffset * 2.5; // Base multiplier for the background offset
   const variableOffset = (index % 2 === 0 ? 1 : -1) * baseOffset / (1 + index * 0.5);
   const minOffset = 5; // Minimum offset to ensure movement is always visible
   const offset = variableOffset + minOffset * (index % 2 === 0 ? 1 : -1);
   return {
      backgroundPositionX: `${offset}px`,
   };
};


   const logoRows = [
      "first_logos_row",
      "second_logos_row",
      "third_logos_row",
      "fourth_logos_row",
      "fifth_logos_row",
      "sixth_logos_row",
   ];

   return (
      <section className="work_logos_section mt-12">
         <div className="sss_title margin_bottom_logos_block">Our Students work for</div>
         {logoRows.map((rowClass, index) => (
            <div key={index} className={rowClass} style={getBackgroundStyle(index)}></div>
         ))}
         <div id="started" />
      </section>
   );
};

export default StudentWorks;
