import styled from 'styled-components';
import XCloseIcon from '../../../images/svg/xCloseIcon';
import Modal from '../modal';

const VideoWrapper = styled.div`
  height: auto !important;
  aspect-ratio: 16/9;
`

const WhyChooseUsModal = ({isOpen, onClose}) => {

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="inline-block align-bottom bg-black rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[800px] sm:w-full">
      <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className='flex justify-between items-center'>
        <h1 className='text-white text-lg font-bold'>Introduction to Business Analysis</h1>
        <div onClick={onClose}>
          <XCloseIcon fill="white" />
        </div>
        </div>
        <VideoWrapper>
          <iframe 
            src="https://www.youtube.com/embed/bA3TBzW-mlw" 
            frameborder="0" 
            allow="autoplay; fullscreen; picture-in-picture" 
            allowfullscreen="" 
            title="Sept Fast Track Morning Session" 
            data-ready="true" 
            style={{
              width: '100%', 
              height: '100%'
            }}>
          </iframe>
        </VideoWrapper>
      </div>
      </div>
    </Modal>
  )
}

export default WhyChooseUsModal;