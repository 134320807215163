import React, { useState } from "react";
import styles from "./instructors.module.css";
import Button from "../button";
import { WhyChooseUsModal } from '../modals';


const InstructorsBlock = ({items}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const handleClose = (e) => {
  
    setIsModalOpen(false);
  };

  return (
    <div className={styles["bg"]}>
      <h1 className={styles["heading"]}>About the instructor</h1>
      <section className={styles["main_block"]}>
        <div className={styles["left_block"]}>
          <img src={items.mainImg} className={styles["left_block_img"]} alt="Instructors" loading="lazy" />
          <h1>I made this intro video for you </h1>
          <Button
            className='py-6 !w-[52%]'
            icon='video'
            variant='transparent'
            onClick={handleClick}
            outline='black'>
            Watch video
          </Button>
        </div>
        <div className={styles["right_block"]}>
          <div className={styles["name_block"]}>
            <h1>{items.fullname}</h1>
            {
              items.socials.map(
                (social) =>
                  <a href="https://www.linkedin.com/in/michael-akpan-455a22168/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"><img src={social} alt="socials" loading="lazy"/></a>
              )
            }
          </div>
          <div className={styles['positions_row']}>
            {
              items.positions.map(
                (position) => (
                  <div className={styles['position']}>
                    {position.name}
                  </div>
                )
              )
            }
          </div>
          <div className={styles["description"]}>
            {
              items.description.map(
                (paragraph) =>
                  <p>
                    {paragraph}
                  </p>
              )
            }
            {/* <h2>Register to see him in class!</h2> */}
          </div>
          <div className={styles['logos']}>
            {
              items.logos.map(({ url, image_url, title }) =>
                <a key={title} href={url} target="_blank" rel="noopener noreferrer" >
                  <img src={image_url} alt={title} loading="lazy"/>
                </a>
              )
            }
          </div>
          <img src={items.signature} alt="Instructor's signature" loading="lazy"></img>
        </div>
      </section >
      {
        isModalOpen &&
        <WhyChooseUsModal isOpen={isModalOpen} onClose={handleClose} />
      }
    </div >
  );
};



export default InstructorsBlock;
