import React from 'react';
import styles from './xCloseIcon.module.css';

const XCloseIcon = ({ fill = '#E91E26', onClick }) => {
   return (
      <svg
         className={styles['rotate_hover']}
         width="13"
         height="14"
         viewBox="0 0 13 14"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         onClick={onClick}
      >
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.234315 11.4345C-0.0781049 11.7469 -0.0781049 12.2535 0.234315 12.5659C0.546734 12.8783 1.05327 12.8783 1.36569 12.5659L5.80045 8.1311L10.7104 13.0411C11.0228 13.3535 11.5294 13.3535 11.8418 13.0411C12.1542 12.7287 12.1542 12.2221 11.8418 11.9097L6.93182 6.99973L11.8414 2.0902C12.1538 1.77778 12.1538 1.27125 11.8414 0.958831C11.5289 0.646411 11.0224 0.646412 10.71 0.958831L5.80045 5.86836L1.36613 1.43404C1.05371 1.12162 0.547175 1.12162 0.234756 1.43404C-0.0776638 1.74646 -0.0776638 2.25299 0.234756 2.56541L4.66908 6.99973L0.234315 11.4345Z"
            fill={fill}
         />
      </svg>
   );
};

export default XCloseIcon;