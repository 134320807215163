import React from 'react'
import AboutStoryCarousel from '../../carousel/aboutStory';
import { ABOUT_STORY } from '../../../data/about-story';
import styles from './about-our-story.module.css'


const AboutStory = () => {
  return (
    <section className={styles['main_wrapper']}>
    <AboutStoryCarousel items={ABOUT_STORY}/>
    </section>
  )
}

export default AboutStory