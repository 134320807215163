import axios from "axios";
import { toast } from "react-toastify";
import { renderValidUrl, downloadPDF } from "../utils/constants";

export const setAppData = hash => dispatch => {
  dispatch({
    type: 'SET_APP_DATA',
    hash: hash
  })
}

export const showModalFunc = () => dispatch => {
  dispatch({
    type: 'SHOW_MODAL',
    showModal: true
  })
}

export const hideModal = () => dispatch => {
  dispatch({
    type: 'HIDE_MODAL',
    showModal: false
  })
}

export const login = (user) => {
  return {
    type: 'LOGIN',
    payload: user,
  };
};

export const logout = () => {
  return {
    type: 'LOGOUT',
  };
};

export const setFormData = hash => dispatch => {
  dispatch({
    type: 'SET_FORM_DATA',
    hash
  })
}

const fetchData = async (dispatch, url, type) => {
  dispatch({ type: 'SET_IS_FETCHING', isFetching: true })

  try {
    const { data } = await axios.get(url)
    dispatch({ type: 'GET_API_REQUEST', hash: { [type]: data } })
  } catch (error) {
    dispatch({ type: 'SET_ERROR', error })
  }
}

export const getAnnouncement = () => async (dispatch) => {
  await fetchData(dispatch, '/announcement', 'announcement')
}

export const getRegions = () => async (dispatch) => {
  await fetchData(dispatch, '/regions', 'regions')
}

export const getTestimonials = () => async (dispatch) => {
  await fetchData(dispatch, '/testimonials', 'testimonials')
}

export const getCourseSchedules = () => async (dispatch) => {
  await fetchData(dispatch, '/course-schedules', 'courseSchedules')
}

export const setCurrentSlide = (slideIndex) => ({
  type: 'SET_CURRENT_SLIDE',
  payload: slideIndex,
});


export const getCourseBanners = () => async (dispatch) => {
  await fetchData(dispatch, '/course-banners', 'courseBanners')
}

export const getBrochure = () => async (dispatch) => {
  await fetchData (dispatch, '/brochure', 'brochure')
}
export const setUserEmail = (email) => ({
  type: 'SET_USER_EMAIL',
  email,
});

export const resetUserEmail = () => ({
  type: 'RESET_USER_EMAIL',
});

export const postRequest = async (url, data, token) => {
  try {
    const response = await fetch(renderValidUrl(url), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { 'Authorization': `Bearer ${token}` })
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData);
    }

    const responseData = await response.json();
    return [true, responseData];
  } catch (error) {
    console.error('Error:', error);
    return [false, error];
  }
};


export const putRequest = async (url, data, token) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };


    const response = await fetch(renderValidUrl(url), {
      method: 'PUT',
      headers,
      body: JSON.stringify(data),
    });

    const responseData = await response.json();
    return [true, responseData];
  } catch (error) {
    console.error('Error:', error);
    return [false, error];
  }
};


export const downloadPdfRequest = async (onClose) => {
  try {
    const { data } = await axios.get('/brochure');
    toast.success('PDF Successfully downloaded');
    downloadPDF(renderValidUrl(data.url));
    onClose();
  } catch (error) {
    toast.error('There was an error downloading the file');
  }
};

export const fetchThirdPartyRequest = async (url) => {
  try {
    const { data } = await axios.get(url);
    return data;
  } catch(error) {
    console.error('Error:', error);  
  }
}

export const getSuccessStory = () => async (dispatch) => {
  await fetchData (dispatch, '/success-stories', 'successStories')
}

export const getCourseCarouselData = () => async (dispatch) => {
  await fetchData (dispatch, '/course-page-carousel', 'courseCarouselData')
}

export const getCourses = () => async (dispatch) => {
  await fetchData (dispatch, '/courses', 'courses')
}

export const getPrices = () => async (dispatch) => {
  await fetchData (dispatch, '/prices', 'prices')
}

export const getCourseInstructors = () => async (dispatch) => {
  await fetchData (dispatch, '/course-instructors', 'courseInstructors')
}

export const getPromotionData = () => async (dispatch) => {
  await fetchData (dispatch, '/promo-popup', 'promotionData')
}

export const getBanner = () => async (dispatch) => {
  await fetchData (dispatch, '/banner', 'bannerData')
}

export const getGoogleRatings = () => async (dispatch) => [
  await fetchData(dispatch, '/google-review', 'googleRatings')
]

export const updateUser = (user) => dispatch => {
  dispatch({
    type: 'UPDATE_USER',
    payload: user,
  })
}

export const updateAvatar = (avatarUrl) => ({
  type: 'UPDATE_AVATAR',
  payload: avatarUrl,
});


export const getUserProfile = () => async (dispatch) => {
  await fetchData(dispatch, '/users/me', 'userProfile')
}