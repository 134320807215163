import React from "react";
import Button from "../../button";
import { FreecourseHero } from "../../../images";
import styles from "./header.module.css";

const FreecourseHeader = () => {
  return (
    <section className={styles["section_no_background"]}>
      <div className={styles["top_banner"]}>
        <div className={styles["banner_info"]}>
          <div className={styles["banner_text"]}>
            <div className={styles["tag"]}>Includes Agile Scrum training</div>

            <div className={styles["banner_title"]}>
              Free Business Analysis crash course
            </div>

            <div className={styles["banner_message"]}>
              <span>
                This is playback of our most recent business analysis crash
                course.
              </span>{" "}
              It is completely free. Play back the moments. We hope this helps
              you get a feel of who we are, what we do and how we do what we do.
              For those who are complete novices to the subject of business
              analysis and scrum, you should be able to understand what business
              analysis is after this free series. What are you waiting for? Dive
              right in.
            </div>
          </div>
        </div>

        <div className={styles["banner_photo"]}>
          <img src={FreecourseHero} alt="banner"/>
        </div>
      </div>
    </section>
  );
};

export default FreecourseHeader;
