import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  FieldName,
  InputBox,
  Input,
  InputContainer,
  Select,
  FieldNameWrapper
} from "../../styles/common";

const InputWrapper = ({ ...props }) => {
  const {
    inputTag = "Input",
    title,
    type = "text",
    placeholder,
    fieldName,
    className,
    options,
    selectedOption,
    requiredMessage,
    patternValue,
    patternMessage,
    compoundValidation = false,
    onChangeHandler,
    control,
    register,
    errors,
    icon,
    onImageClick,
    inputLink,
    url,
    missingDataMessage,
    disabled = false
  } = props;

  return (
    <InputContainer>
      {inputTag === "Check Box" ? (
        <>
          <label htmlFor="checkbox" id="checkbox_wrapper">
            <input
              type="checkbox"
              id="checkbox"
              {...register("subscription", {
                required: "Please check the checkbox to proceed.",
              })}
            />{" "}
            <p>
              Submitting this form is considered consent to contact you 
              regarding our program offerings and promotions via email, sms or calls.
            </p>
          </label>

          {errors.subscription && (
            <span className="errors">{errors.subscription.message}</span>
          )}
        </>
      ) : (
        <>
          <FieldNameWrapper>
            <FieldName>{title}</FieldName>
            {inputLink && <Link className="text-[#fcfcf5] text-xs leading-5 underline mb-[0.3rem]" to={url}>{inputLink}</Link>}
          </FieldNameWrapper>
          {inputTag === "Select" ? (
            <Select
              className={`${className} ${errors[fieldName] && "input_error"}`}
              {...register(fieldName, {
                required: requiredMessage,
                onChange: onChangeHandler ?? null,
              })}
            >
              {options?.map((option) => (
                <option
                  key={option}
                  value={option === "Select" ? "" : option}
                  selected={option === selectedOption}
                >
                  {option}
                </option>
              ))}
              {
                options?.length === 1 && options?.[0] === "Select" &&
                <option
                  value={""}
                  selected={false}
                >
                  {missingDataMessage}
                </option>
              }
            </Select>
          ) : inputTag === "Phone Input" ? (
            <Controller
              control={control}
              name={fieldName}
              rules={{
                required: requiredMessage,
                pattern: {
                  value: /^\d{10,13}$/,
                  message: "Phone number is not valid.",
                },
              }}
              defaultValue={compoundValidation == false ? undefined : []}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <PhoneInput
                  inputClass="phone_input"
                  buttonClass="phone_button"
                  dropdownClass="phone_dropdown"
                  className={`${className} ${errors[fieldName] && "input_error"
                    }`}
                  placeholder="000-000-0000"
                  enableSearch={true}
                  country={"ca"}
                  preferredCountries={["ca", "us", "gb", "ng"]}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  ref={ref}
                />
              )}
            />
          ) : inputTag === "Text Area" ? (
            <textarea
              className={`${className} ${errors[fieldName] && "input_error"}`}
              {...register(fieldName, {
                required: requiredMessage,
              })}
            />
          ) : (
            <InputBox
              tabindex="0"
              className={`${errors[fieldName] && "input_error"}`}
            >
              <Input
                disabled={disabled}
                inputTag={inputTag}
                type={type}
                className={`${className} bg-inherit w-full !border-none focus:!outline-none`}
                placeholder={placeholder}
                {...register(fieldName, {
                  required: requiredMessage,
                  onChange: onChangeHandler ?? null,
                  pattern: patternValue
                    ? {
                      value: patternValue,
                      message: patternMessage,
                    }
                    : null,
                })}
              />
              {icon && <img src={icon} alt="icon" loading="lazy" onClick={onImageClick} className="pr-[10px] cursor-pointer" />}
            </InputBox>
          )}
        </>
      )}
      {inputTag === "Check Box" ? null : (
        <>
          {compoundValidation ? (
            <>
              {errors[fieldName]?.message === `${title} is required` && (
                <span className="errors">{title} is required</span>
              )}
              {errors[fieldName] &&
                errors[fieldName].message !== `${title} is required` && (
                  <div className="requirements">
                    {errors[fieldName]?.message}
                  </div>
                )}
            </>
          ) : (
            <>
              {errors[fieldName] && (
                <span className="errors">{errors[fieldName].message}</span>
              )}
            </>
          )}
        </>
      )}
    </InputContainer>
  );
};

InputWrapper.defaultProps = {
  inputTag: "Input",
  type: "text",
  compoundValidation: false,
  disabled: false
};

InputWrapper.propTypes = {
  inputTag: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  fieldName: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  selectedOption: PropTypes.string,
  requiredMessage: PropTypes.string,
  patternValue: PropTypes.string,
  patternMessage: PropTypes.string,
  compoundValidation: PropTypes.bool,
  onChangeHandler: PropTypes.func,
  control: PropTypes.any,
  register: PropTypes.any,
  errors: PropTypes.any,
  disabled: PropTypes.bool
};

export default InputWrapper;
