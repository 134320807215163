import { recMobile111, recMobile222, recMobile746 } from "../images"

export const ALL_COURSES = {

  'Business Analysis' : 
  [
     {
    imageUrl: recMobile111,
    startDate: "July 25, 2022",
    endDate: "August 24,2022",
    title: "Busines Analysis Bootcamp1",
    description:
      "Course description goes here description here Course descript here description here Course description goes here descript goes here descript goes here descript here",
  },
  {
    imageUrl: recMobile222,
    startDate: "July 25, 2022",
    endDate: "August 24,2022",
    title: "Busines Analysis Bootcamp2",
    description:
      "Course description goes here description here Course descript here description here Course description goes here descript goes here descript goes here descript here",
  },
  {
    imageUrl: recMobile746,
    startDate: "July 25, 2022",
    endDate: "August 24,2022",
    title: "Busines Analysis Bootcamp3",
    description:
      "Course description goes here description here Course descript here description here Course description goes here descript goes here descript goes here descript here",
  },
  ],
  'Project Management' : 
  [
      {
    imageUrl: recMobile746,
    startDate: "July 25, 2022",
    endDate: "August 24,2022",
    title: "Project Management",
    description:
      "Course description goes here description here Course descript here description here Course description goes here descript goes here descript goes here descript here",
  },
  {
    imageUrl: recMobile222,
    startDate: "July 25, 2022",
    endDate: "August 24,2022",
    title: "Project Management",
    description:
      "Course description goes here description here Course descript here description here Course description goes here descript goes here descript goes here descript here",
  },
  {
    imageUrl: recMobile222,
    startDate: "July 25, 2022",
    endDate: "August 24,2022",
    title: "Project Management",
    description:
      "Course description goes here description here Course descript here description here Course description goes here descript goes here descript goes here descript here",
  },
  ],
  'Scrum Master' : 
  [
     {
    imageUrl: recMobile111,
    startDate: "July 25, 2022",
    endDate: "August 24,2022",
    title: "Scrum Master",
    description:
      "Course description goes here description here Course descript here description here Course description goes here descript goes here descript goes here descript here",
  },
  {
    imageUrl: recMobile222,
    startDate: "July 25, 2022",
    endDate: "August 24,2022",
    title: "Scrum Master",
    description:
      "Course description goes here description here Course descript here description here Course description goes here descript goes here descript goes here descript here",
  },
  {
    imageUrl: recMobile111,
    startDate: "July 25, 2022",
    endDate: "August 24,2022",
    title: "Scrum Master",
    description:
      "Course description goes here description here Course descript here description here Course description goes here descript goes here descript goes here descript here",
  },
  ]
}
