import styled from 'styled-components'
import { bgTerms1, bgTerms2 } from '../../images'

const TopBanner = styled.div`
  background-color: #F8D210;
  background-image: url(${bgTerms1}), url(${bgTerms2});
  background-repeat: no-repeat, no-repeat;
  background-size: 98px 98px, 117px 117px;
  background-position: top 8px left 59px, bottom 18px right 45px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  width: 100%;
  height: 220px;
  gap: 8px;
  border-radius: 4px;

  @media only screen and (max-width: 992px) {
    margin-top: 40px;
    height: 244px;
    background-image: none;
    border-radius: 0;
  }
`

const InfoBlock = styled.div`
  width: 653px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media only screen and (max-width: 992px) {
    width: 343px;
  }
`

const ContentWrap = styled.div`
  line-height: 3rem;
  margin-top: 2rem;

  h2, h4 {
    font-weight: 700;
  }

  h2 {
    font-size: 28px;
  }

  h4 {
    font-size: 16px;
  }

  p {
    line-height: 2rem;
    margin-bottom: 1.5rem;
  }

  .decor {
    font-weight: 700;
    text-decoration: underline;
  }

  footer {
    line-height: 1.5rem;
    font-weight: 700;
    margin: 2.5rem 0;

    a {
      color: #333333;
    }
  }
`

const RefundPolicy = () => {
  return (
    <div className='max-w-[1200px] m-auto'>
      <TopBanner>
        <InfoBlock>
          <h1 className='font-bold text-xl sm:text-4xl'>
            Refund Policy
          </h1>
        </InfoBlock>
        <p className='text-center'> Last Updated: September 25, 2024</p>
      </TopBanner>

      <div className='max-w-[800px] m-auto p-8'>

        <ContentWrap>
          <h2>Payment Terms For THESKILLGARAGE Bootcamp</h2>
          <p>Welcome to TheSkillGarage, where we are committed to delivering a transformative educational experience. To ensure clarity and fairness, please familiarize yourself with the following comprehensive payment and cancellation terms, which govern your engagement with our platform and services.</p>
          <p>
            <h2>1. Payment and Refund Policy:</h2> <br />
          <b> Free First Week:</b> We extend a warm welcome to you by offering the first week of our bootcamp absolutely free of charge. This allows you to immerse yourself in our program and gauge its alignment with your learning objectives before making any financial commitment. You can, however, take advantage of our early bird discount if you choose to make payments before week 1 commences.
          </p>

          <p>
           <b>Refund Policy (Pre-Week 2):</b>  Any payments (applies to all payments made towards a training) made before the commencement of the second week of the program are eligible for a full refund less processing fees (processing fees are determined by the payment gateway provider). For a refund to be processed, the request for cancellation must be received before the first class of week 2 begins.
          </p>

        </ContentWrap>

        <ContentWrap>
          <p>
           <b>Non-Refundable Payments (Week 2 Onward):</b>  If payment was made post-week 1 (that is, from the second week onwards), it is non-refundable. After class 1 of week 2 has commenced, no refunds will be granted, regardless of the circumstances.

        </p>

          <h2>2. Cancellation Terms:</h2>
        <p>
        <b>Cancellation Before Next Instalment:</b>  Should you wish to cancel your enrollment, please note that cancellation requests must be submitted at least 48 hours before the next installment payment is due. Any cancellation made after this timeframe will not be honored. And the second instalment will be charged.
        </p>

        <p>
          <b>Termination Due to Non-Payment:</b>  In the event of a missed payment, a grace period of 48 hours will be provided for you to rectify the payment. Failure to do so within this grace period may result in the temporary suspension of your access to course content. Access will be restored upon settlement of the outstanding payment.
        </p>
        <h2>3. Full Payment Terms:</h2>
        <p>
        For participants who have chosen the option of a full upfront payment, please be aware that any request for a refund will be based on a couple of factors: 
        <ol>
          <li><b>1.</b>  If a request is placed before the commencement of week 2, the fee is refundable less the processing fee.</li>
           <li><b>2.</b> If a request is placed anytime between weeks two and four, 67% of the total fee paid, less the processing fee, will be refunded.</li>
           <li><b>3.</b> If a request is placed anytime between weeks four and eight, 37% of the total fee paid, less the processing fee, will be refunded.</li>
           <li><b>4.</b> Requests made after week eight are non-refundable.</li>
        </ol>
      </p>

      <h2>4. Suspension of studies:</h2>


        <p>
        This applies to all payments made towards a training at TheSkillGarage. <br />

          <b>i. Full Payment</b> <br />
          Students who have made full payment can suspend studies and attend any cohort of their choice in their given region within 12 months of registering on the platform. No restrictions apply
        </p>

        <p>
         <h4>ii. Instalment Payment</h4> <br />

          <b>Up to 33% Fee Payment:</b>  Suspension equates to withdrawal. Rejoining requires payment of the full prevailing fees. <br />

          <b>34% to 67% Fee Payment:</b>  Suspension results in platform restrictions. Reinstatement occurs upon payment of the final installment plus a 12.5% deferment fee. <br />

          <b>Full Fee Payment with Suspension:</b>  If suspended but fees continue and are fully paid, students can attend any cohort in their region within 12 months. No restrictions.
        </p>

        <h2> 5. Communication and Updates:</h2> 
        <p>
          We understand the importance of timely communication. Please ensure that your contact information remains current and accurate. Essential information regarding payments, course updates, and announcements will be communicated to the email address you provide during enrollment.

By enrolling in our courses and utilizing our services, you acknowledge that you have read, comprehended, and accepted these payment and cancellation terms. If you have any inquiries or concerns, our dedicated customer support team is readily available to assist you.

Thank you for choosing TheSkillGarage. We are excited to join you on your educational journey and contribute to your growth and success.

Best regards,
        </p>

        <footer>
          For any questions or queries about these Refund Policy, please do not hesitate to contact us at the following e-mail address: <a href="mailto:info@theskillgarage.com">info@theskillgarage.com</a>
        </footer>
        </ContentWrap>
      </div>
    </div>
  )
}

export default RefundPolicy;