import React from 'react'

const Dashboard = () => {
  return (
    <div className='text-black mt-[10rem] w-full h-full'>
      Dashboard Page
    </div>
  )
}

export default Dashboard