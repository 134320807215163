import styled from 'styled-components'
import { bgTerms1, bgTerms2 } from '../../images'

const TopBanner = styled.div`
  background-color: #F8D210;
  background-image: url(${bgTerms1}), url(${bgTerms2});
  background-repeat: no-repeat, no-repeat;
  background-size: 98px 98px, 117px 117px;
  background-position: top 8px left 59px, bottom 18px right 45px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  width: 100%;
  height: 220px;
  gap: 8px;
  border-radius: 4px;

  @media only screen and (max-width: 992px) {
    margin-top: 40px;
    height: 244px;
    background-image: none;
    border-radius: 0;
  }
`

const InfoBlock = styled.div`
  width: 653px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media only screen and (max-width: 992px) {
    width: 343px;
  }
`

const ContentWrap = styled.div`
  line-height: 3rem;
  margin-top: 2rem;

  h2, h4 {
    font-weight: 700;
  }

  h2 {
    font-size: 28px;
  }

  h4 {
    font-size: 16px;
  }

  p {
    line-height: 2rem;
    margin-bottom: 1.5rem;
  }

  .decor {
    font-weight: 700;
    text-decoration: underline;
  }

  footer {
    line-height: 1.5rem;
    font-weight: 700;
    margin: 2.5rem 0;

    a {
      color: #333333;
    }
  }
`

const PrivacyPolicy = () => {
  return (
    <div className='max-w-[1200px] m-auto'>
      <TopBanner>
        <InfoBlock>
          <h1 className='font-bold text-xl sm:text-4xl'>
            Privacy Policy
          </h1>
        </InfoBlock>
        <p className='text-center'> Last Updated: September 25, 2024</p>
      </TopBanner>

      <div className='max-w-[800px] m-auto p-8'>

        <ContentWrap>
          <h2>Privacy Policy</h2>
          <p>
           TheSkillGarage Ltd. takes your privacy seriously and is fully committed to protecting your personal data. This policy outlines the types of data we collect, how we use it, and the measures we take to ensure your information remains secure. By accessing our services, you agree to the practices described in this policy.
          </p>

          <h2>1. Information We Collect</h2>
          <p>
          <b>Personal Data:</b>  We collect personal information such as your name, email address, phone number, and other relevant details required for course enrollment, account creation, and communication. <br />
          <b>Usage Data:</b>  We collect technical information related to your use of our website, including IP addresses, browser type, time spent on pages, and interactions with our content to help improve user experience and platform performance.
          </p>

        </ContentWrap>

        <ContentWrap>
          <h2>2. Payment Information</h2>
          <p>
           We do not store any payment information on our website or servers. All payments are processed through secure, third-party payment gateways, which comply with industry-standard security and encryption protocols (e.g., SSL). These third-party payment processors handle your transaction data, and TheSkillGarage does not have access to, or control over, your credit card or banking information.
        </p>
         <h2>3. How We Use Your Data</h2>
        <p>
          We use your personal data to:
         <li>Provide and manage our services, including course enrollment, progress tracking, and certification.</li>
         <li>Communicate with you regarding course updates, promotions, and important information.</li>
         <li>Analyze usage trends to enhance the functionality of our website and services.</li>
         <li>Fulfill legal obligations and comply with regulatory requirements.</li>
        </p>
       <h2>4. Data Sharing and Disclosure</h2>
        <p>
          We do not sell, rent, or trade your personal information with third parties. However, we may share data in the following circumstances:
          <li>Service Providers: We may share data with trusted third-party vendors who assist us in providing our services (e.g., cloud storage providers, communication tools), under strict confidentiality agreements.</li>
          <li>⁠Legal Compliance : We may disclose your personal data if required to do so by law or in response to valid legal requests, such as subpoenas or court orders.</li>
        </p>
        <h2>5. Data Security</h2>
        <p>
       We employ stringent security measures to protect your personal data, including encryption, secure data transmission, and restricted access protocols. Only authorized personnel have access to personal data, and they are bound by confidentiality obligations. Despite our efforts, no system is entirely foolproof, and we cannot guarantee the absolute security of your information.
      </p>

      <h2>6. Data Retention</h2>
        <p>
         We retain your personal data only for as long as necessary to fulfill the purposes outlined in this policy or as required by law. Once your data is no longer needed, it will be securely deleted or anonymized.
        </p>
        <h2>7. International Data Transfers</h2>
        <p>
        If you are accessing our services from outside the country in which our servers are located, please note that your data may be transferred and stored across borders. We ensure that any international data transfers comply with applicable data protection laws and that adequate safeguards are in place.
        </p>

        <h2>8. Your Rights</h2> 
        <p>
        Under applicable data protection laws, you have the right to:
        <li>⁠Access the personal data we hold about you.</li>
        <li>Rectify inaccurate or incomplete information.</li>
        <li>Request deletion of your data, subject to certain legal limitations.</li>
        <li>Object to the processing of your personal data for marketing purposes.</li>
        <li>Withdraw consent for data processing, where consent is required.</li>
        To exercise these rights, please contact us at <b> info@theskillgarage.com</b> We may require you to verify your identity before processing such requests to protect your privacy.
        </p>

        <h2>9. Third-Party Links</h2>
        <p>Our website may contain links to third-party websites. Please be aware that we are not responsible for the privacy practices or the content of these external sites. We encourage you to review their privacy policies before sharing any personal information.</p>
        <h2>10. Changes to This Policy</h2>
        <p>We reserve the right to update or modify this privacy policy at any time. Any changes will be posted on this page, and we will notify you of significant changes via email or through our platform. Your continued use of our services after such changes will constitute your acceptance of the revised policy.</p>

        <footer>
          For any questions or queries about these Privacy Policies in general, or if you wish to exercise your rights, please do not hesitate to contact us at the following e-mail address: <a href="mailto:info@theskillgarage.com">info@theskillgarage.com</a>
        </footer>
        </ContentWrap>
      </div>
    </div>
  )
}

export default PrivacyPolicy;