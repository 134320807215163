import styled from "styled-components";
import Button from "../../button";
import TSGBg from "../../tsg-bg";
import { startJourneyImg } from "../../../images";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { MoonLoader } from "react-spinners";
import { CALENDLY_URL } from "../../../utils/constants";

const Container = styled.div`
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  gap: 21px;
  overflow: hidden;

  @media only screen and (max-width: 1200px) {
    width: calc(100vw - 24px);
  }

  @media only screen and (max-width: 992px) {
    width: 100vw;
    flex-direction: column;
    gap: 0px;

  }
`;

const TextAreaWrap = styled.div`
  max-width: 515px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TitleWrap = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  text-align: left;
  color: #1e1b2e;
  @media only screen and (max-width: 992px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 32px;
  cursor: pointer;
  z-index: 999;

  @media only screen and (min-width: 768px) {
    z-index: 9;
  }
`;

const StartYourJourney = () => {
  const [toggleBookACall, setToggleBookACall] = useState(false);
  const [calLoaded, setCalLoaded] = useState(false);

  useCalendlyEventListener({
    onEventTypeViewed: () => setCalLoaded(true),
  });


  return (
    <TSGBg bgStyle="slopeRight">
      <Container>
        <TextAreaWrap className="px-4 pt-[100px]">
          {window.innerWidth < 992 ? (
            <TitleWrap>Start your tech <br /> career journey</TitleWrap>
          ) : (
            <TitleWrap>
              Start your tech<br />
              career journey
            </TitleWrap>
          )}
          <p className="font-normal text-base">
            Book a call today and get one-on-one 
            guidance from our student success advisor who will 
            help answer your questions and set you on the right track.
          </p>
          {!toggleBookACall && (
            <Button
              onClick={() => {
                setToggleBookACall(true);
              }}
              icon="whiteArrow"
              className="!w-[180px] mt-[10px]"
            >
              Book a Call
            </Button>
          )}
        </TextAreaWrap>

        <div className="sm:w-[568px] w-full relative m-auto p-2">
          {!toggleBookACall && (
            <img
              src={startJourneyImg}
              alt="banner"
              className="-mb-[15px] w-full"
              loading="lazy"
            />
          )}
          {toggleBookACall && (
            <div className="overflow-scroll max-h-[640px] relative m-auto">
               <CloseIconWrapper
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setToggleBookACall(false);
            setCalLoaded(false);
          }}
        >
          <AiOutlineClose size={32} />
        </CloseIconWrapper>
              <div
                className="w-[95px] h-[92px] 
                right-0 absolute 
                border-l-[100px] border-l-transparent
                border-t-[100px] border-t-white
                border-r-[0px] border-r-transparent"
              />
              <InlineWidget
                url={CALENDLY_URL}
                pageSettings={{
                  hideEventTypeDetails: true,
                  hideGdprBanner: false,
                }}
              />
            </div>
          )}
          {toggleBookACall && (
            <div
              className={`w-[400px] flex h-[400px] items-center justify-center absolute top-0 left-0 z-20 ${
                calLoaded && "hidden"
              }`}
            >
              <MoonLoader color="black" />
            </div>
          )}
        </div>
      </Container>
    </TSGBg>
  );
};

export default StartYourJourney;
