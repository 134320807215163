import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Twitter from '../../../images/black-twitter.svg';
import LinkedIn from '../../../images/linkedIn2.svg';
import Button from '../../button';

const ProfileInformation = ({ setIsEditing }) => {
  const user = useSelector((state) => state.user);
  if (!user) {
    
    return <p>Loading...</p>;
  }

  return (
    <Container>
      <Header>
        <Title>Profile Information</Title>
        <Button variant='bg-transparent' outline='black' className='button-size' onClick={() => { setIsEditing(true) }}>Edit</Button>
      </Header>

      <UserInfo>Username<br /><span className='info-value'>{user.username}</span></UserInfo>
      <UserInfo>Date of Birth<br /><span className='info-value'>{user.dateOfBirth}</span></UserInfo>
      <UserInfo>Phone Number<br /><span className='info-value'>{user.phoneNumber}</span></UserInfo>
      <UserInfo>Address<br /><span className='info-value'>{user.address}</span></UserInfo>
      <UserInfo>Social Media<br />
        <div className='icons'>
          <IconLink href={user.linkedin_username} target="_blank" rel="noopener noreferrer">
            <img src={LinkedIn} alt="LinkedIn" loading='lazy' />
          </IconLink>
          <IconLink href={user.twitter_username} target="_blank" rel="noopener noreferrer">
            <img src={Twitter} alt="Twitter" loading='lazy' />
          </IconLink>
        </div>
      </UserInfo>
    </Container>
  );
};

const Container = styled.div`
  align-self: flex-start;
  padding: 40px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 1px 3px 20px 0px rgba(1, 1, 1, 0.07);

  .button-size {
    width: 90px;
  }

  @media (max-width: 600px) {
    padding: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Title = styled.p`
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;

  @media (max-width: 424px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

const UserInfo = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
  
  .info-value {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  .icons {
    display: flex;
    gap: 16px;
  }
`;

const IconLink = styled.a`
  display: inline-block;
`;

export default ProfileInformation;
