import Cookies from "js-cookie";

export const BACKEND_URL = process.env.REACT_APP_API_URL.replace('/api', '')

export const renderValidUrl = (url) => {
  if (url?.startsWith('http://') || url?.startsWith('https://')) {
    // Absolute URL, return as is
    return url;
  } else {
    // Relative URL, append backendUrl
    return BACKEND_URL + url;
  }
};
 const expirationTimeInMinutes = 60;
 export const expirationDate = new Date(new Date().getTime() + (expirationTimeInMinutes * 60 * 1000));

export const setCookieWithExpiry = (name, value) => Cookies.set(name, value, { expires: expirationDate });


export const getTokenFromCookie = () => {
  const token = Cookies.get('token');
  return token;
};


export const filterRegionsByCourseTitle = (courseObject, bannerTitle) => {
    // Get the region names where at least one course has the specified title
    const filteredRegions = Object.keys(courseObject || {}).filter((region) =>
      courseObject[region].courses.some((course) => course.title === bannerTitle)
    );

    return filteredRegions;
  }

export const downloadPDF = (url, linkTitle='brochure.pdf') => {
  const link = document.createElement('a');
  link.href = url;
  link.download = linkTitle;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const allowablePagePaths = {
  Home: "/",
  About: "/about",
  FAQ: "/resources/faq",
};

export function showPromoOnThisPage(pagePath, data) {
  const { showOnPages, showOnAllPages } = data;

  // if showOnAllPages is true show on allowable pages
  if (showOnAllPages) {
    for (let path in allowablePagePaths){
      if (allowablePagePaths[path] === pagePath) return true
    }
    return false
  }

  // If showOnPages is an array and the current pagePath is included in the showOnPages array, return true
  if (showOnPages && Array.isArray(showOnPages)) {
    for (let path in allowablePagePaths){
      if (allowablePagePaths[path] === pagePath && showOnPages.includes(path)) return true
    }
    return false;
  }

  // If none of the conditions above match, return false
  return false;
}

export const PARTNERDROPDOWN = [
  "Select",
  "Become a Mentor/Educator/Instructor",
  "Become a UX/UI Volunteer",
  "Become a Software Developer Volunteer",
  "Sponsorship",
  "Work with us",
  "Other",
]

export const WORKDROPDOWN = [
  "Select",
  "Become a Mentor/Educator/Instructor",
  "Become a UX/UI Volunteer",
  "Become a Software Developer volunteer", 
  "Become a BA"
]

export const PASSWORDREGEX = new RegExp(
  /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/
);

export const PASSWORDLIST = ['At least 8 characters', 'Numbers and special characters','Uppercase and lowercase'];

// export const CALENDLY_URL = "https://calendly.com/theskillgarage-info/30min"
export const CALENDLY_URL = "https://meet.brevo.com/theskillgarage-intro-call"