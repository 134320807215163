import React from "react";
import styles from "./auth.module.css";
import {
  logoWhiteImg,
} from "../../images";

const ResetLink = () => {

  return (
    <section className={styles["authWrapper"]}>
      <div className={`${styles["authContent"]} !w-full lg:!w-[640px] !bg-[rgba(255,255,255,0.1)] !p-10 text-center`}>
        <div className={styles["imageWrapper"]}>
          <img src={logoWhiteImg} alt="logo" loading="lazy"/>
        </div>
        <h2 className={`${styles["authHeader"]} !text-xl !lg:text-[28px]`}>The password reset link is expired</h2>
      </div>
    </section>
  );
};

export default ResetLink;
