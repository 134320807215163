import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


import {
  PageLayout,
  SEO,
  StartYourJourney,
  StudentStoriesList1,
  StudentVideos,
  StudentWorks,
  TestimonialHeader,
  TestimonialNumberRoll,
} from "../components";
import { getAnnouncement, getRegions, getCourseSchedules, getTestimonials, getSuccessStory, getCourses, getCourseCarouselData } from "../redux/action";


function Testimonial() {
  const dispatch = useDispatch();
  const {testimonials} = useSelector(state => state); // Access the testimonials data


  useEffect(() => {
    dispatch(getAnnouncement())
    dispatch(getRegions())
    dispatch(getTestimonials())
    dispatch(getCourses())
    dispatch(getCourseSchedules())
    dispatch(getSuccessStory())
    dispatch(getCourses())
    dispatch(getCourseCarouselData())
  }, [dispatch])
   
  useEffect(() => {
  if (testimonials.length === 0) {
    dispatch(getTestimonials());
  }
}, [dispatch, testimonials.length]);

  return (
    <>
      <SEO
        url="/testimonials"
        openGraphType="website"
        schemaType="website"
        title="TheSkillGarage"
        description="We help you transition into tech seamlessly."
      />
      <PageLayout>
        <div className="bg-[#FCFCF5] w-full">
          <TestimonialHeader />
          <TestimonialNumberRoll />
          <StudentVideos />
          <StudentWorks />
          <StudentStoriesList1 data={testimonials} />
          <StartYourJourney testimonials />
        </div>
      </PageLayout>
    </>
  );
}

export default Testimonial;
