import { SEO, VerifyEmail } from "../components";

function VerifyEmailPage() {
  return(
    <>
      <SEO 
      url="/verify-email"
      openGraphType="website"
      schemaType="website"
      title = "Verify Email Page"
      description="Verify Email Page"
      />
      <div className="bg-[#000] w-full h-full lg:h-auto">
        <VerifyEmail/>
      </div>
    </>
  )
}

export default VerifyEmailPage;