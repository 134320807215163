// Date method
export function dates(tags) {
  var dates = "";
  var i;

  dates += "<" + tags + ">" + "Select" + "</" + tags + ">";

  for (i = 1; i < 32; i++) {
    dates += "<" + tags + " value='" + i + "'>" + i + "</" + tags + ">";
  }

  // You can call the class multiple times
  var multiple_list = document.getElementsByClassName("bear-dates");
  for (i = 0; i < multiple_list.length; i++) {
    multiple_list[i].innerHTML = dates;
  }
}

// Months method
export function months(tags) {
  // List all the months with array
  var list_months = [
    { value: 0, label: 'Select' },
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
  ];

  var i;
  var months = "";

  for (i = 0; i < list_months.length; i++) {
    months +=
      "<" +
      tags +
      " value='" +
      list_months[i].value +
      "'>" +
      list_months[i].label +
      "</" +
      tags +
      ">";
  }

  // You can call the class multiple times
  var multiple_list = document.getElementsByClassName("bear-months");
  for (i = 0; i < multiple_list.length; i++) {
    multiple_list[i].innerHTML = months;
  }
}

// Year method
export function years(tags, startY, endY) {
  var i;
  var years = "";

  years += "<" + tags + " value='0'>" + "Select" + "</" + tags + ">";

  for (i = startY; i <= endY; i++) {
    years +=
      "<" + tags + " value='" + i + "'>" + i + "</" + tags + ">";
  }

  // You can call the class multiple times
  var multiple_list = document.getElementsByClassName("bear-years");
  for (i = 0; i < multiple_list.length; i++) {
    multiple_list[i].innerHTML = years;
  }
}
