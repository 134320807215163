import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import StudentSuccessCarousel from "../../carousel/studentSuccessCarousel";
import styles from "./student-success-module.css";
import { getSuccessStory } from "../../../redux/action";

const StudentSuccess = ({title, course }) => {
  const dispatch = useDispatch();
  const { successStories } = useSelector((state) => state);
  
  useEffect(() => {
    if (successStories.length === 0) {
      dispatch(getSuccessStory());
    }
  }, []);

  // Filter the success stories based on the course if provided
  const filteredSuccessStories = course
    ? successStories.filter(
        (item) => item.course && item.course.title === course
      )
    : successStories;

  return (
    <section className={styles["section_no_background"]}>
      <div>
        <StudentSuccessCarousel title={title} items={filteredSuccessStories} />
      </div>
    </section>
  );
};

StudentSuccess.defaultProps={
  title:"Student Success Stories"
}
export default StudentSuccess;
