import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  alertIcon,
  copyIcon2,
  PolygonImage,
  copyIconCanada,
  copyIconChristmas,
  copyIconEaster,
  copyIconHalloween,
  copyIconPresent,
  copyIconStPatrick,
  copyIconValentines
} from "../../../images";
import styles from "./banner.module.css";

// to choose Coupon type from list
const COPY_ICONS_TYPE =
{
  'Canada': copyIconCanada,
  'Christmas': copyIconChristmas,
  'Easter': copyIconEaster,
  'Halloween': copyIconHalloween,
  'Present': copyIconPresent,
  'StPatrick': copyIconStPatrick,
  'Valentines': copyIconValentines
}

// to handle copy function
async function copyTextToClipboard(text) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}

const AlertCopyBanner = ({ escapeable, alert, copy, couponCode, copyIconType, message }) => {
  /*
    This component implements the alert and copy banners
    Inputs
    escapeable - Takes input true/false to make banner escapeable or not. Default is true
    alert - Indicates the type of banner is alert
    copy - Indicates the type of banner is copy
    couponCode - For copy Banners it indicates the Discount Code to be copied to clipboard 
    copyIconType - Indicates the icon to be used for Copy Banner. Available icons - Canada, Christmas, Easter, StPatricks, Present, Valentines
    message - common for all kinds of banner. For copy banner accepts html with tags in <span> indicating to be emboldened. 
  */

  // to handle copy click
  const [isCopied, setIsCopied] = useState(false)
  const handleCopy = (e) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(couponCode)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => { setIsCopied(false); }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
    e.stopPropagation();
  }

  // Appends couponCode to message.
  const finalMessage = `${message}${copy ? ` <span>${couponCode}</span>` : ''}`

  return (
    <>
      <div className={styles["banner"]}>
        {
          alert &&
          <div className={styles["banner_icon"]}>
            <img src={alertIcon} alt="alert-icon" loading="lazy"/>
          </div>
        }
        {
          copy && copyIconType && (copyIconType in COPY_ICONS_TYPE) &&
          <div className={`${styles["banner_icon"]} ${styles["copy_banner_icon"]}`}>
            <img src={COPY_ICONS_TYPE[copyIconType]} alt="alert-icon" loading="lazy"/>
          </div>
        }
        <p className={
          [
            styles["banner_message"],
            escapeable && styles["escapeable_banner_message"],
            copy && styles["copy_banner_message"]
          ]
            .join(' ')
        }
          dangerouslySetInnerHTML={{ __html: finalMessage }}
        />
        {
          copy &&
          <div className={styles["copy_btn"]} onClick={handleCopy}>
            <img src={copyIcon2} alt="Copy-icon" loading="lazy"/>
          </div>
        }
        {
          isCopied &&
          <div className={styles["copy_popup"]} onClick={() => setIsCopied(false)}>
            <img src={PolygonImage} alt="polygon" loading="lazy"/>
            <div className={styles["copy_popup_message"]}><p>Code copied to the clipboard! 🥳</p></div>
          </div>
        }
      </div>
    </>
  );
};

AlertCopyBanner.propTypes = {
  escapeable: PropTypes.string.isRequired,
  alert: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  couponCode: PropTypes.string,
  copyIconType: PropTypes.string,
  message: PropTypes.string.isRequired,
};

export default AlertCopyBanner;
