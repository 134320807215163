
const INITIAL_STATE = {
  appData: {},
  formData: {
    defaultRegion: 'US & Canada'
  },
  showModal: false,
  isFetching: false,
  announcement: null,
  regions: null,
  testimonials: [],
  error: null,
  courseCarouselData: null,
  courseSchedules: null,
  brochure: null,
  successStories: [],
  courses: null,
  courseInstructors: null,
  bannerData: null,
  promotionData: null,
  prices: [],
  googleRatings: null,
  isAuthenticated: false,
  user: null,
  userEmail: "",
  currentSlide: 0,
}


export const reducer = (previousState = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_APP_DATA':
      return {
        ...previousState,
        appData: {
          ...previousState.appData,
          ...action.hash,
        }
      };
    case 'SET_FORM_DATA':
      return {
        ...previousState,
        formData: {
          ...previousState.formData,
          ...action.hash
        }
      };
    case 'SHOW_MODAL':
      return {
        ...previousState,
        showModal: true,
      };
    case 'HIDE_MODAL':
      return {
        ...previousState,
        showModal: false,
      };  
    case 'SET_IS_FETCHING':
      return {
        ...previousState,
        isFetching: action.isFetching
      };
    case 'SET_ERROR':
      return {
        ...previousState,
        error: action.error,
        isFetching: false
      };
    case 'GET_API_REQUEST':
      return {
        ...previousState,
        ...action.hash,
        isFetching: false
      }
      case 'LOGIN':
        return {
          ...previousState,
          isAuthenticated: true,
          user: action.payload,
        };
      case 'LOGOUT':
        return {
          ...previousState,
          isAuthenticated: false,
          user: null,
        };
        case 'SET_USER_EMAIL':
          return {
            ...previousState,
            userEmail: action.email,
          };
        case 'RESET_USER_EMAIL':
          return {
            ...previousState,
            userEmail: '',
          };
          case "UPDATE_USER":
      return {
        ...previousState,
        user: action.payload,
      };
       case 'SET_CURRENT_SLIDE':
      return {
        ...previousState,
        currentSlide: action.payload,
      };
case 'UPDATE_AVATAR':
  return {
    ...previousState,
    user: {
      ...previousState.user,
      avatarUrl: action.payload,
    },
  };
    default:
      return previousState;
  }
}