import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { FirstLastName } from "../../styles/common";
import Button from '../button';
import InputWrapper from '../inputWrapper';
import ThankYou from '../modals/thankYou';
import styles from './referForm.module.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getCourses } from '../../redux/action';

export default function ReferForm() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCourses())
  }, [])

  const courses = useSelector(state => state.courses)
  const formData = useSelector(state => state.formData)
  const [courseArray, setCourseArray] = useState([])

useEffect(() => {
  const courseTitles = courses
    ? Object.values(courses).flatMap((region) =>
        region.courses.map((course) => course.title)
      )
    : [];

  const uniqueCourseTitles = new Set(courseTitles);
  const filteredCourseTitles = [...uniqueCourseTitles];

  setCourseArray(filteredCourseTitles);
}, [courses]);


  const [successPopUp, setPopup] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue
  } = useForm({ mode: "all" });

  const [loading, setLoading] = useState(false);

  const isAuthenticated = useSelector(state => state.isAuthenticated)
  const user = useSelector(state => state.user)

 

  useEffect(() => {
    if (isAuthenticated && user) {
      setValue("firstname", user.firstname)
      setValue("lastname", user.lastname)
      setValue("email", user.email)
    }
    else {
      setValue("firstname", '')
      setValue("lastname", '')
      setValue("email", '')
    }
  }, [user, isAuthenticated])


  const onSubmit = async (formData) => {
    try {
      setLoading(true); // Set loading state to true during submission

      if (formData.email === formData.friendemail) {
        throw new Error("You can't refer yourself. Please try again!")
      }

      const { data } = await axios.post('/refer-friends', {
        userName: `${formData.firstname} ${formData.lastname}`,
        userEmail: formData.email,
        friendName: `${formData.friendfirstname} ${formData.friendlastname}`,
        friendEmail: formData.friendemail,
        course: formData.selectedcourse,
      });

      if (data?.details?.errors?.length > 0) {
        toast.error(data.message);

        for (const error of data.details.errors) {
          toast.error(error.message);
        }
      } else {
        toast.success(data.message);
        setPopup(true)
        reset();
      }
    } catch (error) {
      console.log("AXIOS error", error);
      toast.error(error.message);
    } finally {
      setLoading(false); // Set loading state back to false when the submission is complete
    }
  }

  function handleCose() {
    setPopup(false)
  }

  return (
    <section className={styles['container']}>
      <form className={styles['form']} onSubmit={handleSubmit(onSubmit)}>
        <h1 className={styles['heading1']}>Your Name (Referrer) </h1>
        <FirstLastName>
          <InputWrapper
            title="First Name"
            placeholder="Philips"
            fieldName="firstname"
            requiredMessage="Enter valid firstname"
            register={register}
            errors={errors}
            disabled={isAuthenticated}
          />

          <InputWrapper
            title="Last Name"
            placeholder="Dankano"
            fieldName="lastname"
            requiredMessage="Enter valid lastname"
            register={register}
            errors={errors}
            disabled={isAuthenticated}
          />
        </FirstLastName>

        <InputWrapper
          title="Email"
          type="email"
          placeholder="Example@example.com"
          fieldName="email"
          requiredMessage="Email is required"
          patternValue={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
          patternMessage="Invalid email address"
          compoundValidation={true}
          register={register}
          errors={errors}
          disabled={isAuthenticated}
        />

        <h1 className={styles['heading2']}> Friend's Name (Your Referral) </h1>
        <FirstLastName>
          <InputWrapper
            title="Friend First Name"
            placeholder="John"
            fieldName="friendfirstname"
            requiredMessage="Enter valid firstname"
            register={register}
            errors={errors}
          />

          <InputWrapper
            title="Friend Last Name"
            placeholder="Doe"
            fieldName="friendlastname"
            requiredMessage="Enter valid lastname"
            register={register}
            errors={errors}
          />
        </FirstLastName>

        <InputWrapper
          title="Email"
          type="email"
          placeholder="Example@example.com"
          fieldName="friendemail"
          requiredMessage="Email is required"
          patternValue={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
          patternMessage="Invalid email address"
          compoundValidation={true}
          register={register}
          errors={errors}
        />

        <InputWrapper
          inputTag="Select"
          title="Select Course"
          fieldName="selectedcourse"
          options={["Select", ...courseArray]}
          requiredMessage="this is required"
          register={register}
          errors={errors}
          missingDataMessage="There are no courses available now."
        />

        <Button loading={loading} type="submit" size variant={loading ? "disabled" : "secondary"}>Send referral code</Button>
      </form>
      {successPopUp && <ThankYou referSuccess onClose={handleCose} />}
    </section>
  )
}
