import { useMediaQuery } from '@react-hook/media-query';
import React, { useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { XCloseIcon } from '../../../images';
import { FirstLastName } from "../../../styles/common";
import InputWrapper from '../../inputWrapper';
import styles from './freeForm.module.css';
import { postRequest } from '../../../redux/action';
import { toast } from "react-toastify";

function FreeCourseForm({ url }) {
  const videoRef = useRef([]);
  const isMobile = useMediaQuery('(max-width: 992px)');
  const [showForm, setShowForm] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmittedSuccessfully, setFormSubmittedSuccessfully] = useState(false);


const handlePlay = () => {
  if (formSubmittedSuccessfully) {
    videoRef.current?.getInternalPlayer()?.playVideo();
  } else {
    setShowForm(true);
  }
};

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all", defaultValues: { phoneNumber: "+1" } });
    const onSubmit = async (data) => {
      try {
        const [success, responseData] = await postRequest("/api/free-video-forms", data);
        if (success) {
          toast.success("Form submitted successfully!");
          setFormSubmitted(true);
          setFormSubmittedSuccessfully(true);
          setShowForm(false);

          // Prepare Pabbly webhook payload
          const pabblyData = new URLSearchParams({
            phoneNumber: data.phoneNumber,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            howDidYouHearAboutUs: data.howDidYouHearAboutUs,
            subscription: data.subscription 
          }).toString();

          // Send data to Pabbly webhook
          const webhookUrl = process.env.REACT_APP_PABBLY_WEBHOOK_URL_UNLOCK;
          const webhookResponse = await fetch(webhookUrl, {
            method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: pabblyData,
      });

          if (!webhookResponse.ok) {
            console.error('Pabbly webhook submission failed');
          } else {
            console.log('Pabbly webhook submission successful');
          }
        } else {
          console.error("Error submitting form:", responseData);
          toast.error("Error submitting form!");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("Error submitting form!");
      }
    };
  const mobileClasses = isMobile ? 'w-375' : 'bg-gray-900';
  const formClasses = showForm ? styles['form-margin'] : 'mb-10';
  const className = `md:mb-10 mb-100 min-w-300 max-w-992 md:min-w-993 md:max-w-3500 w-375 md:w-1200 mx-auto relative z-10 ${mobileClasses} ${formClasses}`;

  return (
    <section className={className}>
      {formSubmitted && !showForm ? (
        <div
          className="absolute inset-0 md:w-1200 w-375  z-10 opacity-50 cursor-auto"
          onClick={() => setShowForm(false)}
          style={{ pointerEvents: showForm ? 'auto' : 'none' }}
        ></div>
      ) : (
        <div
          className="absolute inset-0 z-10 opacity-0 cursor-default"
           onClick={() => !formSubmittedSuccessfully && setShowForm(true)}
        ></div>
      )}
   <Container>
        <ReactPlayer
          className="react_player"
          ref={videoRef}
          url="https://www.youtube.com/embed/videoseries?list=PLD0BkXx0yFm9RzAY61OdO743Dj48TKXLb"
          width="100%"
          height="100%"
          background="#00000"
          tabIndex={0}
          config={{
            youtube: {
              playerVars: { autoplay: 0, controls: 1, rel: 0, modestbranding: 1 },
            },
          }}
          controls={formSubmittedSuccessfully}
          onPlay={handlePlay}
        />
      </Container>

      {showForm && (
        <FormModal>
          <span className={styles['top_btn']}>
            <XCloseIcon onClick={handleCloseForm} fill="white" />
          </span>
          <div className={styles["modal_body_right"]}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FirstLastName>
                <InputWrapper
                  title="First Name"
                  placeholder="Philips"
                  fieldName="firstName"
                  requiredMessage="Enter valid firstname"
                  register={register}
                  errors={errors}
                />

                <InputWrapper
                  title="Last Name"
                  placeholder="Dankano"
                  fieldName="lastName"
                  requiredMessage="Enter valid lastname"
                  register={register}
                  errors={errors}
                />
              </FirstLastName>

              <InputWrapper
                title="Email"
                type="email"
                placeholder="Example@example.com"
                fieldName="email"
                requiredMessage="Email is required"
                patternValue={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
                patternMessage="Invalid email address"
                compoundValidation={true}
                register={register}
                errors={errors}
              />

              <InputWrapper
                inputTag="Phone Input"
                title="Phone Number"
                fieldName="phoneNumber"
                requiredMessage="Phone number is required"
                compoundValidation={true}
                control={control}
                register={register}
                errors={errors}
              />

              <InputWrapper
                inputTag="Select"
                title="How did you hear about us?"
                fieldName="howDidYouHearAboutUs"
                options={["Select", "LinkedIn", "Facebook", "Instagram"]}
                requiredMessage="this is required"
                register={register}
                errors={errors}
              />

              <InputWrapper
                inputTag="Check Box"
                register={register}
                errors={errors}
              />

              <div className='mx-auto'>
                <button type="submit" className={styles['submit_btn']}>Unlock Videos</button>
              </div>
            </form>
          </div>
        </FormModal>
      )}
    </section>
  );
};

const Container = styled.div`
  position: relative;
  padding-top: 56.25%;
  
  .react_player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const FormModal = styled.div`
  padding: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 484px;
  background: #000000;
  border: 1px solid #D8D8D8;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
  border-radius: 3px;
  z-index: 20;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export default FreeCourseForm;
