import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { Link, useNavigate } from 'react-router-dom';
import { postRequest, setUserEmail, showModalFunc, updateUser } from '../../../redux/action';
import styles from './signUp.module.css';
import { whiteEyeIcon, whiteCloseEyeIcon, logoWhiteImg } from '../../../images';
import InputWrapper from '../../inputWrapper';
import { useForm } from 'react-hook-form';
import { FirstLastName } from '../../../styles/common';
import Button from '../../button';
import PasswordChecker from "../passwordChecker"
import Login from '../login';
import { AuthModal } from '../../modals';

const SignUp = () => {
  const dispatch = useDispatch();
  const {showModal} = useSelector(state=> state)

  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors,isDirty, isValid },
  } = useForm({ mode: 'all', defaultValues: { phone_number: '+1' } });

  const passwordValue = watch("password", "");
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();

 const onSubmit = async (formData) => {
    const [success, responseData] = await postRequest("/api/auth/local/register", {
      username: formData.username,
      firstname: formData.firstname,
      lastname: formData.lastname,
      email: formData.email,
      password: formData.password,
      phoneNumber: formData.phone_number
    });

    if (responseData?.error) {
      toast.error(responseData.error.message);
    } else {
      reset();
      const userEmail = formData.email;
      dispatch(setUserEmail(userEmail));
      dispatch(updateUser(formData));
      navigate('/verify-email');
      toast.success("Registration Successful.")
    }
  };


  return (
    <>
    <section className={styles['signup_wrapper']}>
       <div className={styles['inner_form']}>
          <div className={styles['title']}>
           <Link to={'/'}>
            <img src={logoWhiteImg} alt="logo"  className="w-[80px] h-[35px]"loading='lazy'/>
           </Link>
            <h2 className={styles['title_text']}>Create Account</h2>
          </div>
          <form
            className={styles['login_form']}
            onSubmit={handleSubmit(onSubmit)}
          >
            <FirstLastName>
              <InputWrapper
                title="First Name"
                fieldName="firstname"
                requiredMessage="Enter valid firstname"
                register={register}
                errors={errors}
              />

              <InputWrapper
                title="Last Name"
                fieldName="lastname"
                requiredMessage="Enter valid lastname"
                register={register}
                errors={errors}
              />
            </FirstLastName>

            <InputWrapper
              title="Username"
              type="text"
              fieldName="username"
              requiredMessage="Username is required"
              register={register}
              errors={errors}
            />

            <InputWrapper
              title="Email"
              type="email"
              placeholder="Example@example.com"
              fieldName="email"
              requiredMessage="Email is required"
              patternValue={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
              patternMessage="Please enter a valid email adress. Example: name@gmail.com"
              compoundValidation={true}
              register={register}
              errors={errors}
            />

           <div className='mb-3'>
           <InputWrapper
                inputTag = "Password"
                title="Create Password"
                type={showPassword ? 'text' : 'password'}
                fieldName="password"
                requiredMessage="Password is Required"
                compoundValidation={true}
                register={register}
                errors={errors}
                icon={ showPassword || !isDirty ? whiteEyeIcon : whiteCloseEyeIcon  }
                onImageClick={handleShowPassword}
                patternValue = {/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/}
                patternMessage="Low Password Strength"
                className={'!text-[14px]'}
              />
                <PasswordChecker passwordValue={passwordValue}/>
           </div>
              
            <InputWrapper
                inputTag = "Password"
                title="Confirm Password"
                type={showPassword ? 'text' : 'password'}
                fieldName="confirmpassword"
                requiredMessage="Password is Required"
                compoundValidation={true}
                {...register("confirmpassword", { validate:(value)=> value === passwordValue || "The new password fields do not match. Please try again." })}
                register={register}
                errors={errors}
                icon={ showPassword || !isDirty ? whiteEyeIcon : whiteCloseEyeIcon  }
                onImageClick={handleShowPassword}
                className={'!text-[14px]'}                
              />

            <InputWrapper
              inputTag="Phone Input"
              title="Phone Number"
              fieldName="phone_number"
              requiredMessage="Phone number is required"
              compoundValidation={true}
              control={control}
              register={register}
              errors={errors}
            />

            <label className='text-[#D8D8D8] font-normal text-xs leading-5 mb-[-.5rem]'>
              By creating an account, you accept our{' '}
              <href className="underline">Terms</href>.
            </label>
            <Button size variant={isValid ? "secondary" :"disabled"} type="submit" className='mt-4 bg-[#D8D8D8]'>
              Create account
            </Button>
          </form>
            <label className='text-[#D8D8D8] font-normal text-xs leading-5 mt-8'>Already have an account? <a className="underline" onClick={()=>dispatch(showModalFunc())}>Log in</a></label>
        </div>
    </section>
    {showModal && (
      <AuthModal>
        <Login />
      </AuthModal>
    )}
    </>
  );
};

export default SignUp;
