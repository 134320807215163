import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import Button from '../button';
import ArrowRight from '../../images/bg/arrowCircleRight.svg'
import ArrowLeft from '../../images/bg/arrowCircleLeft.svg'
import { avatar2 } from '../../images';
import { setCurrentSlide } from '../../redux/action';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-modal-video/scss/modal-video.scss';
import styles from './studentSuccess.module.css'
import { SuccessStoryModal } from '../modals';
import ImageTag from "../../components/image-tag";

const StudentSuccessCarousel = ({ items, title }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);
   const dispatch = useDispatch();
   const currentSlide = useSelector(state => state.currentSlide);

   const handleClick = () => {
      setIsModalOpen(true);
   };

   const handleClose = () => {
      setIsModalOpen(false);
   };

   const sliderRef = useRef(null);


const location = useLocation();

   const settings = {
     dots: false,
     arrows: true,
     infinite: true,
     speed: 900,
     slidesToShow: 1,
     slidesToScroll: 1,
     afterChange: (index) => dispatch(setCurrentSlide(index))
   };

   const barWidth = 100 / items.length;
   const barPosition = (currentSlide + 0) * barWidth;

   const nextSlide = () => {
     sliderRef.current.slickNext();
   };

   const prevSlide = () => {
     sliderRef.current.slickPrev();
   };

   const handleClickBar = (event) => {
     const barContainerWidth = event.currentTarget.offsetWidth;
     const clickPositionX = event.clientX - event.currentTarget.getBoundingClientRect().left - (barContainerWidth / 2);
     const clickPercent = clickPositionX / barContainerWidth + 0.5;
     const newSlideIndex = Math.floor(clickPercent * items.length);
     const newBarPosition = (newSlideIndex * barWidth) + (barWidth / 2);
     dispatch(setCurrentSlide(newSlideIndex));
     sliderRef.current.slickGoTo(newSlideIndex);
     const bar = document.querySelector(`.${styles.bar}`);
     bar.style.left = `${newBarPosition}%`;
   };

  return (
    <section className={styles["section_no_background"]}>
    <div className={styles["student_success_stories"]}>
       <h3 className='sss_title'>{title}</h3>
     <div className={styles["sss_carousel_row"]}>
       <div className="carousel_body">
    <div className="slider-container">
      <Slider ref={sliderRef} {...settings}>

       {items.filter(item => !(window.location.pathname.includes('fast-track') && item.fastTrack === true)).map((item, index) => (
        <div className={styles["card"]} key={index}>
          <div className={styles["container"]}>
            <div className={styles["left_success"]}>
              <h3 className={styles['success_text']}><span className={styles['test-span']}>"</span>{item.description}</h3>
              <div className={styles["line"]}></div>
           {item.videoURL && 
              <Button variant='buttonWhite' className={styles['btn_size']} icon='video' outline= 'black' onClick={() => handleClick(index)}>
                Watch Student Stories
              </Button>
            }

            </div>
            <div className={styles["right_success"]}>
            <div className={styles["frame"]}>
            <div className={styles["image_block"]}>
            <ImageTag imageUrl={item.image} alt={item.firstName} placeholderImg={avatar2} loading="lazy" />
            </div>
            </div>
            <div className={styles["label_block"]}>
              <div className={styles["label_title"]}>{item.firstName} {item.lastName}</div>
              <div className={styles["label_text"]}>{item.jobTitle}</div>
            </div>
            </div>
          </div>
          </div>
        ))}
      </Slider>
        {isModalOpen && <SuccessStoryModal isOpen={isModalOpen} onClose={handleClose} items={items[currentSlide]} firstName={items[currentSlide].firstName} lastName={items[currentSlide].lastName}
    jobTitle={items[currentSlide].jobTitle} url={items[currentSlide].videoURL}/>}
    </div>

       </div>
     </div>
    </div>
  <div className={styles["pagination"]} onClick={handleClickBar}>
    <div className={styles.barContainer} onClick={handleClickBar}>
      <div className={styles.bar} style={{ width: `${barWidth}%`, left: `${barPosition}%` }} />
    </div> 
  </div>

   <div className={styles["flex_btn_left"]}>
     <button onClick={prevSlide}>
      <img src={ArrowLeft} alt="arrow left" loading='lazy' />
     </button>
    </div>
    <div className={styles['flex_btn_right']}>
      <button onClick={nextSlide}>
        <img src={ArrowRight} alt="arrow right" loading='lazy' />
      </button>
    </div>
    </section>
  );
};

export default StudentSuccessCarousel;