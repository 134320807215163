import React from 'react'
import ReactPlayer from 'react-player'

const CoursePlayer = ({course}) => {
  return (
    <ReactPlayer 
    url={course}
    controls={true}
    playIcon={<button>Play</button>}
    />  )
}

export default CoursePlayer