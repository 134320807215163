import { SEO, PageLayout, PrivacyPolicy } from "../components";

export default function Privacy() {
  return (
    <>
      <SEO
        url="/privacy-policy"
        title="Privacy Policy"
      />
      <PageLayout>
        <div className="bg-gray w-full">
          <PrivacyPolicy />
        </div>
      </PageLayout>
    </>
  )
}