import React from 'react';
import { logoWhiteImg } from "../../../../images";
import { ModalContainer, ModalWrapper } from '../../../../styles/common';

const ResetPasswordEmailNotification = () => {
    return (
        <ModalWrapper>
            <ModalContainer>
                <div className='inner_div'>
                    <img className='image' src={logoWhiteImg} alt="nav-logo" loading='lazy'/>
                    <div className='title'>Check Your Email</div>
                    <div className='description'>
                        We have sent an email with instructions to reset your password to
                        <p><span className='email'>email@email.com</span>.</p>
                    </div>
                    <p>
                        Please check your inbox and follow the instructions in the email to reset your password.
                    </p>
                </div>
                <p className='footer'>
                    If you do not receive an email from us within a few minutes, please check your spam folder or contact us for assistance.
                </p>
            </ModalContainer>
        </ModalWrapper>
    );
}

export default ResetPasswordEmailNotification;