import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../button';
import { checkBoxIcon, cancelBlack } from '../../images';

const NewsletterForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubscribe = () => {
    setIsModalOpen(true);
  };

  return (
    <FormContainer>
      {isModalOpen ? (
        <ThankYouMessage>
          <CloseButton onClick={() => setIsModalOpen(false)}>
            <img src={cancelBlack} alt="cancel" loading='lazy'/>
          </CloseButton>

          <Content>
            <img src={checkBoxIcon} alt="checkbox" loading='lazy'/>
            <ThankYouTitle>Thank you for subscribing!</ThankYouTitle>
            <ThankYouDescription>
              Check your email for the latest updates, insightful articles, and exclusive content.
            </ThankYouDescription>
          </Content>
        </ThankYouMessage>
      ) : (
        <>
          <Title>Stay Informed and Inspired!</Title>
          <Description>
            Subscribe to our newsletter for the latest updates, insightful articles, and exclusive content delivered directly to your inbox.
          </Description>
          <form>
            <NameInput>
              <Label htmlFor="Name">Name</Label>
              <Input type="text" placeholder="Full Name" />
            </NameInput>

            <EmailInput>
              <Label htmlFor="email">Email</Label>
              <Input type="email" placeholder="Example@example.com" />
            </EmailInput>
          </form>
          <ButtonStyle>
            <Button
              variant="black"
              outline="black"
              className="!w-[291px] mt-[10px]"
              icon="whiteArrow"
              onClick={handleSubscribe}
            >
              Subscribe for Newsletter
            </Button>
          </ButtonStyle>
        </>
      )}
    </FormContainer>
  );
};

// Styled components for the form
const FormContainer = styled.div`
  background-color: #f8d210;
  padding: 32px;
  border-radius: 4px;
  width: 382.23px;
  text-align: left;
  height: 406px;
  position: relative;
`;

const Title = styled.h2`
  font-size: 20px;
  color: #000;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 16px;
`;

const Description = styled.p`
  font-size: 14px;
  color: #000; /* Black text */
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
`;

const NameInput = styled.div``;

const Label = styled.label`
  font-size: 14px;
  font-weight: 400;
  color: #000;
  line-height: 20px;
`;

const EmailInput = styled.div`
  margin-bottom: 16px;
`;

const Input = styled.input`
  width: 293px;
  padding: 16px;
  margin-bottom: 8px;
  border: 2px solid #000;
  border-radius: 4px;
  background: none;
  color: #000;

  &::placeholder {
    font-weight: 400;
    font-size: 12px;
    color: #1e1b2e;
    opacity: 0.6;
  }

  &:focus {
    outline: none;
  }
`;

const ButtonStyle = styled.div``;

const ThankYouMessage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 380px;
  height: 406px;
  background: #F8D210;
  border-radius: 4px;
  padding: 32px
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0px;
  right: 0px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
`;

const Content = styled.div`
  text-align: left;
  margin-top: 90px
`;

const ThankYouTitle = styled.h2`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-top: 4px;
  margin-bottom: 16px;
`;

const ThankYouDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #000;
`;

export default NewsletterForm;
