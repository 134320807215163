import classNames from "classnames";
import PropTypes from "prop-types";
import { SpinnerCircularFixed } from 'spinners-react';
import styles from "./button.module.css";
import {
  play,
  arrowRightLongIcon,
  leftWhiteArrow,
  footerTelegramIcon,
  yellowTelegramIcon,
  blackArrowRight,
  shareICon,
  shareFB,
  shareLI,
  shareLink,
  shareTT,
} from "../../images";

const VARIANT = {
  primary: styles.btnPrimary,
  secondary: styles.btnSecondary,
  white: styles.btnWhite,
  black: styles.btnBlack,
  transparent: "bg-transparent",
  disabled: styles.btnDisabled
};

const OUTLINE = {
  black: styles.blackOutline,
  white: styles.whiteOutline,
  yellow: styles.yellowOutline,
};

const iconDirection = {
video: styles.video,
telegram: styles.video,
yellowTelegram: styles.video
}

const ICONS = {
  video: play,
  blackArrow: arrowRightLongIcon,
  whiteArrow: leftWhiteArrow,
  telegram: footerTelegramIcon,
  yellowTelegram: yellowTelegramIcon,
  blackArrowRight: blackArrowRight,
  shareICon: shareICon,
  shareFB: shareFB,
  shareTT: shareTT,
  shareLI: shareLI,
  shareLink: shareLink,
};

const Button = ({
  type = "button",
  variant = "primary",
  size,
  children = null,
  icon,
  className = "",
  outline = "",
  loading,
  onClick,
  ...restProps
}) => {
  const classes = classNames(
    styles.btnContainer,
    size && styles.btnFull,
    VARIANT[variant],
    outline && OUTLINE[outline],
    className
  );
  return (
    <button {...restProps} type={type} className={classes} onClick={onClick}>
      {
        loading ? (
          <SpinnerCircularFixed color={'#f8d210'} style={{ height: '30px' }} thickness={'150'}/>
        ) : (
          <span
          className={
            icon && (iconDirection[icon])
          }
        >
          {children}
          {icon && <img src={ICONS[icon]} alt="icon" loading="lazy" />}
        </span>
        )
      }
    </button>
  );
};

export default Button;

Button.propTypes = {
  variant: PropTypes.string,
  outline: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  size: PropTypes.string,
  loading: PropTypes.bool
};