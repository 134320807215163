import React, { useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import {
  MatchDownIcon,
  MatchUpIcon,
  YellowArrowLeft,
  YellowArrowRight,
} from "../../images";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./fastTrackSchedule.module.css";
import { FAST_TRACK_SCHEDULE } from "../../data/fast-track-schedule";

const FastTrackSchedule = () => {
 const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlide2, setCurrentSlide2] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
const [showRightArrow, setShowRightArrow] = useState(true);


 const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  beforeChange: (current, next) => {
    setActiveIndex(next);
    setShowLeftArrow(next !== 0);
    setShowRightArrow(next !== FAST_TRACK_SCHEDULE.length - 1);
  },
  afterChange: setCurrentSlide,
};


  const barWidth = 100 / FAST_TRACK_SCHEDULE.length;
  const isDesktop = useMediaQuery({ minWidth: 992 });

const nextSlide = () => {
  sliderRef.current.slickNext();
  if (!isDesktop) {
    setCurrentSlide2(sliderRef.current.slickGoTo(currentSlide + 1));
  }
};


const prevSlide = () => {
  if (showLeftArrow) {
    sliderRef.current.slickPrev();
    if (isDesktop && currentSlide === 1) {
      setCurrentSlide2(sliderRef.current.slickGoTo(0) || 0);
    }
    if (!isDesktop) {
      setCurrentSlide2(sliderRef.current.slickGoTo(currentSlide - 1));
    }
  }
};


   const titleUpOrDown = (order) => {
    if (order % 2) {
      return 1;
    }
    return 3;
  };

  

  const handleClickBar = (event, index) => {
    event.preventDefault();
    sliderRef.current.slickGoTo(index);
    setCurrentSlide(index);
    setActiveIndex(index);
  
  };  


  return (
    <section className={styles["section_no_background"]}>
      <div className={styles['top_stroke']}>
      {FAST_TRACK_SCHEDULE.map((item, index) => (
          <>
       <div
                  className={styles["week_parent_block"]}
                  key={item.id}
                  onClick={(e) => handleClickBar(e, index)}
                >
                  <div className={styles["week_item"]}>
                    <div
                      className={`${styles["label_match"]} ${
                        titleUpOrDown(item.id) < 2
                          ? styles["match_up"]
                          : styles["match_down"]
                      }`}
                    >
                      <div className={styles["match"]}>
                        {titleUpOrDown(item.id) < 2 ? (
                          <MatchUpIcon
                            color={index === activeIndex ? "#f8d210" : ""}
                          />
                        ) : (
                          <MatchDownIcon
                            color={index === activeIndex ? "#f8d210" : ""}
                          />
                        )}
                      </div>

                      <div
                        className={styles["week_title"]}
                        style={{ order: titleUpOrDown(item.id) }}
                      >
                        {item?.duration}
                      </div>
                    </div>

                    <div
                      className={`${styles["week_order"]} ${
                        index === activeIndex
                          ? styles["week_active_background"]
                          : styles[""]
                      }`}
                    >
                      {`${item?.session}`}
                    </div>
                  </div>
                  {item?.id < FAST_TRACK_SCHEDULE?.length && (
                    <div
                      className={styles["os"]}
                      v-if="week.order < weeks.length"
                    ></div>
                  )}
                </div></>

        ))}
        

      </div>
    <div className={styles["student_success_stories"]}>
        <div className={styles["sss_carousel_row"]}>
          <div className="carousel_body">
            <div className="slider-container">
              <Slider ref={sliderRef} {...settings}>
                {FAST_TRACK_SCHEDULE.map((item, index) => (
                  <div
                  key={item.id}
                  >
                    <div className={styles["slide_header"]}>
                      <div className={styles["right_section"]}>
                        <div className={styles["order_week"]}>
                          <p>{`${item.sessionTime}`}</p>
                          <div className={styles["week_title1"]}>
                            {item.sessionTitle}
                          </div>
                        </div>
                      </div>
                      <div className={styles["tag"]}>
                        <span>{item?.time}</span>
                      </div>
                    </div>
                    <div className={styles["slide_body"]}>
                      <div className={styles["description_section"]}>
                        <div className={styles["description"]}>{item.description}</div>
                    
                      </div>
                      <div className={styles["points_section"]}>
                        {item.pointItems.map((point, index) => (
                          <div className={styles["point_item_row"]}>
                            <div className={styles["in_point_item_row"]}>
                              <div className={styles["point_icon"]}>
                                <div className={styles["disk_dot"]}></div>
                              </div>

                              <div className={styles["point_text"]}>
                                {point.points}
                              </div>
                            </div>
                            <div
                              className={`${styles["vertical_line"]} ${
                                index === 0 ? styles["first"] : ""
                              } ${
                                index === item.pointItems.length - 1
                                  ? styles["last"]
                                  : ""
                              }`}
                            ></div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    <div id="arrow_left" className={styles["flex_btn_left"]}>
  {showLeftArrow && (
    <button onClick={prevSlide}>
      <img src={YellowArrowLeft} alt="arrow left" loading="lazy" />
    </button>
  )}
</div>
<div id="arrow_right" className={styles["flex_btn_right"]}>
  {showRightArrow && (
    <button onClick={nextSlide}>
      <img src={YellowArrowRight} alt="arrow right" loading="lazy"/>
    </button>
  )}
</div>

    </section>
  );
};

export default FastTrackSchedule;