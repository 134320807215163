import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import arrowUp from "../../../images/ArrowUp.svg";

const BackToTop = ({ showGoTop, setShowGoTop }) => {
    const handleVisibleButton = () => {
        setShowGoTop(window.pageYOffset > 300)
    }

    const handleScrollUp = () => {
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        window.addEventListener('scroll', handleVisibleButton)

        return () => window.removeEventListener('scroll', handleVisibleButton);
    }, [])

    return (
        <Container>
            <div className={showGoTop ? 'fadeIn' : 'fadeOut'} onClick={handleScrollUp}>
                <img src={arrowUp} alt='arrow up icon' loading='lazy'/>
                <p>Back to top</p>
            </div>
        </Container>
    )
}

const Container = styled.div`
    div {
        position: fixed;
        bottom: 100px;
        right: 32px;
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgb(255, 255, 255);
        box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
        color: #000;
        border-radius: 5px;
        text-align: center;
        padding: 10px;
        font-size: 12px;
        box-sizing: border-box;
        user-select: none;
        cursor: pointer;
        z-index: 999999;
    }

    .fadeIn {
        opacity: 1;
        transform: translateY(0);
        transition: all .5s ease;
    }
    
    .fadeOut {
        opacity: 0;
        transform: translateY(100px);
        transition: all .5s ease;
    }

    p {
        margin-top: 10px;
    }
`;

export default BackToTop