import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Card from '../articleCards/cards';
import { BLOG_CARD_DATA } from '../../../data';
import styled from 'styled-components';
import NewsletterForm from '../../subscribeNewsletter';

function RelatedPosts() {
    const { title } = useParams(); // Get the title from the URL

    useEffect(() => {
        // Scroll to the top of the page when the component is mounted
        window.scrollTo(0, 0);
    }, [title]); 

    // Find the current article's data based on the title
    const currentArticle = BLOG_CARD_DATA.find(card => card.title === decodeURIComponent(title));

    // Get the category of the current article
    const currentCategory = currentArticle ? currentArticle.category : null;

    // Filter the card data to get related posts, and limit to two items
    const relatedPosts = BLOG_CARD_DATA
        .filter(card => card.category === currentCategory && card.title !== currentArticle.title)
        .slice(0, 2); // Limit to only two related posts

    return (
        <Container>
            <h2 className="header">Related Posts</h2>

            <div className="container__box">
                {relatedPosts.map((post, index) => (
                    <Card
                        key={index}
                        category={post.category}
                        image={post.image}
                        author={post.author}
                        date={post.date}
                        readTime={post.readTime}
                        title={post.title}
                        description={post.description}
                        link={post.link}
                    />
                ))}

                <NewsletterForm />
            </div>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    
    @media only screen and (max-width: 992px) {
       padding: 0px 16px;
    }

    .header {
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
        text-align: center;
    }

    .container__box {
        display: flex;
        flex-direction: row;
        gap: 27px;

        @media only screen and (max-width: 992px) {
            flex-direction: column;
        }
    }
`;

export default RelatedPosts;
