import { SEO, SignUp } from "../components";

function SignUpPage(){
    return (
        <>
          <SEO
            url="/signup"
            openGraphType="website"
            schemaType="website"
            title="Signup"
            description="Signup"
          />
         <SignUp/>
        </>
      );
    }
export default SignUpPage