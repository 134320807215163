import React, { useState } from 'react';
import styled from 'styled-components';
import { arrowDownBlack } from '../../../images';
import { BLOG_CARD_DATA } from '../../../data';
import Checkbox from './checkbox';

const SelectCategory = ({ onCategorySelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Extract unique categories from BLOG_CARD_DATA
  const categories = [...new Set(BLOG_CARD_DATA.map(card => card.category))];

  const handleCategorySelect = (category) => {
    const isSelected = selectedCategories.includes(category);
    let updatedCategories;

    if (isSelected) {
      updatedCategories = selectedCategories.filter(c => c !== category);
    } else {
      updatedCategories = [...selectedCategories, category];
    }

    setSelectedCategories(updatedCategories);
    onCategorySelect(updatedCategories);
  };

  return (
    <DropdownContainer>
      <Button onClick={toggleDropdown}>
        Select Categories 
        <Arrow isOpen={isOpen}>
          <img src={arrowDownBlack} alt="arrow" loading='lazy'/>
        </Arrow>
      </Button>
      {isOpen && (
        <DropdownMenu>
          {categories.map((category, index) => (
            <DropdownItem key={index} onClick={() => handleCategorySelect(category)}>
              <Checkbox 
                checked={selectedCategories.includes(category)} 
              />
              <Label>{category}</Label>
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const Button = styled.button`
  width: fit-content;
  height: 38px;
  background-color: #0000000A;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Arrow = styled.span`
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.2s;
`;

const DropdownMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 16px;
  top: 100%;
  left: 0;
  padding: 16px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1;
  width: 173px;
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const Label = styled.label`
  cursor: pointer;
  font-size: 14px;
  margin-left: 8px;

  @media only screen and (max-width: 992px) {
    color: black;
 }
  
`;

export default SelectCategory;
