import React, { useState } from "react";
import { googleBlackIcon, googleColorIcon, starIcon } from "../../images";
import styles from "./google.module.css";

const GoogleFloater = ({ratings}) => {
  const [isHovered, setIsHovered] = useState(false);


  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  const handleTouch = () => {
    setIsHovered(true);
    setTimeout(() => {
      setIsHovered(false);
    }, 500);
  };

  return (
      <a
        href="https://www.google.com/maps/place/TheSkillGarage/@43.762494,-79.1770849,17z/data=!4m8!3m7!1s0x4aad7a79926b6459:0x5ccfe3dcaef14d28!8m2!3d43.7624901!4d-79.1748909!9m1!1b1!16s%2Fg%2F11t0pw3s35"
        target="_blank"
        rel="noopener noreferrer"
        className={`${styles["google-rating"]} ${
            isHovered ? styles["hover"] : ""
          }`}
          onMouseEnter={handleHover}
          onMouseLeave={handleLeave}
          onTouchStart={handleTouch}
      >
        { isHovered ? 
          <img src={googleColorIcon} alt="google-color icon" loading="lazy"/> :
          <img src={googleBlackIcon} alt="google-black icon" loading="lazy"/>
        }
        <span>{ratings && ratings}</span>
        {isHovered &&
          Array.from({ length: ratings ? ratings : 0 }, (_, index) => (
            <img key={index} src={starIcon} alt={`star-${index}`} loading="lazy"/>
          ))
        }
      </a>
  );
};

export default GoogleFloater;
