import React, { useState } from 'react';
import styled from 'styled-components';

const Newsletter = () => {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  }

  return (
    <Container>
      <p>Newsletter Subscription</p>
      <label class="form-switch">
        <input type="checkbox" onChange={handleCheckboxChange} checked={checked} />
        <i></i>
      </label>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 24px;
  align-self: flex-start;
  padding: 40px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 1px 3px 20px 0px rgba(1, 1, 1, 0.07);

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 600px) {
    padding: 16px;
  }

  p {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;

    @media (max-width: 424px) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .form-switch {
    display: inline-block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .form-switch i {
    position: relative;
    display: inline-block;
    margin-right: .5rem;
    width: 46px;
    height: 26px;
    background-color: #e6e6e6;
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;
  }
  
  .form-switch i::before {
    content: "";
    position: absolute;
    left: 0;
    width: 42px;
    height: 22px;
    background-color: #fff;
    border-radius: 11px;
    transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
    transition: all 0.25s linear;
  }
  
  .form-switch i::after {
    content: "";
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: #fff;
    border-radius: 11px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    transform: translate3d(2px, 2px, 0);
    transition: all 0.2s ease-in-out;
  }
  
  .form-switch:active i::after {
    width: 28px;
    transform: translate3d(2px, 2px, 0);
  }
  
  .form-switch:active input:checked + i::after {
    transform: translate3d(16px, 2px, 0);
  }
  
  .form-switch input {
    display: none;
  }
  
  .form-switch input:checked + i {
    background-color: #F8D210;
  }
  
  .form-switch input:checked + i::before {
    transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
  }
  
  .form-switch input:checked + i::after {
    transform: translate3d(22px, 2px, 0);
  }
`;

export default Newsletter