import React, {useRef, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import styles from '../thankYou/thankYou.module.css'
import { hideModal } from '../../../redux/action';
import XCloseIcon from "../../../images/svg/xCloseIcon";
import { useOutsideClick } from '../../../hooks/useClickOutside';


const AuthModal = ({ children }) => {
  const dispatch = useDispatch();

  const modalRef = useRef();

  const handleClose = () => dispatch(hideModal());

  useOutsideClick(modalRef, handleClose);

   return (
      <section className={styles["modal-shadow"]} >
      <div className={styles["inner"]} ref={modalRef}>
        <span className={styles["top_btn"]}>
          <XCloseIcon onClick={() => handleClose()} fill="white" />
        </span>
           {children}
         </div>
      </section>
   );
};

export default AuthModal;
