import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ReactCountryFlag from "react-country-flag";
import {
  ellipseIcon,
  XCloseIcon,
  whiteLinkedInIcon,
  whiteTwitterIcon,
  smallWhiteLinkedInIcon,
  smallWhiteTwitterIcon,
} from "../../../images";
import Button from "../../button";
import Modal from "../modal";
import ReactPlayer from "react-player";
import React from "react";

const VideoWrapper = styled.div`
  height: auto !important;
  aspect-ratio: 16/9;
`;

const SuccessStoryModal = ({
  isOpen,
  onClose,
  firstName,
  lastName,
  fullName,
  jobTitle,
  icon,
  date,
  flag,
  url,
  items
}) => {
  const navigate = useNavigate();
  
 // Function to convert Vimeo URL to embeddable URL
  const getEmbeddableUrl = (url) => {
    // Check if the URL is already an embeddable URL
    if (url.includes("player.vimeo.com/video") || url.includes("youtube.com/embed")) {
      return url;
    }

    // Vimeo URL handling
    const vimeoRegex = /vimeo.com\/(\d+)(?:\/([a-zA-Z0-9]+))?/;
    const vimeoMatch = url.match(vimeoRegex);
    if (vimeoMatch && vimeoMatch[1]) {
      const videoId = vimeoMatch[1];
      const identifier = vimeoMatch[2] ? `/${vimeoMatch[2]}` : "";
      return `https://player.vimeo.com/video/${videoId}${identifier}`;
    }

    // YouTube URL handling
    const youtubeRegex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
    const youtubeMatch = url.match(youtubeRegex);
    if (youtubeMatch && youtubeMatch[1]) {
      return `https://www.youtube.com/embed/${youtubeMatch[1]}`;
    }

    // If the URL doesn't match any known patterns, return the original URL
    return url;
  };

  const embeddableUrl = getEmbeddableUrl(url);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="inline-block align-bottom bg-black rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[800px] sm:w-full">
        <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="flex justify-between items-center">
            <div>
              <div className="flex items-center">
                <h1 className="font-bold font-poppins text-white text-base leading-6">
                  {firstName && lastName ? `${firstName} ${lastName}` : fullName}
                </h1>
                {icon &&
                  icon.map((data, index) => (
                    <a className={"ml-2"} href="" key={index}>
                      {data === "LinkedIn" ? (
                        <img src={smallWhiteLinkedInIcon} alt="linkedIn Icon" loading="lazy"/>
                      ) : (
                        <img src={smallWhiteTwitterIcon} alt="twitter Icon" loading="lazy"/>
                      )}
                    </a>
                  ))}
              </div>
              <div className="flex justify-between mb-4">
                <p className="font-poppins font-normal text-white text-xs md:text-base leading-6 opacity-80">
                  {items.title}
                </p>
                {flag && (
                  <ul className="flex list-disc ml-6">
                    <li className="font-poppins font-normal text-white text-xs md:text-base leading-6 opacity-80">
                      {date}
                    </li>
                    <li className="text-white ml-8">
                      <ReactCountryFlag
                            countryCode={flag || "US"}
                            svg
                            style={{
                              width: "1.5em",
                              height: "1.5em",
                            }}
                            alt="flag"
                          />
                    </li>
                  </ul>
                )}
              </div>
            </div>
            <div onClick={onClose} className="mb-12">
              <XCloseIcon fill="white" />
            </div>
          </div>
          <VideoWrapper>
            <ReactPlayer
            url={url}
            controls={true}
            width='100%'
            height='100%'
            playIcon={<button>Play</button>}
            />
          </VideoWrapper>
         <div className="text-white flex flex-col items-center leading-10 text-lg mt-5">
          <p className="font-poppins font-normal md:font-medium text-white text-center text-base md:text-lg leading-7 md:leading-28">
            {items.course && items.course.title
              ? `Start your ${items.course.title} journey today.`
              : `Start your ${items.title} journey today.`}
          </p>
          <div className="w-full sm:w-[50%] py-2">
            <Button
              onClick={() => {
                window.scrollTo(0, 0);  // Scroll to the top
                navigate(`/courses/${items.course?.slug || items.slug}`);
              }}
              variant="secondary"
              icon="blackArrow"
              size
            >
              See course details
            </Button>
          </div>
        </div>

        </div>
      </div>
    </Modal>
  );
};

export default SuccessStoryModal;