import React from 'react';
import styled from 'styled-components';
import { logoutIcon } from "../../images";
import Button from '../button';
import Newsletter from './Newsletter';
import PhotoContainer from './PhotoContainer';
import PasswordProfileIndex from './password';
import PaymentProfileIndex from './payment';
import ProfileInformationIndex from './profileInformation';
import { logout } from '../../redux/action';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

const ProfileIndex = () => {
  const dispatch = useDispatch()

   const handleLogOut = () => {
    Cookies.remove('token');
    dispatch(logout());
  }
  return (
    <Container>
      <PhotoContainer />
      <div>
        <ProfileInformationIndex />
        <PasswordProfileIndex />
        <PaymentProfileIndex />
        <Newsletter />
        <Button variant='bg-transparant' onClick={handleLogOut} outline='black' className='logout-btn'><img src={logoutIcon} alt='logout icon' loading='lazy'/> Log Out</Button>
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 40px;
  width: min(100%, 1200px);
  margin: 120px auto;
  padding: 32px 0;
  box-sizing: border-box;

  .logout-btn {
    margin-top: 48px;
    width: 142px;
    box-sizing: border-box;
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1.2fr 3fr;
    padding: 32px 16px;
  }
  
  @media (max-width: 900px) {
    gap: 20px;
  }
  
  @media (max-width: 685px) {
    grid-template-columns: 1fr;
    padding: 32px 16px;
  }
`;

export default ProfileIndex