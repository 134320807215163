import React from "react";
import styles from "./info.module.css";
import { CARD_FIRST_COLUMN } from "../../../data";
import ImageTag from "../../image-tag";

const CourseInfo = ({ course }) => {
  return (
    <section className={styles["section_first_background"]}>
      <div className={styles["background_section_body"]}>
        <div className={styles["card_row"]}>
          {course.text_and_cards.map(
            (item, index) =>
              item.firstColumn &&
              item.firstColumn.map((item, index) => (
                <div className={styles["card_item"]} key={index}>
                  <div className={styles["icon_card"]}>
                     <ImageTag imageUrl={item.icon.url} alt={item.name} />
                    <div className={styles["card_title"]}>{item.title}</div>
                    <div className={styles["card_text"]}> {item.text}</div>
                  </div>
                </div>
              ))
          )}
        </div>

        <div className={styles["card_row"]}>
          {course.text_and_cards.map(
            (item, index) =>
              item.secondColumn &&
              item.secondColumn.map((item, index) => (
                <div className={styles["card_item"]} key={index}>
                  <div className={styles["icon_card"]}>
                     <ImageTag imageUrl={item.icon.url} alt={item.name} />
                    <div className={styles["card_title"]}>{item.title}</div>
                    <div className={styles["card_text"]}> {item.text}</div>
                  </div>
                </div>
              ))
          )}
        </div>
      </div>
    </section>
  );
};

export default CourseInfo;
