import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { postRequest } from '../../redux/action';
import styles from './verifyEmail.module.css';
import { logoWhiteImg } from '../../images';
import Button from '../button';
import { useNavigate } from 'react-router-dom';

const VerifyEmail = () => {
  const userEmail = useSelector((state) => state.userEmail);
  const navigate = useNavigate();

  const handleResendEmailClick = async () => {
    const [success, responseData] = await postRequest("/api/auth/send-email-confirmation", {
      email: userEmail,
    });

    if (responseData?.error) {
      toast.error(responseData.error.message);
    } else {
      navigate('/resend-email');
      toast.success("Verification Email has been sent Successful.")
    }
  };

  return (
    <div className={styles['main_container']}>
      <div className={styles['top_layout']}>
        <div>
          <img src={logoWhiteImg} alt="logo" loading='lazy'/>
        </div>
        <h2 className={styles['header']}>Verify your email address</h2>
        <p className={styles['sub_heading']}>
          We've sent a verification email to the address{' '}
          <span className={styles['email_address']}>{userEmail}.</span>
        </p>
        <p className={styles['top_text']}>
          Please open the email and click the verification link to confirm your account.
        </p>
      </div>
      <div className={styles['sub_layout']}>
        <p className={styles['sub_text']}>
          If you don't see the email, make sure to check your spam or junk folder. If you still can't find it, you can request a new verification email.
        </p>
        <Button
          type="button"
          outline="white"
          variant="primary"
          onClick={handleResendEmailClick}
          className={styles['outline']}
        >
          Resend Email
        </Button>
      </div>
    </div>
  );
};

export default VerifyEmail;
