import React from 'react';
import FreecourseHeader from './header';
import FreeCourseForm from './form';
import CourseAnalysis from './course-analysis';
import StartYourJourney from '../home/start-your-journey';

const FreeCourse = () => {
  return (
    <section>
      <FreecourseHeader />
      <FreeCourseForm url={"https://vimeo.com/748645354"} />
      <CourseAnalysis />
      <StartYourJourney />
    </section>
  )
}

export default FreeCourse;