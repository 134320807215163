import { MatchDownIcon, MatchUpIcon } from "../images";

export const COURSE_SCHEDULE = [
  {
    week: 1,
    weekDescription: "Getting Started",
    time: "4 hours"  ,
    text: "Launch your data analytics journey with an introduction to data by learning foundational concepts and new skills for approaching data problems. You'll start your learning journey by developing a problem-solving framework and by understanding how data should be collected and prepared for data analysis. Translate your learnings into job-ready skills by solving real-world problems with a variety of data sets.",
    pointItem: [
      {points: "Onboarding"},
      {points: "Introduction to business Analysis "},
      {points: "Competencies of a Business Analyst" },
      {points: "Stakeholder Analysis "},
      {points: "Introduction to Individual Project"},
      {points: "Assignment 1"}
    ] ,
    skills: []
  },
  {
    week: 2,
    weekDescription: "Getting acquainted",
    time: "4 hours"  ,
    text: "Launch your data analytics journey with an introduction to data by learning foundational concepts and new skills for approaching data problems. You'll start your learning journey by developing a problem-solving framework and by understanding how data should be collected and prepared for data analysis. Translate your learnings into job-ready skills by solving real-world problems with a variety of data sets.",
    pointItem: [
      {points: "Software Development Life Cycle (SDLC)"},
      {points: "How projects are run"},
      {points: "Agile Ceremonies"},
      {points: "Individual Project Continued"},
      {points: "Attend UX/UI Review Session (Optional)"},
      {points: "Assignment 2"}
    ] ,  
    skills: [
      {skill: "Waterfall"},
      {skill: "Agile"},
      {skill: "Scrum"},
      {skill: "Kanban"},
      {skill: "SAFe"},
    ]
  },
  {
    week: 3,
    weekDescription: "Getting into the flow",
    time: "4 hours"  ,
    text: "Launch your data analytics journey with an introduction to data by learning foundational concepts and new skills for approaching data problems. You'll start your learning journey by developing a problem-solving framework and by understanding how data should be collected and prepared for data analysis. Translate your learnings into job-ready skills by solving real-world problems with a variety of data sets.",
    pointItem: [
      {points: "Requirement Engineering"},
      {points: "Process Flow Maps"},
      {points: "Business Process Modelling "},
      {points: "Attend UX/UI Review Session (Optional)"},
      {points: "Create your first Wireframe"},
      {points: "Assignment 3"}
    ] ,
     skills: [
      {skill: "Elicitation"},
      {skill: "Analysis"},
      {skill: "Documentation "},
      {skill: "Handling changes to requirements  "},
      {skill: "Traceability  "},
      {skill: "Verification and Validation "},
      {skill: "Draw.io"},
      {skill: "Visio"},
      {skill: "UML"},
      {skill: "BPMN 2.0"},
      {skill: "Balsamiq"},
    ]
  },
  {
    week: 4,
    weekDescription: "Getting to learn the ropes",
    time: "4 hours"  ,
    text: "Launch your data analytics journey with an introduction to data by learning foundational concepts and new skills for approaching data problems. You'll start your learning journey by developing a problem-solving framework and by understanding how data should be collected and prepared for data analysis. Translate your learnings into job-ready skills by solving real-world problems with a variety of data sets.",
    pointItem: [
      {points: "Overflow from week 3."},
      {points: "Lets keep swimming without drowning"},
      {points: "Attend UX/UI Review Session (Optional)" },
      {points: "Individual Project Presentations"},
      {points: "ntroduction to Student Weekly Roadshow"},
    ] ,
     skills: []
  },
  {
    week: 5,
    weekDescription: "Getting to know your tools",
    time: "4 hours"  ,
    text: "Launch your data analytics journey with an introduction to data by learning foundational concepts and new skills for approaching data problems. You'll start your learning journey by developing a problem-solving framework and by understanding how data should be collected and prepared for data analysis. Translate your learnings into job-ready skills by solving real-world problems with a variety of data sets.",
    pointItem: [
      {points: "Practical Confluence Walkthrough"},
      {points: "Miro Walkthrough for Collaboration"},
      {points: "Practical Jira Walkthrough" },
      {points: "Attend UX/UI Review Session (Optional)"},
      {points: "Introduction to Group Project"},
      {points: "Student Assignment to Project Pods"}
    ] ,
     skills: [
      {skill: "Miro"},
      {skill: "Jira"},
    ]
  },
  {
    week: 6,
    weekDescription: "Getting the extras",
    time: "4 hours"  ,
    text: "Launch your data analytics journey with an introduction to data by learning foundational concepts and new skills for approaching data problems. You'll start your learning journey by developing a problem-solving framework and by understanding how data should be collected and prepared for data analysis. Translate your learnings into job-ready skills by solving real-world problems with a variety of data sets.",
    pointItem: [
      {points: "SQL Training"},
      {points: "Become a Product Owner Training"},
      {points: "Technology and Design for Business Analysts and Product Owners " },
      {points: "Software Testing"},
      {points: "Attend UX/UI Review Session (Optional)"},
      {points: "Update on group project"},
      {points: "Roadshow 1"},
    ],
     skills: [
      {skill: "Data and Product Management"}
    ] ,
  },
  {
    week: 7,
    weekDescription: "Getting prepared",
    time: "4 hours"  ,
    text: "Launch your data analytics journey with an introduction to data by learning foundational concepts and new skills for approaching data problems. You'll start your learning journey by developing a problem-solving framework and by understanding how data should be collected and prepared for data analysis. Translate your learnings into job-ready skills by solving real-world problems with a variety of data sets.",
    pointItem: [
      {points: "An exposé of the hidden Job Market "},
      {points: "The fallacy of the entry level job market "},
      {points: "Get your CV put together " },
      {points: "Job profile optimization"},
      {points: "Launch "},
    ] ,
     skills: []
  },
  {
    week: 8,
    weekDescription: "Getting to know how they made it",
    time: "4 hours"  ,
    text: "Launch your data analytics journey with an introduction to data by learning foundational concepts and new skills for approaching data problems. You'll start your learning journey by developing a problem-solving framework and by understanding how data should be collected and prepared for data analysis. Translate your learnings into job-ready skills by solving real-world problems with a variety of data sets.",
    pointItem: [
      {points: "Q and A live sessions with Past Alumni"},
      {points: "Life before and after the course"},
      {points: "Life on the job" },
    ] ,
     skills: []
  },
  {
    week: 9,
    weekDescription: "Getting Started",
    time: "4 hours"  ,
    text: "Launch your data analytics journey with an introduction to data by learning foundational concepts and new skills for approaching data problems. You'll start your learning journey by developing a problem-solving framework and by understanding how data should be collected and prepared for data analysis. Translate your learnings into job-ready skills by solving real-world problems with a variety of data sets.",
    pointItem: [
      {points: "Onboarding"},
      {points: "Introduction to business Analysis "},
      {points: "Competencies of a Business Analyst" },
      {points: "Stakeholder Analysis "},
      {points: "Introduction to Individual Project"},
      {points: "Assignment 1"}
    ] ,
     skills: [
      {skill: "Elicitation"},
      {skill: "Analysis"},
      {skill: "Documentation "},
      {skill: "Handling changes to requirements  "},
      {skill: "Traceability  "},
      {skill: "Verification and Validation "},
      {skill: "Draw.io"},
      {skill: "Visio"},
      {skill: "UML"},
      {skill: "BPMN 2.0"},
      {skill: "Balsamiq"},
    ]
  },
];

// Add icon property to each week in the COURSE_SCHEDULE array
COURSE_SCHEDULE.map((week, index) => ({
  ...week,
  icon: index % 2 === 0 ? MatchDownIcon : MatchUpIcon,
}));