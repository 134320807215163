import React from 'react';
import './about-mission.css';
import goldenMount from '../../../images/bg/goldenAcheiv.svg';
import goldenLamp from '../../../images/bg/goldenLamp.svg';
import goldenTarget from '../../../images/bg/golden-target.svg';

const AboutMission = () => {
   return (
      <section className="section_yellow_background three_section_margin mt-12">
         <div className="section_no_background">
            <div className="card_block_row">
               <div className="card_in_row">
                  <div className="card_in_row_icon">
                     <img src={goldenMount} alt="golden-mount"/>
                  </div>

                  <div className="card_in_row_title">Mission</div>

                  <div className="card_in_row_text">
                    Start or Accelerate your career progression in the tech space 
                    by delivering relevant knowledge with commensurate work experience.

                  </div>
               </div>

               <div className="card_in_row">
                  <div className="card_in_row_icon">
                     <img src={goldenLamp} alt="golden-lamp" loading='lazy'/>
                  </div>

                  <div className="card_in_row_title">Vision</div>

                  <div className="card_in_row_text">
                     Create a global community where career help in tech is accessible across all
                     verticals and tech domain
                  </div>
               </div>

               <div className="card_in_row">
                  <div className="card_in_row_icon">
                     <img src={goldenTarget} alt="golden-target"/>
                  </div>

                  <div className="card_in_row_title">Purpose</div>

                  <div className="card_in_row_text">
                     To raise exceptional tech talents and close workplace and tech talent gaps for BIPOC communities.
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
};

export default AboutMission;
