import React, { useState, useEffect } from "react";
import { arrowDownBlack, locationIcon, bannerPhoto } from "../../../images";
import styles from "./header.module.css";
import Button from "../../button";
import { useDispatch } from "react-redux";
import { setFormData } from "../../../redux/action";
import { filterRegionsByCourseTitle } from "../../../utils/constants";
import {
  convertDateToValidFormat,
  convertDateToDays,
  generateShortDuration,
} from "../../../utils/time";

const CourseHeader = ({
  course,
  allCourses,
  defaultRegion,
  regions,
  setIsEnrolOpen,
  setIsModalOpen,
  bannerTile,
  bannerMessage,
  scrollToPayments,
}) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(false);
  const [regionCourseData, setRegionCourseData] = useState(null);

  useEffect(() => {
    const cohorts = course?.regionPrice?.[defaultRegion]?.cohorts;
    setRegionCourseData(cohorts && cohorts.length > 0 ? cohorts[0] : null);
  }, [defaultRegion, course]);

  const availableRegions = filterRegionsByCourseTitle(allCourses, bannerTile);

  const handleRegionSelect = (region) => {
    dispatch(setFormData({ defaultRegion: region }));
    setSelected(false);
  };


  return (
    <section className={styles.section_no_background}>
      <div className={styles.top_banner}>
        <div className={styles.banner_info}>
          <div className={styles.banner_text}>
            <div className={styles.tag}>No previous work experience needed!</div>
            <div className={styles.banner_title}>{bannerTile}</div>
            <div className={styles.banner_message}>{bannerMessage}</div>
          </div>
          <div className={styles.banner_button}>
            <Button className={styles.enrol_now} onClick={scrollToPayments}>
              Enrol Now
            </Button>
            <Button
              outline="black"
              variant="secondary"
              className={styles.download_brochure}
              onClick={() => setIsModalOpen(true)}
            >
              Download Brochure
            </Button>
          </div>
        </div>
        <div className={styles.banner_photo}>
          <img src={bannerPhoto} alt="banner"/>
        </div>
      </div>

      <div className={styles.first_course_inf}>
        {regions && (
          <div className={styles.inf_point}>
            <div className={styles.point}>Region</div>
            <div className={styles.select_cont}>
              <div className={styles.select_dropdown}>
                <div
                  className={styles.select}
                  onClick={() => setSelected(!selected)}
                >
                 
                  <img src={locationIcon} alt="location-icon" />
                  <span>{defaultRegion}</span>
                  <img
                    className={`${styles.arrow} ${
                      selected ? styles.selected_arrow : ""
                    }`}
                    src={arrowDownBlack}
                    alt="dropdown arrow"
                  />
                </div>
                {selected && (
                  <div className={styles.select_options}>
                    {availableRegions.map((region) => (
                      <div
                        key={region}
                        className={`${styles.dropdown} ${
                          region === defaultRegion ? styles.selected : ""
                        }`}
                        onClick={() => handleRegionSelect(region)}
                      >
                        {region}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {regionCourseData ? (
          <>
            <CourseInfo 
              label="Course starts" 
              value={convertDateToValidFormat(regionCourseData.startDate)}
              description={regionCourseData.startDescription}
            />
            <CourseInfo 
              label="Duration" 
              value={convertDateToDays(regionCourseData.startDate, regionCourseData.endDate)}
              description={generateShortDuration(regionCourseData.startDate, regionCourseData.endDate)}
            />
            <CourseInfo 
              label="Training Session" 
              value={regionCourseData.sessionList?.map((day) => day.day.substr(0, 3)).join(", ") || ""}
              description={`${regionCourseData.timezone} time zone`}
            />
          </>
        ) : (
          <>
            <CourseInfo label="Course starts" value="-" />
            <CourseInfo label="Duration" value="-" />
            <CourseInfo label="Training Session" value="-" />
          </>
        )}
      </div>
    </section>
  );
};

// Separate reusable CourseInfo component for cleaner code
const CourseInfo = ({ label, value, description }) => (
  <div className={styles.inf_point}>
    <div className={styles.point}>{label}</div>
    <div className={styles.info}>{value}</div>
    {description && <div className={styles.point_description}>{description}</div>}
  </div>
);

export default CourseHeader;
