import React from 'react';
import styles from './testimonial.module.css';
import { faith} from '../../../images';
import { fedrick } from '../../../images';

const Testimonial = ({ course }) => {
  const trimmedCourse = course.trim();

   return (
      <section
         className={`${styles['section_black_background']} ${styles['three_section_margin']}`}
      >
         <div className={styles['student_remarks']}>
            <div className={styles['remarks_text_and_link']}>
               <div className={styles['remarks_text']}>
                  <span className={styles['test-span']}>“</span>
                 When I joined  TheSKillGarage, I had some knowledge of Business Analysis. However, I soon discovered that my knowledge was below par. I had to unlearn and relearn new concepts plus the project experience helped me to consolidate my business analysis knowledge.
               When I joined, I was told I would get a job in 3 months. I laughed because I had been on a BA job hunt for as long as I could remember. Anyways, cut the long story short, I got a job.
               Thanks to the relentless effort of TheSKillGarage"
               </div>

               <a href="/testimonies" className={styles['yellow_linc']}>
                  See more testimonials
               </a>
            </div>zz

            <div className={styles['avatar_block']}>
      

         <div className={styles['img_block']}>
         <img className={styles['test-img']} src={trimmedCourse === 'Product Management' ? fedrick : faith} alt="avatar" loading='lazy'/>
         </div>

         <div className={styles['label_block']}>
         <div className={styles['label_title']}>{trimmedCourse === 'Product Management' ? 'Fredrick Nnabuko' : 'Faith Momoh'}</div>

         <div className={styles['label_text']}>{trimmedCourse === 'Product Management' ? 'Product Management' : 'Business Analyst'}</div>
         </div>
            </div>
         </div>
      </section>
   );
};

export default Testimonial;
