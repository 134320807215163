import React, { useState, useEffect, useCallback, useRef, lazy, Suspense } from "react";
import { heroImage } from "../../../images";
import styles from "./header.module.css";

// Lazy load components
const Button = lazy(() => import("../../button"));
const LazyAuthModal = lazy(() => import("../../modals/auth"));

const Header = () => {
  const [showModal, setShowModal] = useState(false);
  const heroImageRef = useRef(null);

  const handleScroll = useCallback((e, itemId) => {
    e.preventDefault();
    const yOffset = window.innerWidth < 992 ? -20 : 0;
    const element = document.querySelector(itemId);
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, []);

  // Preload hero image for improved LCP
  useEffect(() => {
    const link = document.createElement("link");
    link.rel = "preload";
    link.href = heroImage;
    link.as = "image";
    document.head.appendChild(link);

    // Prefetch the button component
    const buttonPrefetch = document.createElement('link');
    buttonPrefetch.rel = 'prefetch';
    buttonPrefetch.href = '/button';
    document.head.appendChild(buttonPrefetch);
  }, []);

  const onClose = useCallback(() => setShowModal(!showModal), [showModal]);

  return (
    <>
      <section className={styles["section_no_background"]}>
        <div className={styles["top_banner"]}>
          <div className={styles["banner_info"]}>
            <div className={styles["banner_text"]}>
              <h1 className={styles["banner_title"]}>Begin with experience</h1>
              <div className={styles["banner_message"]}>
                <p>
                  We have made it simple for you:
                  <br />
                  Get hands-on experience, tackle on-the-job scenarios,
                  and receive continued support to land a tech role.
                </p>
              </div>
            </div>

            <div className={styles["banner_button"]}>
              <Suspense fallback={<div>Loading...</div>}>
                <Button onClick={(e) => handleScroll(e, "#started")}>Book a call</Button>
                <Button onClick={(e) => handleScroll(e, "#courses")} outline="black" variant="secondary">
                  Browse Courses
                </Button>
              </Suspense>
            </div>

            <div className={styles["banner_info_proc"]}>
              <div className={styles["block_inf"]}>
                <div className={styles["numbers_inf"]}>95%</div>
                <p>Course Completion Rate</p>
              </div>

              <div className={styles["block_inf"]}>
                <div className={styles["numbers_inf"]}>250%</div>
                <p>Average Salary Increase</p>
              </div>

              <div className={styles["block_inf"]}>
                <div className={styles["numbers_inf"]}>100%</div>
                <p>Graduate Satisfaction Rate</p>
              </div>
            </div>
          </div>

          <div className={styles["banner_photo"]}>
            <img 
              ref={heroImageRef} 
              src={heroImage} 
              alt="hero"
              width="540" 
              height="513"
              fetchPriority="high"
            />
          </div>
        </div>
      </section>

      {showModal && (
        <Suspense fallback={<div>Loading...</div>}>
          <LazyAuthModal 
            firstLabel="Email" 
            secondLabel="Password" 
            ctaText="Login" 
            onClose={onClose} 
          />
        </Suspense>
      )}
    </>
  );
};

export default React.memo(Header);