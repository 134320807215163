import { heroStoryImg, heroImage } from "../images"


export const ABOUT_STORY = [
  {
    year: "2020",
    yearTitle: "2020",
    firstDesc: "Our Founder, Michael Akpan, organized online training to help friends laid off due to COVID-19 land business analysis jobs. Their success led to TheSkillGarage's creation.",
    secondDesc: "",
    imageUrl: heroStoryImg,
  },
  {
    year: "2021",
    yearTitle: "2021",
    firstDesc: "TheSkillGarage was officially registered in Canada in February. We welcomed our first student in June and focused on growing into a global brand.",
    secondDesc: "",
    imageUrl: heroStoryImg,
  },
  {
    year: "2022",
    yearTitle: "2022",
    firstDesc: "We gained recognition in North America for student success. We expanded to the USA, UK, and Dubai, introducing hands-on projects and internships.",
    secondDesc: "",
    imageUrl: heroStoryImg,
  },
  {
    year: "2023",
    yearTitle: "2023",
    firstDesc: "We expanded our offerings by introducing additional courses and work experience programs.  This additions reflect our commitment to cutting-edge education.",
    secondDesc: "",
    imageUrl: heroStoryImg,
  },
  {
    year: "2024",
    yearTitle: "2024",
    firstDesc: "We launched three of our start-up student projects - TheSkillGarage new website, Afroshop and TSG Experience Hub.",
    secondDesc: "",
    imageUrl: heroStoryImg,
  }
]