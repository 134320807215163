import styled from 'styled-components';
import { checkMark } from '../../../images';

const CheckboxContainer = styled.div`
  display: flex;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  background: ${({ checked }) => (checked ? '#fff' : '#fff')};
  border: 2px solid #000;
  border-radius: 2px;
  transition: all 150ms;
  cursor: pointer;
  position: relative;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  }

  &::after {
    content: ${({ checked }) => (checked ? `url(${checkMark})` : '""')};
    position: absolute;
    top: -3px;
    left: 2px;
    font-size: 16px;
    font-weight: bold;
    color: black;
  }
`;

const Checkbox = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked} />
  </CheckboxContainer>
);

export default Checkbox;
