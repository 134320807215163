import { SEO, PageLayout, NotFoundHeader } from "../components";

function PageNotFound() {
    return (
        <>
          <SEO
            url="/404"
            openGraphType="website"
            schemaType="website"
            title="404 Page"
            description="Page doesn't exist"
          />
          <PageLayout>
            <div className="bg-[#FCFCF5] w-full">
              <NotFoundHeader />
            </div>
          </PageLayout>
        </>
      );
  }
  
  export default PageNotFound;