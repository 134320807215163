import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import Button from '../../button';
import styles from '../banner/banner.module.css';

const mountElement = document.getElementById("overlays");

const UsePortal = () => {
    const [showNotification, setShowNotification] = useState(false);

    const container = {
        position: 'absolute',
        bottom: '0px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '207px',
        height: '184px',
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(window.location.href);
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 1000);// Hide notification after 3 seconds
    };

    const handleShareTwitter = () => {
        const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`;
        window.open(url, '_blank');
    };

    const handleShareFacebook = () => {
        const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`;
        window.open(url, '_blank');
    };

    const handleShareLinkedIn = () => {
        const url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}`;
        window.open(url, '_blank');
    };

    return createPortal(
        <div style={container}>
            <ShareMenu>
                <Button
                    variant='bg-transparent'
                    outline='none'
                    className={styles['shr_button']}
                    icon={'shareLink'}
                    onClick={handleCopyLink}
                >
                    Copy link
                </Button>
                <Button
                    variant='bg-transparent'
                    outline='none'
                    className={styles['shr_button']}
                    icon={'shareTT'}
                    onClick={handleShareTwitter}
                >
                    Share on Twitter
                </Button>
                <Button
                    variant='bg-transparent'
                    outline='none'
                    className={styles['shr_button']}
                    icon={'shareFB'}
                    onClick={handleShareFacebook}
                >
                    Share on Facebook
                </Button>
                <Button
                    variant='bg-transparent'
                    outline='none'
                    className={styles['shr_button']}
                    icon={'shareLI'}
                    onClick={handleShareLinkedIn}
                >
                    Share on LinkedIn
                </Button>
                {showNotification && (
                    <Notification>Link copied successfully!</Notification>
                )}
            </ShareMenu>
        </div>,
        mountElement
    );
};

const ShareMenu = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
    background-color: #FFFFFF;
    border-radius: 4px;
`;

const Notification = styled.div`
    position: absolute;
    width: 203px;
    bottom: 170px; // Adjust based on placement preference
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 16px;
    background-color: #4caf50;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 10;
`;

export default UsePortal;
