import React from "react";
import { Link } from "react-router-dom";
import Button from "../button";
import { heroWaitList } from "../../images";
import styles from "./waitList.module.css";

const WaitList = () => {
  return (
    <section className={styles["section_no_background"]}>
      <div className={styles["top_banner"]}>
        <div className={styles["banner_info"]}>
          <div className={styles["banner_text"]}>
            <div className={styles["banner_title"]}>Coming soon...</div>

            <div className={styles["banner_message"]}>
              We are working to creating something fantastic.. We will be here
              soon, subscribe to be notified.
            </div>
          </div>

          <form className={styles["banner_form"]}>
            <div className={styles["email_input"]}>
              <label htmlFor="email">Email</label>

              <input
                type="email"
                id="email"
                placeholder="Example@example.com"
              />
            </div>
            <Button className={styles["notify"]}>Notify Me</Button>
          </form>
        </div>

        <div className={styles["banner_photo"]}>
          <img src={heroWaitList} alt="banner"/>
        </div>
      </div>
    </section>
  );
};

export default WaitList;
