import React, { useState } from 'react';
import styled from 'styled-components';
import EditPaymentProfile from './EditPaymentProfile';
import PaymentProfile from './PaymentProfile';

const PaymentProfileIndex = () => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Wrapper>
      {/* {
        isEditing ?
          <EditPaymentProfile setIsEditing={setIsEditing} /> :
          <PaymentProfile setIsEditing={setIsEditing} />
      } */}
    </Wrapper>
  )
}

const Wrapper = styled.div`
`;

export default PaymentProfileIndex