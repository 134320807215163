import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SuccessStoryModal } from "../../modals";
import ReactCountryFlag from "react-country-flag";
import Button from "../../button";
import { 
  blackLinkedInIcon, 
  blackTwitterIcon, 
  ukFlag,
  usFlag,
  canadaFlag,
  storiesAvatar,
  instructor3,
  fbBlack,
  instagramBlackIcon
} from "../../../images";
import ImageTag from "../../image-tag";

import styles from "./studentStoriesList.module.css";
import BackToTop from "../backToTop";
const SOCIAL_ICONS = {
  "LinkedIn": blackLinkedInIcon,
  "Twitter": blackTwitterIcon,
  "FaceBook": fbBlack,
  "Instagram": instagramBlackIcon,
}
// Mapping of country names to ISO 3166-1 alpha-2 country codes
const COUNTRY_CODES = {
  UK: "GB",
  USA: "US",
  Nigeria: "NG",
  Canada: "CA",
  Australia: "AU",
  Germany: "DE",
  France: "FR",
  Italy: "IT",
  Spain: "ES",
  Japan: "JP",
  China: "CN",
  India: "IN",
  Brazil: "BR",
  SouthAfrica: "ZA",
  Mexico: "MX",
  Argentina: "AR",
  Chile: "CL",
  Peru: "PE",
  Colombia: "CO",
  Russia: "RU",
  Turkey: "TR",
  SaudiArabia: "SA",
  UAE: "AE",
  Thailand: "TH",
  Singapore: "SG",
  Malaysia: "MY",
  Indonesia: "ID",
  Vietnam: "VN",
  Philippines: "PH",
  SouthKorea: "KR",
  Taiwan: "TW",
  HongKong: "HK",
  NewZealand: "NZ",
  Greece: "GR",
  Portugal: "PT",
  Poland: "PL",
  Netherlands: "NL",
  Sweden: "SE",
  Norway: "NO",
  Denmark: "DK",
  Finland: "FI",
  CzechRepublic: "CZ",
  Hungary: "HU",
  Austria: "AT",
  Switzerland: "CH",
};


const StudentStoriesList1 = ({ isOpen, open, data }) => {
  const [totalItems, setTotalItems] = useState(data.length);
  const [itemsToShow, setItemsToShow] = useState(6);
  const [selectedID, setSelectedID] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentSlide = useSelector((state) => state.currentSlide)


  // For the back-to-top button
  const [initBackToTop, setInitBackToTop] = useState(false);
  const [showGoTop, setShowGoTop] = useState(false);

  const handleClick = (id) => {
    setIsModalOpen(true);
    setSelectedID(id);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const evenIndexElements = [];
  const oddIndexElements = [];

  data.forEach((obj, index) => {
    if (index % 2 === 0) {
      evenIndexElements.push(obj);
    } else {
      oddIndexElements.push(obj);
    }
  });

  const handleLoadMore = () => {
    isOpen();
    const newItemsToShow = itemsToShow + 6;
    setItemsToShow(newItemsToShow);

    // Handle back-to-top logic
    setInitBackToTop(true);
    setShowGoTop(true);
  };

  return (
    <div className={styles["stories_list"]} id="list__student__stories">
      <div className={styles["stories"]}>
        <div className={styles["stories_column"]}>
          {evenIndexElements.slice(0, itemsToShow).map((data, index) => (
            <>
              <div className={styles["stories_item"]} key={index}>
                <div className={styles["first_row"]}>
                  <div className={styles["student_info"]}>
                    <div className={styles["avatar"]}>
                      <ImageTag imageUrl={data.imageUrl} alt={data.fullName} placeholderImg={storiesAvatar} loading='lazy'/>
                    </div>

                    <div className={styles["inf"]}>
                      <div className={styles["title_and_social"]}>
                        <div className={styles["title_inf"]}>
                          {data.fullName}
                        </div>
                        <div className={styles["social_icons"]}>
                          {data.social.map((item, index) => (
                            <a
                              className={styles["social_icon"]}
                              href={item.url}
                              key={index}
                            >
                             <img src={SOCIAL_ICONS[item.network]} alt={item.network} loading="lazy"/>
                            </a>
                          ))}
                        </div>
                      </div>

                      <div className={styles["other_inf"]}>
                        {data.occupation}
                        <div className={styles["gray_dot"]}></div>
                        {data.date}
                        <div className={styles["gray_dot"]}></div>
                        <span className={styles["flag"]}>
                       <ReactCountryFlag
                            countryCode={COUNTRY_CODES[data.country] || "US"}
                            svg
                            style={{
                              width: "1.5em",
                              height: "1.5em",
                            }}
                            alt="flag"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  {data.videoUrl?.length > 0 && (
                    <Button
                      className={styles["watch_btn"]}
                      icon="video"
                      onClick={() => handleClick(data.id)}
                      variant="transparent"
                      outline="black"
                    >
                      Watch video
                    </Button>
                  )}
                </div>

                <div className={styles["description_stories"]}>
                  <span>{data.testimonial}</span>
                </div>
              </div>
              {selectedID === data.id && isModalOpen ? (
                <SuccessStoryModal
  isOpen={isModalOpen}
  onClose={handleClose}
  fullName={data.fullName}
  date={data.date}
  url={data.videoUrl}
  items={data.course}
  flag={COUNTRY_CODES[data.country] || "US"}
  icon={data.social.map((item) => item.network)}
  occupation={data.occupation}
/>

              ) : null}
            </>
          ))}
        </div>
        <div className={styles["stories_column"]}>
          {oddIndexElements.slice(0, itemsToShow).map((data, index) => (
            <>
              <div className={styles["stories_item"]} key={index}>
                <div className={styles["first_row"]}>
                  <div className={styles["student_info"]}>
                    <div className={styles["avatar"]}>
                      <ImageTag imageUrl={data.imageUrl} alt={data.fullName} placeholderImg={storiesAvatar} loading='lazy'/>
                    </div>

                    <div className={styles["inf"]}>
                      <div className={styles["title_and_social"]}>
                        <div className={styles["title_inf"]}>
                          {data.fullName}
                        </div>
                        <div className={styles["social_icons"]}>
                          {data.social.map((item, index) => (
                            <a
                              className={styles["social_icon"]}
                              href={item.url}
                              key={index}
                            >
                             <img src={SOCIAL_ICONS[item.network]} alt={item.network} loading="lazy"/>
                            </a>
                          ))}
                        </div>
                      </div>

                      <div className={styles["other_inf"]}>
                        {data.occupation}
                        <div className={styles["gray_dot"]}></div>
                        {data.date}
                        <div className={styles["gray_dot"]}></div>
                        <span className={styles["flag"]}>
                          <ReactCountryFlag
                            countryCode={COUNTRY_CODES[data.country] || "US"}
                            svg
                            style={{
                              width: "1.5em",
                              height: "1.5em",
                            }}
                            alt="flag"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  {data.videoUrl?.length > 0 && (
                    <Button
                      className={styles["watch_btn"]}
                      icon="video"
                      onClick={() => handleClick(data.id)}
                      variant="transparent"
                      outline="black"
                    >
                      Watch video
                    </Button>
                  )}
                </div>

                <div className={styles["description_stories"]}>
                  <span>{data.testimonial}</span>
                </div>
              </div>
              {selectedID === data.id && isModalOpen ? (
                <SuccessStoryModal
  isOpen={isModalOpen}
  onClose={handleClose}
  fullName={data.fullName}
  date={data.date}
  items={data.course}
  url={data.videoUrl}
  flag={COUNTRY_CODES[data.country] || "US"}
  icon={data.social.map((item) => item.network)}
  occupation={data.occupation}
/>

              ) : null}
            </>
          ))}
        </div>
      </div>

      {itemsToShow * 2 < totalItems && (
        <div className={styles["see_more"]}>
          <Button
            id="show_more_button"
            className={styles["see_more_btn"]}
            onClick={handleLoadMore}
            variant="transparent"
            outline="black"
          >
            See more testimonials
          </Button>
        </div>
      )}

      {initBackToTop ? (
        <BackToTop showGoTop={showGoTop} setShowGoTop={setShowGoTop} />
      ) : null}
    </div>
  );
};

export default StudentStoriesList1;
