import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './cta.module.css';
import Button from '../button';

const CTA = ({ title, desc, subtitle, button1Label, button2Label }) => {
   const navigate = useNavigate();

 const redirectToFreeCourse = () => {
  navigate('/free-course');
};


   return (
      <section className={styles['section_no_background']}>
         <div className={styles['cta_wrapper']}>
            <div className={styles['title_for_cta']}>
               <h1>{title}</h1>
            </div>
            <div className={styles['cta_desc']}>
               <p>{desc}</p>
            </div>
            {subtitle && (
               <>
                  <h2 className={styles['cta_sub']}>
                     <p className={styles['cta_sub1']}>{subtitle}</p>
                  </h2>
               </>
            )}
            <div className={styles['cta_flex']}>
               <Button variant="secondary" icon="blackArrow" onClick={redirectToFreeCourse} className={styles['cta_btn']}>
                  {button1Label}
               </Button>
               {button2Label && (
                  <Button
                     variant="primary"
                     size={false}
                     className={styles['cta_btn']}
                     outline="white"
                  >
                     {button2Label}
                  </Button>
               )}
            </div>
         </div>
      </section>
   );
};

export default CTA;
