import React from "react";
import { Link } from "react-router-dom";
import Button from "../button";
import {NotFoundImage} from "../../images";
import styles from "./page.module.css";

const NotFoundHeader = () => {
  return (
    <section className={styles["section_no_background"]}>
      <div className={styles["top_banner"]}>
        <div className={styles["banner_info"]}>
          <div className={styles["banner_text"]}>
            <div className={styles["tag"]}>Error code: 404</div>

            <div className={styles["banner_title"]}>Oops!</div>

            <div className={styles["banner_message"]}>
              You did not break the internet, but we can’t find what you are
              looking for
            </div>
          </div>

          <div className={styles["banner_button"]}>
          <Link to="/">
            <Button className={styles["enrol_now"]}>Back to Home</Button>
            </Link>
          </div>
        </div>

        <div className={styles["banner_photo"]}>
          <img src={NotFoundImage} alt="banner"/>
        </div>
      </div>
    </section>
  );
};

export default NotFoundHeader;
