import { SEO, ResetLink } from "../components";

function ResetLinkPage() {
  return(
    <>
      <SEO 
        url="/expired-link"
        openGraphType="website"
        schemaType="website"
        title= "Expired Link Page"
        description="Expired Link Page"
      />
        <div className="bg-[#000] w-full h-full lg:h-auto">
          <ResetLink/>
        </div>
    </>
  )
}

export default ResetLinkPage;