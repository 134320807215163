import { MatchDownIcon, MatchUpIcon } from "../images";

export const FAST_TRACK_SCHEDULE = [
  {
    id: 1,
    session: "Session 1",
    sessionTime: "Friday, 11 October 2024",
    sessionTitle: "Course Schedule",
    duration: "October 11th, 2024 7 pm - 10pm (EST)",
    time: "Time: 11 AM - 2 PM EST",
    description: "Launch your data analytics journey with an introduction to data by learning foundational concepts and new skills for approaching data problems. You'll start your learning journey by developing a problem-solving framework and by understanding how data should be collected and prepared for data analysis. Translate your learnings into job-ready skills by solving real-world problems with a variety of data sets.",
    pointItems: [
      {points: "Introduction to Business Analysis"},
      {points: "The Business Analysis Job Functions"},
      {points: "Core Competencies of a Business Analyst"},
      {points: "Software Development Life Cycle (SDLC)"},
      {points: "Waterfall and Agile"},
    ],
  },
  {
    id: 2,
    session: "Session 2",
    sessionTime: "Saturday, 12 October 2024",
    sessionTitle: "Course Schedule",
    duration: "October 12th, 2022 4 pm - 7pm (EST)",
    time: "Time: 4 PM - 7 PM EST",
    description: "Launch your data analytics journey with an introduction to data by learning foundational concepts and new skills for approaching data problems. You'll start your learning journey by developing a problem-solving framework and by understanding how data should be collected and prepared for data analysis. Translate your learnings into job-ready skills by solving real-world problems with a variety of data sets.",
    pointItems: [
      {points: "Requirements Life Cycle Management "},
      {points: "Requirements Elicitation"},
      {points: "Epics, User Stories and Acceptance Criteria"},
      {points: "Question and Answer Session"},
      {points: "Next Steps Guide"},
    ],
  }
]; 
FAST_TRACK_SCHEDULE.map((week, index) => ({
  ...week,
  icon: index % 2 === 0 ? MatchDownIcon : MatchUpIcon,
}));

export const FAST_TRACK_SCHEDULES = [
  {
    id: 1,
    session: "Session 1",
    sessionTime: "Saturday, 14 December 2024",
    sessionTitle: "Business Analysis Essentials",
    duration: "December 14th, 2024 9 am - 12pm (EST)",
    time: "Time: 9 AM - 12 PM EST",
    description: "This session dives deep into essential concepts, proven methodologies, and strategic approaches that define effective business analysis. Whether you're a beginner looking to understand the fundamentals, an enthusiast looking to refresh key knowledge, or a professional aiming to refine your expertise, this session will provide the clarity and confidence you need to navigate the field. Get ready to refresh and enhance your skills and apply them to real-world scenarios with precision and impact!",
    pointItems: [
      {points: "Components of a Business Analysis Project "},
      {points: " Project Methodologies"},
      {points: "Stakeholder Management"},
      {points: "Elicitation and Documentation"},
      {points: " Business Process Modelling"},
      {points: "Scrum: JIra in action Practical Case Study"},
    ],
  },
  {
    id: 2,
    session: "Session 2", 
    sessionTime: "Sunday, 15 December 2024",
    sessionTitle: "Product Ownership + How to Win in the Job Market",
    duration: "December 15th, 2024 4pm - 7pm (EST)",
    time: "Time: 4 PM - 7 PM EST",
    description: "This session continues to explore the essentials of business analysis from the previous session but from the lens of the prevailing job market. We will discuss product ownership, how to win in the current job market, plus resume and interview essentials. ",
    pointItems: [
      {points: "Product ownership"},
      {points: "Outlook of the job market for Business Analysts "},
      {points: "Resume clinic - what to capture on your resume"},
      {points: "Using AI to aid your job applications "},
      {points: "Networking"},
      {points: "How to land an interview fast "},
      {points: "How to prepare for interviews"},
    ],
  },
];

FAST_TRACK_SCHEDULES.map((week, index) => ({
  ...week,
  icon: index % 2 === 0 ? MatchDownIcon : MatchUpIcon,
}));
