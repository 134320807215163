import React, { useState } from 'react';
import { PageLayout, StartYourJourney } from '../components';
import Banner from '../components/blog/banner';
import CardContainer from '../components/blog/articleCards';
import { BLOG_CARD_DATA } from '../data';

// Utility function to shuffle an array
const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

function BlogPage() {
  // Shuffle BLOG_CARD_DATA only for initial display
  const [initialCards] = useState(shuffleArray(BLOG_CARD_DATA).slice(0, 8));
  const [visibleCards, setVisibleCards] = useState(initialCards);
  const [allCards] = useState(BLOG_CARD_DATA); // Original order of all cards

  const handleShowMore = () => {
    // Filter out cards that are already visible
    const remainingCards = allCards.filter(card => !visibleCards.includes(card));

    // Get the next 3 cards from the remaining cards (in original order)
    const newCards = remainingCards.slice(0, 3);

    // Add the new cards to the visible cards
    setVisibleCards(prevCards => [...prevCards, ...newCards]);
  };

  return (
    <>
      <PageLayout>
        <div className="bg-[#FCFCF5] ">
          <Banner bannerTitle="Blog" />
          <CardContainer visibleCardCount={visibleCards.length} />
        </div>
        <StartYourJourney
          buttonText="Show More"
          onButtonClick={handleShowMore}
          buttonClassName="!w-[144px] h-[46px]"
        />
      </PageLayout>
    </>
  );
}

export default BlogPage;
