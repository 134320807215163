import { useState } from 'react';
import PropTypes from "prop-types";
import { placeholder } from '../../images';
import { renderValidUrl } from '../../utils/constants';

const ImageTag = ({ imageUrl, placeholderImg, alt, maxRetries }) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);

  const handleImageLoading = () => {
    setImageLoading(false);
  };

  const handleImageError = (e) => {
    if (retryCount < maxRetries) {
      setRetryCount(retryCount + 1);
      e.target.src = renderValidUrl(imageUrl);
    } else {
      e.target.src = placeholderImg;
    }
  };

  const validUrl = renderValidUrl(imageUrl);

  return (
    <img
      src={validUrl}
      alt={alt}
      onLoad={handleImageLoading}
      onError={handleImageError}
      loading='lazy'
    />
  );
};


ImageTag.defaultProps = {
  placeholderImg: placeholder,
  alt: '',
  maxRetries: 3
}

ImageTag.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  placeholderImg: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  maxRetries: PropTypes.number
};

export default ImageTag;
