export const FAQ_DATA = [
  {
     title: 'Can I pay in instalments?',
     content: `Yes. We currently have a three-part payment plan our candidates can take advantage of.`,
  },
  {
     title: 'How are the classes delivered?',
     content: `Currently, all our classes are live, instructor-led and remote. All registered students have access to class recordings and can play back any session that was missed.`,
     video: ''
  },
  {
     title: 'I am not able to attend live sessions. Is there an alternative?',
     content: `When you register, you have access to both self-paced course and live sessions. If you are not able to attend live sessions because of timing , that's fine. You can always keep up with the self-paced course and ask instructors and mentors questions on the platform. `,
  },
  {
     title: 'I do not have experience; will I get a job?',
     content: `Yes. Our training program is a perfect blend of theory and real-life projects. This practical project helps you gain experience, confidence, and skills for a rewarding business analysis job and career growth, regardless of prior experience.
`,
  },
  {
     title: 'Do I need to know how to code?',
     content: `No, business analysis does not require you to code.`,
  },
  {
     title: 'Do you get help with Jobs after the training',
     content: `
       We do not guarantee you a job; However, you are assigned a career coach that will help you with resume and interview preparation, as well as mentorship to get a job. We do not look for a job for you. That is actually your responsibility. We  promise to support you in the best way possible. And after you get a job, provide on-the-job support.
`,
  },
  {
   title: `Do I need to write IIBA CBAP Professional exam  after this course to get a job?
`,
   content: `The simple answer is No. This bootcamp is designed to help you gain the skillset and skills you need to get a job and hit the ground running day 1 as a business analyst. 90% of our students get jobs without this certification. However, we encourage students to pursue professional certifications such as CBAP, CSPO and CSM.`
  },
  {
   title: `Do I get a certificate after the program ?
`,
content: `Yes. Although our business analysis course is more practice- and work-focused, we also give you a certificate if you require one. To get a certificate, you must complete the training and be an active participant in a project.`
  },
  {
   title: `I am not in Canada , USA, Europe. Can I join the program?
`,
content: `Yes, we accommodate candidates globally. We are a global community.`
  }
];
