import React from 'react';
import styles from './auth.module.css';
import Button from '../button';
import { logoWhiteImg } from '../../images';
import { useDispatch, useSelector } from 'react-redux';
import { showModalFunc } from '../../redux/action';
import Login from './login';
import { AuthModal } from '../modals';
import { Link } from 'react-router-dom';

const PasswordSuccess = () => {
  const dispatch = useDispatch();
  const {showModal} = useSelector(state=> state)
  
  return (
    <>
      <section className={styles['authWrapper']}>
        <div className="flex flex-col items-center justify-center gap-4 w-[90%] md:w-[640px] h-[280px] bg-[#FFFFFF1A]">
           <Link to={'/'}>
            <img src={logoWhiteImg} alt="logo"  className="w-[80px] h-[35px]" loading="lazy" />
           </Link>

          <h2 className={styles['authHeader']}>Congratulations!</h2>
          <p className={styles['authSubTitle']}>
            Your password has been reset successfully.
          </p>

          <Button
            type="submit"
            variant="secondary"
            size
            className={styles['btnPasswordSuccess']}
            onClick={() => dispatch(showModalFunc())}
          >
            Login to your account
          </Button>
        </div>
      </section>
      {showModal && (
        <AuthModal>
          <Login />
        </AuthModal>
      )}
    </>
  );
};

export default PasswordSuccess;
