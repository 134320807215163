import styled from "styled-components";
export const InputBox = styled.div`
  display:flex;
  align-items:center;
  gap: 10px;
  font-size: 12px;
  font-weight: 400;
  background: inherit;
  width: 100%;
  border:2px solid #d8d8d8;
  border-radius: 4px;

  &.input_error{
    background: inherit;
    border: 1px solid #FF3B30;
  }
  &:focus-within  {
    border: 2px solid #f8d210;
    outline: none;
  }
  `;

export const Input = styled.input`
  width: 100%;
  padding: 16px;
  border: 2px solid #d8d8d8;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;

  ::placeholder {
    color: #d8d8d8;
  } 
`;

export const BtnPrimary = styled.button`
  width: 100%;
  background: #f8d210;
  border-radius: 4px;
  padding: 16px 32px;
  box-sizing: border-box;
  cursor: pointer;

  :not(:disabled):hover {
    transform: scale(1.1);
    transition: all 0.2s linear;
  }

  :disabled {
    background: #ecde9a;
  }
`;

export const Select = styled.select`
  width: 100%;
  height: 44px;
  padding: 10px;
  border: 2px solid #d8d8d8;
  border-radius: 4px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  background: inherit;

  option {
    color: #d8d8d8;
    background: black
  }
`;

export const InputContainer = styled.div`
  margin-bottom: 16px;

  input[type="checkbox"] {
    padding: 5px;
  }

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;

    color: #d8d8d8;
  }

  .input_error {
    border: 1px solid #f93737;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.07);
  }

  .errors {
    font-weight: 400;
    font-size: 14px;
    color: #e85050;
  }

  .requirements {
    padding: 16px 0 0;
    max-height: 200px;
    overflow: hidden;
    color: #f93737;
    font-style: italic;
    font-size: 14px;
  }

  #checkbox_wrapper {
    display: flex;

    #checkbox {
      width: 18px;
      height: 18px;
      margin-right: 8px;
      box-sizing: border-box;
      outline: none;
    }

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #d8d8d8;
    }
  }

  .react-tel-input {
    height: 44px;
  }

  .phone_input {
    background: inherit;
    width: 100%;
    height: 44px;
    padding-left: 81px;
    border: 2px solid #d8d8d8;
    border-radius: 4px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #ffffff;

    ::placeholder {
      color: #d8d8d8;
    }
  }

  .phone_button {
    background-color: inherit;
    border: 2px solid #d8d8d8;
    cursor: pointer;
  }

  .phone_dropdown {
    background-color: black;
    border: 1px solid white;
  }

  .country-list .country:hover {
    background-color: #333333;
  }

  .country-list .country.highlight {
    background-color: #36454f;
  }

  .country-list .search {
    background: black;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  .country-list .search-box {
    border: 2px solid #ffff;
    width: 90%;
    border-radius: 20px;
    height: 2rem;
  }
  
  .selected-flag {
    background-color: inherit !important;
  }

  .selected-flag .arrow {
    border-top: 4px solid #ffff;
  }
  
  .selected-flag:hover {
    background-color: black;
  }
  
  .flag-dropdown .open {
    background-color: black !important;
  }

  .flag-dropdown {
    width: 71px;
  }

  .selected-flag {
    width: 100%;
  }
  
  .selected-flag .flag {
    left: 30%;
  }

  .input_error .phone_input, .input_error .selected-flag {
    // background: pink;
  }
`;

export const FieldName = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #fcfcf5;
  // margin-bottom: 7px; commenting to fix the spacing issue between fieldname and field
`;

export const FieldNameWrapper = styled.div`
 width: 100%;
 display: flex;
 justify-content: space-between;
`;

export const FirstLastName = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  @media (max-width: 1100px) {
    display: block;
  }
`;

export const ModalWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.39);
`;

export const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  
  .top_btn {
    float: right;
  }
  
  .inner_div {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 4px;
    background: rgb(25, 25, 25);
    box-shadow: 1px 3px 20px 0px rgba(1, 1, 1, 0.07);
    padding: 40px;

    .image {
      padding-bottom: 16px;
    }

    .title {
      color: #FFF;
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;
      padding-bottom: 16px;
    }

    p {
      color: #d8d8d8;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }

    .description {
      color: #FCFCF5;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      padding-bottom: 16px;

      .email {
        color: #FCFCF5;
        font-size: 16px;
        font-weight: 700;
      }
    }
    
    .btn {
      width: 100%;
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  .footer {
    margin-top: 10rem;
    color: #d8d8d8;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }

  @media (max-width: 1000px) {
    padding-left: 5rem;
    padding-right: 5rem;
    width: 100%;
  }
  
  @media (max-width: 800px) {
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
  }
  
  @media (max-width: 500px) {
    padding-left: 0;
    padding-right: 0;

    .inner_div {
      padding-left: 10px;
      padding-right: 10px;
    }

    .footer {
      margin-top: 5rem;
    }
  }
`;