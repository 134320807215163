import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom"

import styles from "./course-analysis.module.css";
import Button from "../../button";

import DownloadCta from "../../modals/download-cta";

const CourseAnalysis = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClick = () => {
        setIsModalOpen(true)
    }

    const handleClose = () => {
        setIsModalOpen(false)
    }

    return (
        <section className={styles["section_no_background"]}>
            <div className={styles["start_today_block"]}>
                <div className={styles["tag_black"]}>First three classes are FREE</div>

                <div className={styles["title_black"]}>
                    Start Business Analysis Course Today
                </div>

                <div className={styles["parent_bonus_block"]}>
                    <div className={styles["bonus_black"]}>
                        <span>+ BONUS </span>
                        Product Ownership Module
                    </div>

                    <div className={styles["description_black"]}>
                        You should be able to apply for both a business analyst and a
                        product owner role after the training
                    </div>
                </div>

                <div className={styles["button_row_black"]}>
                    <Link to="/courses/business-analysis">
                        <Button variant="secondary" className={styles["enrol_now_yellow"]}>
                            Register for next cohort
                        </Button>
                    </Link>
                    <Link to="/courses/business-analysis">
                    <Button outline="white" className={styles["download_brochure_black"]} onClick={handleClick}>
                        Download Brochure
                    </Button>
                    
                    </Link>
                </div>
                {/* {
                    isModalOpen &&
                    <DownloadCta onClose={handleClose} />

                } */}
            </div>
        </section>
    );
};

export default CourseAnalysis;
