import { SEO, ResetPassword } from "../components";

function ResetPasswordPage() {
  return(
    <>
      <SEO 
        url="/reset-password"
        openGraphType="website"
        schemaType="website"
        title= "Reset Password Page"
        description="Reset Password Page"
      />
        <div className="bg-[#000] w-full h-full lg:h-auto">
          <ResetPassword/>
        </div>
    </>
  )
}

export default ResetPasswordPage;