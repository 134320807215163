import { SEO, PageLayout, Header, Marque, WhyChooseUs, FeaturedIn, CTA, StudentSuccess, StudentWorks, StartYourJourney } from "../components";
import { FEATURED_IN } from "../data";
import CookiePopUp from "../components/modals/Cookies/cookiePopUp";
import { useEffect } from "react";
import { initFacebookPixel } from "../utils/facebookPixel";
import { getAnnouncement, getCourseInstructors, getRegions, getCourseSchedules, getTestimonials, getSuccessStory, getCourses, getCourseCarouselData } from "../redux/action";
import { useDispatch, useSelector } from "react-redux";

function App() {
  const dispatch = useDispatch();
  const { announcement } = useSelector(state => state)
  
  useEffect(() => {
    dispatch(getAnnouncement())
    dispatch(getRegions())
    dispatch(getTestimonials())
    dispatch(getCourses())
    dispatch(getCourseSchedules())
    dispatch(getSuccessStory())
    dispatch(getCourses())
    dispatch(getCourseCarouselData())
    dispatch(getCourseInstructors())
    initFacebookPixel()
  }, [])

  return (
    <>
      <SEO
        url="/"
        openGraphType="website"
        schemaType="website"
        title="TheSkillGarage"
        description="We help you transition into tech seamlessly."
      />
      <PageLayout>
        <div className="bg-[#FCFCF5] w-full">
          <CookiePopUp />
          <Header />
          <Marque data={announcement} />
          <WhyChooseUs />
          <FeaturedIn data={FEATURED_IN} />
          <CTA
            title="Free Business Analysis Crash Course "
            desc="Step into the world of Business Analysis with
             confidence through our free crash course tailored for beginners.
              Whether you are taking your first step or revisiting the essentials, 
              register now to engage in live sessions or access recorded ones for free.
"
            button1Label="Learn for Free"
          />
          <StudentSuccess />
          <StudentWorks />
          <StartYourJourney />
        </div>
      </PageLayout>
    </>
  );
}

export default App;
