import { scrumMasterIcon, businessAnalysisIcon, projectManagementIcon } from "../images";

export const MENU_HEADER = [
   {
      name: 'For Students',
      subMenu: [
         {
            id: 'business_analysis',
            name: 'Business Analysis Practical Bootcamp',
            free: false,
            link: '/courses/business_analysis_bootcamp',
         },
         {
            id: 'Business Analysis Training',
            name: 'Business Analysis Training',
            free: false,
            self: true,
            link: '',
         },
         {
            id: 'Business Analysis Fast-Track Course',
            name: 'Business Analysis Fast-Track Course',
            free: true,
            link: '',
         },
         {
            id: 'Product Manager Certificate Course',
            name: 'Product Manager Certificate Course',
            free: false,
            link: '',
         },
         {
            id: 'Scrum and Product Owner Training',
            name: 'Scrum and Product Owner Training',
            free: false,
            link: '',
         },
         {
            id: 'Scrum + Practical Project',
            name: 'Scrum + Practical Project ',
            accelerator: true,
            link: '',
         }
      ],
   },
   {
      name: 'For Companies',
      subMenu: [
         {
            id: 'hire_tsg_graduates',
            name: 'Hire TSG Graduates',
            free: false,
            link: '',
         },
         {
            id: 'post_a_job',
            name: 'Post a Job',
            free: false,
            link: '',
         },
         {
            id: 'internships',
            name: 'Internships',
            free: false,
            link: '',
         },
         {
            id: 'staff_training',
            name: 'Staff training',
            free: false,
            link: '',
         },
         {
            id: 'sponsor_a_student',
            name: 'Sponsor a student',
            free: false,
            link: '',
         },
      ],
   },
   {
      name: 'Resources',
      subMenu: [
         {
            id: 'blog',
            name: 'Blog',
            free: false,
            link: '',
         },
         {
            id: 'faqs',
            name: 'FAQs',
            free: false,
            link: '/resources/faq',
         },
         // {
         //    id: "qa",
         //    name: "Live Q and A",
         //    free: false,
         //    link: "/resources/qa",
         // },

         {
            id: 'free_resource',
            name: 'Free Business Analysis Course',
            free: false,
            link: '/free-course',
         },
         // {
         //    id: "rf",
         //    name: "Refer A Friend",
         //    free: false,
         //    link: "/resources/refer",
         // },
      ],
   },
   {
      name: 'About Us',
      subMenu: null,
      link: '/about',
   },
   {
      name: 'Sign in',
      subMenu: null,
      link: '/login',
   },
];


export const NEW_MENU_DETAILS = [
   {
      title: 'Business Analysis',
      description: "Some small bootcamp description for better undestanding Some small bootcamp description for better",
      icon: scrumMasterIcon,
      links: [
         {
            label: 'Bootcamp',
            url: '/courses/business-administration-1'
         },
         {
            label: 'Fast-track course',
            url: '/fast-track'
         }
      ]
   },
   {
      title: 'Project Management',
      description: "Some small bootcamp description for better undestanding Some small bootcamp description for better",
      icon: projectManagementIcon,
      links: [
         {
            label: 'Bootcamp',
            url: '/courses/product-owner-course'
         },
         {
            label: 'Fast-track course',
            url: '/fast-track'
         },
         {
            label: 'free course',
            url: '/free-course'
         }
      ]
   },
   {
      title: 'Scrum Master',
      description: "Some small bootcamp description for better undestanding Some small bootcamp description for better",
      icon: businessAnalysisIcon,
      links: [
         {
            label: 'Bootcamp',
            url: '/courses/scrum-master'
         },
         {
            label: 'Fast-track course',
            url: '/fast-track'
         },
         {
            label: 'free course',
            url: '/free-course'
         },
         {
            label: 'Bootcamp 2',
            url: '/courses/business-administration-2'
         },
         {
            label: 'Fast-track course 2',
            url: '/fast-track'
         }
      ]
   },
   {
      title: 'Data Analysis',
      description: "Some small bootcamp description for better undestanding Some small bootcamp description for better",
      icon: scrumMasterIcon,
      links: [
         {
            label: 'Bootcamp',
            url: '/courses/data-analysis-po'
         },
         {
            label: 'Fast-track course',
            url: '/fast-track'
         },
         {
            label: 'free course',
            url: '/free-course'
         },
         {
            label: 'Bootcamp 2',
            url: '/courses/business-administration-1'
         },
         {
            label: 'Fast-track course 2',
            url: '/fast-track'
         }
      ]
   },
   {
      title: 'Project Management',
      description: "Some small bootcamp description for better undestanding Some small bootcamp description for better",
      icon: projectManagementIcon,
      links: [
         {
            label: 'Bootcamp',
            url: '/courses/product-owner-course-1'
         },
         {
            label: 'Fast-track course',
            url: '/fast-track'
         },
      ]
   },
   {
      title: 'Scrum Master',
      description: "Some small bootcamp description for better undestanding Some small bootcamp description for better",
      icon: businessAnalysisIcon,
      links: [
         {
            label: 'Bootcamp',
            url: '/courses/scrum-master'
         },
      ]
   },
   {
      title: 'Business Analysis',
      description: "Some small bootcamp description for better undestanding Some small bootcamp description for better",
      icon: scrumMasterIcon,
      links: [
         {
            label: 'Bootcamp',
            url: '/courses/busines-analysis-bootcamp3'
         },
         {
            label: 'Fast-track course',
            url: '/fast-track'
         }
      ]
   },
   {
      title: 'Project Management',
      description: "Some small bootcamp description for better undestanding Some small bootcamp description for better",
      icon: projectManagementIcon,
      links: [
         {
            label: 'Bootcamp',
            url: '/courses/product-management-certificate-course'
         },
         {
            label: 'Fast-track course',
            url: '/fast-track'
         },
      ]
   },
   {
      title: 'Scrum Master',
      description: "Some small bootcamp description for better undestanding Some small bootcamp description for better",
      icon: businessAnalysisIcon,
      links: [
         {
            label: 'Bootcamp',
            url: '/courses/scrum-master'
         },
      ]
   }
].slice(0, 9)