import React, { useState, useEffect, useLayoutEffect } from "react";
import Footer from "../footer";
import Navbar from "../navbar";
import GoogleFloater from "../googleFloater";
import TelegramFooter from "../telegramFooter";
import styles from "./layout.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getBanner, getGoogleRatings, getPromotionData } from "../../redux/action";
import { useLocation } from "react-router";
import { showPromoOnThisPage } from "../../utils/constants";
import Promotion from "../modals/Promotion/Promotion";
import { AuthModal } from "../modals";
import Login from "../auth/login";
import Cookies from 'js-cookie';

const PageLayout = ({ children, hasFooter = true }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = 'manual'
  }, []);

  const dispatch = useDispatch();
  const location = useLocation();
  const pagePath = location.pathname;
  const [isPromoPopUp, setPromoPopUp] = useState(false);

  useEffect(() => {
    dispatch(getPromotionData())
    dispatch(getGoogleRatings())
  }, [dispatch])

  const { promotionData, googleRatings, showModal } = useSelector(state => state)
  const currentTime = Date.now();
  const delayTimeInDays = 7
  const delayTime = delayTimeInDays * 24 * 60 * 60 * 1000; // delay time in milliseconds
  const lastShownPromoTimestamp = Cookies.get('lastShownPromoTimestamp');

  useEffect(() => {
    // Check if lastShownTimestamp exists and if it does, check if delayTime has not passed since the last display
    if (lastShownPromoTimestamp && lastShownPromoTimestamp + delayTime > currentTime) return

    if (promotionData?.showPopup && showPromoOnThisPage(pagePath, promotionData)) {
      // Show Promo after given time in seconds
      const timeId = setTimeout(() => setPromoPopUp(true), promotionData.showAfterTimeInSeconds * 1000);

      // Update lastShownTimestamp to the current time and store it in a cookie
      Cookies.set('lastShownPromoTimestamp', currentTime, { expires: delayTimeInDays });

      return () => clearTimeout(timeId);
    }
  }, [promotionData]);

  function onClose() {
    setPromoPopUp(false);
  }

  useEffect(() => {
    dispatch(getBanner())
  }, []);

  // showBanner indicates whether the banner shows on the page or not
  const [showBanner, setShowBanner] = useState(false)

  const { bannerData } = useSelector(state => state)

  useLayoutEffect(() => {
    if (bannerData?.showBanner && showPromoOnThisPage(pagePath, bannerData)) {
      setShowBanner(true)
    }
  }, [bannerData]);

  const [navbarHeight, setHeight] = useState(0)
  const sectionStyles = {
    position: 'relative',
    top: `${navbarHeight - 78}px`,
  };

  return (
    <>
      <Navbar showBanner={showBanner} setShowBanner={setShowBanner} bannerData={bannerData} setHeight={setHeight} />
      {
        isPromoPopUp &&
        <Promotion onClose={onClose} promoData={promotionData} />
      }
      <section style={sectionStyles}>
        <div className={styles["page_body"]}>
          {googleRatings?.rating && <GoogleFloater ratings={googleRatings.rating.toFixed(1)} />}
         {
          showModal && (
            <AuthModal>
              <Login/>
            </AuthModal>
          )
         }
          {children}
        </div>
        {
          hasFooter &&
          <>
            <TelegramFooter />
            <Footer />
          </>
        }
      </section>
    </>
  );
};

export default PageLayout;
