import { SEO, Login } from "../components";

function LoginPage() {
  return(
    <>
      <SEO 
        url="/login"
        openGraphType="website"
        schemaType="website"
        title= "Login Page"
        description="Login Page"
      />
        <div className="bg-[#000] w-full h-full lg:h-auto">
          <Login/>
        </div>
    </>
  )
}

export default LoginPage;