import { SEO, PageLayout, FreeCourse } from "../components";

function FreeCoursePage() {
  return (
    <>
      <SEO
        url="/free-course"
        openGraphType="website"
        schemaType="website"
        title="Free Course Page"
        description="Free Business Analysis crash course"
      />
      <PageLayout>
        <div className="bg-[#FCFCF5] w-full">
          <FreeCourse />
        </div>
      </PageLayout>
    </>
  );
}

export default FreeCoursePage;