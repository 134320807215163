import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import styles from "../banner.module.css";
import TimerPromoBanner from "../timer-promo-banner";

const CourseBanner = (
  {
    showBanner, setShowBanner,
    // all banners
    message, btnUrl, code,
    // timer banners                   
    discount, title, btnLabel, type, dateAndTimeToExpire, expandedOnMobile
  }
) => {
  // Banner doesn't render if props are not correctly defined
  useEffect(() => {
    const intervalId = setInterval(() => {
      if ((!dateAndTimeToExpire || new Date(dateAndTimeToExpire).getTime() <= Date.now())) {
        setShowBanner(false);
        clearInterval(intervalId); // Stop the interval when the condition is met
      }
      // component won't show if timer banner has no expiry time or incorrect expiry time
    }, 1000); // Run the function every second

    return () => {
      clearInterval(intervalId); // Cleanup the interval when the component is unmounted
    };
  }, [dateAndTimeToExpire, setShowBanner]);

  // to handle link
  const navigate = useNavigate()

  return (
    <>
      {showBanner &&
          <div
      className={[
        styles["header"],
        styles["banner_color"],
        styles["timer_header"],
        expandedOnMobile && styles["expanded_header"],
        'px-[5px] border border-black'
      ].join(' ')}
      onClick={() => btnUrl ? window.open(btnUrl, '_blank', 'noopener noreferrer') : null}
    >

          < TimerPromoBanner
            discount={`-${discount}%`}
            heading={title}
            btn_message={btnLabel}
            message={message}
            btn_type={type}
            couponCode={code}
            rel_link={btnUrl}
            expiry_time={dateAndTimeToExpire}
            expanded={expandedOnMobile}
            setShowBanner={setShowBanner}
            courseBanner
          />
        </div >
      }
    </>
  );
};


CourseBanner.defaultProps = {
  dismissable: true,
  bannerColor: true,
  btnUrl: null,
  type: 'N/A',
  message: ''
}

CourseBanner.propTypes = {
  dismissable: PropTypes.bool,
  bannerColor: PropTypes.bool,
  btnUrl: PropTypes.string,
  type: PropTypes.string.isRequired,
  code: PropTypes.string,
  icon: PropTypes.string,
  showBanner: PropTypes.bool,
  discount: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  setShowBanner: PropTypes.func,
  dateAndTimeToExpire: PropTypes.string,
  expandedOnMobile: PropTypes.bool
};

export default CourseBanner;
