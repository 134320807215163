import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PopupModal } from 'react-calendly';
import { CALENDLY_URL } from '../../../utils/constants';
import styles from './about-team.module.css';
import Button from '../../button';

const GetInvolved = () => {

   const [isOpen, setIsOpen] = useState(false);
   const rootElement = document.getElementById('root');

   const navigate = useNavigate();

   const handleOpenModal = () => {
      setIsOpen(true);
   };
   const handleCloseModal = () => {
      setIsOpen(false);
   };

   return (
      <section
         className={`${styles['section_no_background']} ${styles['three_section_margin']} ${styles['item_in_center']} ${styles['last_section']}`}
      >
         <div className={styles['get_involved']}>
            <div className={`${styles['title_two_type']} ${styles['text_in_center']}`}>
               Get Involved
            </div>

            <div className='flex w-full items-center justify-center space-x-0 lg:space-x-4 space-y-6 lg:space-y-0 flex-col lg:flex-row'>
               <Button size onClick={handleOpenModal}>
                 Book a Call
               </Button>
               <PopupModal
                  url={CALENDLY_URL}
                  onModalClose={handleCloseModal}
                  open={isOpen}
                  rootElement={rootElement}
               />
               <Button
                  variant='transparent'
                  outline='black'
                  size
                  onClick={() => navigate('/contact-partner')}
               >
                  Partner with Us
               </Button>

               {/* <Button
                  variant='transparent'
                  outline='black'
                  size
               >
                  Work at TheSkillGarage
               </Button> */}
            </div>
         </div>
      </section>
   );
};

export default GetInvolved;
