import {
    whiteFacebookIcon,
    whiteTelegramIcon,
    whiteTwitterIcon,
    whiteLinkedInIcon,
    whiteInstagramIcon,
    whiteWhatsappIcon,
  } from '../../../images';

export const imgArray = [
    {
      title: "WhatsApp",
      icon: whiteWhatsappIcon,
      url: "https://whatsapp.com/channel/0029VaEIHKPFMqrfEtWGgm23"
    },
    {
      title: "Twitter",
      icon: whiteTwitterIcon,
      url: "/https://x.com/theskillgarage"
    },
    {
        title: "LinkedIn",
        icon: whiteLinkedInIcon,
        url: "https://www.linkedin.com/company/theskillgarage"
      },
      {
        title: "Instagram",
        icon: whiteInstagramIcon,
        url: "https://www.instagram.com/theskillgarage"
      },
      {
        title: "Facebook",
        icon: whiteFacebookIcon,
        url: "https://www.facebook.com/theskillgarage/"
      },
      // {
      //   title: "Telegram",
      //   icon: whiteTelegramIcon,
      //   url: "https://t.me/+PT-H1KZ1G1QzODc0"
      // },
    
    ]
    
  