import * as moment from 'moment-timezone';

export const STR_FORMAT = 'DD MMM YYYY';
export const ISO_DATE = 'YYYY-MM-DD';
export const ISO_DATE_TIME = 'YYYY-MM-DDTHH:mm:ssZ';
export const TIMESTAMP_DATE_TIME = 'YYYY-MM-DD HH:mm:ss';
export const TIME_12_HOURS = 'hh:mm A';
export const ISO_TZ = 'Z';
export const WEEK_DAY = 'dddd';
export const MONTH_NAME = 'MMMM';
export const MINUTES_PER_SLOT = 15;
export const UTC_OFFSET = '+00:00';
export const MONTH_DEY_YEAR = 'MMM DD, YYYY';
export const WEEKS_DAYS = [
   'Monday',
   'Tuesday',
   'Wednesday',
   'Thursday',
   'Friday',
   'Saturday',
   'Sunday',
];
export const TZ = 'America/New_York';

export const currentTimeISO = () => {
   return moment().utcOffset(0).format(ISO_DATE_TIME);
};

export const timeToISO = (time) => {
   return moment(time).format(TIMESTAMP_DATE_TIME);
};

export const timeToISOInEST = (time) => {
   return moment(time).tz(TZ).format(ISO_DATE_TIME);
};

export const timeToStr = (time) => {
   return moment(time).format(STR_FORMAT);
};

export const startEndPeriod = (startTime, endTime, full = false) => {
   const startYear = moment(startTime).format('YYYY');
   const endYear = moment(endTime).format('YYYY');
   const mounthFormat = full ? 'MMMM' : 'MMM';

   if (startYear === endYear) {
      return `${moment(startTime).tz(TZ).format('DD')}th ${moment(startTime)
         .tz(TZ)
         .format(mounthFormat)} - ${moment(endTime).tz(TZ).format('DD')}th ${moment(endTime)
         .tz(TZ)
         .format(mounthFormat)} ${endYear}`;
   }
   return `${moment(startTime).tz(TZ).format('DD')}th ${moment(startTime)
      .tz(TZ)
      .format(mounthFormat)} ${startYear} - ${moment(endTime).tz(TZ).format('DD')}th ${moment(
      endTime,
   )
      .tz(TZ)
      .format(mounthFormat)} ${endYear}`;
};

export const dateMDYtoEST = (date) => {
   return moment(date).tz(TZ).format(MONTH_DEY_YEAR);
};

export const dateToEST = (date) => {
   return moment(moment(date).utcOffset(0)).tz(TZ).toDate();
};

export const dateFromInWeeks = (startDate, endDate) => {
   const end = moment(endDate);
   const start = moment(startDate);
   const in_weeks = moment.duration(end.diff(start)).asWeeks();
   return Math.ceil(in_weeks);
};

export const getTimeHM = (time) => {
   return moment(time).tz(TZ).format(TIME_12_HOURS);
};

export const convertDateToValidFormat = (startDate) => {
  const monthsShort = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const formatDay = (day) => {
    if (day >= 11 && day <= 13) {
      return `${day}th`;
    }
    switch (day % 10) {
      case 1: return `${day}st`;
      case 2: return `${day}nd`;
      case 3: return `${day}rd`;
      default: return `${day}th`;
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString + "T00:00:00Z"); // Force UTC interpretation
    const day = formatDay(date.getUTCDate()); // Use UTC getters
    const month = monthsShort[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    return `${day} ${month} ${year}`;
  };

  return formatDate(startDate);
};

export const formatTimeRange = (from, to) => {
  const formattedFrom = moment(from, 'HH:mm:ss').format('h A');
  const formattedTo = moment(to, 'HH:mm:ss').format('h A');
  return { formattedFrom, formattedTo };
};

export const generateLongDuration = (startDate, endDate) => {
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const formatDay = (day) => {
    if (day >= 11 && day <= 13) {
      return `${day}th`;
    }
    switch (day % 10) {
      case 1: return `${day}st`;
      case 2: return `${day}nd`;
      case 3: return `${day}rd`;
      default: return `${day}th`;
    }
  };

  const formatMonth = (month) => months[month];

  const formatDate = (dateString) => {
    // Force UTC interpretation
    const date = new Date(dateString + "T00:00:00Z");
    const day = formatDay(date.getUTCDate());
    const month = formatMonth(date.getUTCMonth()); // Month is zero-indexed
    const year = date.getUTCFullYear();
    return `${day} ${month} ${year}`;
  };

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  return `${formattedStartDate} - ${formattedEndDate}`;
};


export const calculateCourseDuration = (startDate, endDate) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const start_course_date = Date.UTC(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate()
  );
  const end_course_date = Date.UTC(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate()
  );
  return Math.floor((end_course_date - start_course_date) / _MS_PER_DAY);
};

export const convertDateToDays = (startDate, endDate) => {
  const start_Date = moment(startDate);
  const end_Date = moment(endDate);

  if (start_Date.isValid() && end_Date.isValid()) {
    const weeksDifference = end_Date.diff(start_Date, 'weeks');
    return `${weeksDifference} week${weeksDifference !== 1 ? 's' : ''}`;
  } else {
    return "Invalid Date Range";
  }
};


export const generateShortDuration = (startDate, endDate) => {
  const monthsShort = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const formatDay = (day) => {
    if (day >= 11 && day <= 13) {
      return `${day}th`;
    }
    switch (day % 10) {
      case 1: return `${day}st`;
      case 2: return `${day}nd`;
      case 3: return `${day}rd`;
      default: return `${day}th`;
    }
  };

  const formatMonth = (month) => monthsShort[month - 1];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      throw new Error(`Invalid date: ${dateString}`);
    }
    const day = formatDay(date.getDate());
    const month = formatMonth(date.getMonth() + 1);
    const year = date.getFullYear();
    return { day, month, year };
  };

  try {
    const start = formatDate(startDate);
    const end = formatDate(endDate);

    // Format output
    const formattedStartDate = `${start.day} ${start.month}`;
    const formattedEndDate = `${end.day} ${end.month}`;

    return `${formattedStartDate} - ${formattedEndDate} ${end.year}`;
  } catch (error) {
    console.error(error.message);
    return "Invalid dates";
  }
};



// Helper function to format a date string
function formatDateString(date, isShort) {
  const day = moment(date).tz(TZ).format('D'); // Use 'D' to get the day without suffix
  const monthFormat = isShort ? 'MMM' : 'MMMM';
  const month = moment(date).tz(TZ).format(monthFormat);
  const year = moment(date).tz(TZ).format('YYYY');
  return `${day} ${month} ${year}`;
}


// Helper function to get the day suffix
function getDaySuffix(day) {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}