import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  getCourseInstructors, getCourses, setFormData } from "../redux/action";
import { useParams } from "react-router-dom";
import {
  SEO,
  PageLayout,
  AboutHeader,
  AboutStory,
  AboutMission,
  AboutTeam,
  Testimonial,
  Instructors,
  AboutAchievement,
} from "../components";

function About() {
  const dispatch = useDispatch();

  const { courses, formData } = useSelector((state) => state);

   const getCourseTitlesForRegion = (coursesData, defaultRegion) => {
  const regionData = coursesData[defaultRegion];

  if (!regionData || !regionData.courses) {
    return [];
  }

  return regionData.courses.map(course => course.title);
};


  useEffect(() => {
    dispatch(getCourseInstructors());
    dispatch(getCourses());
    dispatch(setFormData());
  }, [dispatch])

    const courseTitles = getCourseTitlesForRegion(courses, formData.defaultRegion);

  return (
    <>
      <SEO
        url="/about"
        openGraphType="website"
        schemaType="website"
        title="About Us"
        description="We help you transition into tech seamlessly."
      />
      <PageLayout>
        <div className="bg-[#FCFCF5] w-full">
          <AboutHeader />
          <AboutStory/>
          <AboutMission />
          <AboutAchievement />
          <Testimonial course={courseTitles}/>
          <Instructors/>
          <AboutTeam />
        </div>
      </PageLayout>
    </>
  );
}

export default About;
