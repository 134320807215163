// Payments.js
import React, { useEffect, useState } from "react";
import TSGBg from "../tsg-bg";
import PaymentCard from "../payments/cards";
import CourseBanner from "../banner/course-banner";
import { regionPrice, formData } from "../../data/refresherPayment"; 
const refresherPayments = React.forwardRef(({
  setIsEnrolOpen, isEnrolOpen, showBanner, courseBanner, setShowBanner,
}, ref) => {
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false); 
  const [paymentOptionsWithSymbols, setPaymentOptionsWithSymbols] = useState([]);

  useEffect(() => {
    if (formData.defaultRegion in regionPrice) {
      const userRegion = regionPrice[formData.defaultRegion];
      if (userRegion.cohorts && userRegion.payments) {
        const paymentOptions = userRegion.payments;
        let updatedPaymentOptions = [];

        if (isFeatureEnabled) {
          updatedPaymentOptions = paymentOptions.map((option) => ({
            ...option,
            price: option.defaultPrice,
            slashPrice: option.defaultSlash,
            symbol: formData.defaultRegion === "Europe - Africa" ? "€" : "c$",
          }));
        } else {
          updatedPaymentOptions = paymentOptions.map((option) => ({
            ...option,
            price: option.price,
            slashPrice: option.slashPrice,
            symbol: formData.defaultRegion === "Europe - Africa" ? "£" : "$",
          }));
        }

        setPaymentOptionsWithSymbols(updatedPaymentOptions);
      }
    }
  }, [isFeatureEnabled]);

  return (
    <TSGBg bgStyle="slopeLeftLow">
      <div ref={ref} className="w-screen lg:w-[calc(100vw-24px)] mt-8 xl:w-[1200px] flex flex-col items-center gap-10 lg:gap-[45px]">
        <h3 className="text-xl md:text-[28px] md:leading-9 text-[#1E1B2E] flex gap-4 font-bold flex-col items-center">
          Flexible and Affordable Pricing
          {/* <ToggleSwitch
            onColor="#F8D210"
            offColor="#ccc"
            isOnInitial={isFeatureEnabled}
            onToggle={handleToggle}
            regionPrice={formData.defaultRegion} // Pass region to ToggleSwitch
          /> */}
        </h3>
        {showBanner && (
          <CourseBanner
            showBanner={showBanner}
            setShowBanner={setShowBanner}
            {...courseBanner}
          />
        )}
        <div className="flex flex-col lg:flex-row w-full max:h-[1050px] h-full gap-4 lg:gap-16 justify-between">
         {paymentOptionsWithSymbols.map((priceOption) => (
          <PaymentCard
            key={priceOption.id}
            card={{
              ...priceOption, // Passing the entire updated price object
              price: priceOption.price, // Will be the price or defaultPrice depending on toggle
              symbol: priceOption.symbol, // Will be the appropriate currency symbol
              slashPrice: priceOption.slashPrice, // Will be the slashPrice or defaultSlash depending on toggle
              userRegion: regionPrice[formData.defaultRegion]
            }}
            setIsEnrolOpen={setIsEnrolOpen}
            isEnrolOpen={isEnrolOpen}
          />
        ))}
        </div>
      </div>
    </TSGBg>
  );
});

export default refresherPayments;
