import React from 'react';
import { AiFillDelete } from 'react-icons/ai';
import styled from 'styled-components';
import Button from '../../button';

const PaymentProfile = ({ setIsEditing }) => {
  const cards = [
    "**** **** **** 4563",
    "**** **** **** 2203"
  ];

  const handleChange = (event) => { }

  return (
    <Container>
      <Header>
        <Title>Payment</Title>
        <Button variant='bg-transparent' outline='black' className='button-size' onClick={() => { setIsEditing(true) }}>Add Payment Method</Button>
      </Header>

      <UserInfo>Card Number</UserInfo>
      <CardContainer onChange={handleChange}>
        {
          cards.map((card, index) => (
            (index === cards.length - 1 && cards.length > 1) ?
              <WithDelete>
                <label key={index}>
                  <input type="radio" value={card} name="cardNumber" onChange={handleChange} />
                  {card}
                </label>
                <DeleteButton>
                  <AiFillDelete color='#333' size={24} />
                </DeleteButton>
              </WithDelete>
              : <label key={index}>
                <input type="radio" value={card} name="cardNumber" onChange={handleChange} />
                {card}
              </label>
          ))
        }
      </CardContainer>

      <UserInfo>To protect your information, we never store your payment data (e.g. card number) but only a token.</UserInfo>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 24px;
  align-self: flex-start;
  padding: 40px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 1px 3px 20px 0px rgba(1, 1, 1, 0.07);

  .button-size {
    width: 208px;
  }

  @media (max-width: 600px) {
    padding: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Title = styled.p`
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;

  @media (max-width: 424px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

const UserInfo = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
`;

const CardContainer = styled.div`
  margin-bottom: 18px;

  input{
    margin-right: 8px;
    width: 18px;
    height: 18px;
  }

  label {
    display: flex;
    align-items: center;
    color: black;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 16px;
  }
`;

const WithDelete = styled.div`
  display: flex;
  align-items: flex-start;
`;

const DeleteButton = styled.div`
  background-color: #D8D8D8;
  padding: 4px;
  margin: 0 8px;
  border-radius: 4px;
  cursor: pointer;
`;

export default PaymentProfile