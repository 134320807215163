import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"
import styled from 'styled-components'
import { FieldName, Input, InputContainer } from '../../../styles/common'
import Button from '../../button'
import { getTokenFromCookie, setCookieWithExpiry } from '../../../utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { postRequest, updateUser, logout } from '../../../redux/action'
import { toast } from'react-toastify'
import Cookies from 'js-cookie'

const EditPasswordProfile = ({ setIsEditing }) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({ mode: "all", defaultValues: {} });

  const password = useRef();
  password.current = watch("newPassword", "");

   const handleLogOut = () => {
    Cookies.remove('token');
    dispatch(logout());
  }
  
  const token = getTokenFromCookie();
  const dispatch = useDispatch();

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onSubmit = async (data) => {
    try {
      const [success, response] = await postRequest(
        "/api/auth/change-password",
        {
          currentPassword: data.currentPassword,
          password: data.newPassword,
          passwordConfirmation: data.confirmPassword,
        },
        token
      );

      if (!success || response?.error) {
        toast.error(
          `${
            response?.error?.message ||
            "An error occured while changing your password"
          }`,
          { autoClose: 2000 }
        );
      } else {
        reset();
        handleLogOut();
        setCookieWithExpiry("token", response?.jwt);
        dispatch(updateUser(response));
        toast.success("Password updated successfully", { autoClose: 2000 });
        setIsEditing(false);
      }
    } catch (error) {
       toast.error(`An error occured while changing your password`, {
        autoClose: 2000,
      });
      console.error(error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Title>Change Password</Title>
        <Button type='submit' variant='bg-transparent' outline='black' className='button-size'>Change Password</Button>
      </Header>

      <InputContainer>
        <FieldName>Current Password</FieldName>
        <Input
          className={errors.currentPassword && "input_error"}
          type={showCurrentPassword ? "text" : "password"}
          placeholder="Current Password"
          {...register("currentPassword", {
            required: true,
          })}
        />
        <EyeIcon onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
          {showCurrentPassword ? (
            <AiOutlineEyeInvisible fontSize="18px" color='black' />
          ) : (
            <AiOutlineEye fontSize="18px" color='black' />
          )}
        </EyeIcon>
        {errors.currentPassword && (
          <div className="requirements">{errors.currentPassword.message}</div>
        )}
      </InputContainer>

      <InputContainer>
        <FieldName>New Password</FieldName>
        <Input
          className={errors.newPassword && "input_error"}
          type={showNewPassword ? "text" : "password"}
          placeholder="New Password"
          {...register("newPassword", {
            required: true,
            minLength: {
              value: 6,
              message: "Password must have at least 6 characters",
            },
            validate: (value) => {
              if (!/[A-Z]/.test(value)) {
                return "Password must contain a uppercase letter";
              }
              if (!/[0-9]/.test(value)) {
                return "Password must contain a number";
              }
              if (!/[^a-zA-Z0-9]/.test(value)) {
                return "Password must contain a special character";
              }
            },
          })}
        />
        <EyeIcon onClick={() => setShowNewPassword(!showNewPassword)}>
          {showNewPassword ? (
            <AiOutlineEyeInvisible fontSize="18px" color='black' />
          ) : (
            <AiOutlineEye fontSize="18px" color='black' />
          )}
        </EyeIcon>
        {errors.newPassword && (
          <div className="requirements">{errors.newPassword.message}</div>
        )}
      </InputContainer>

      <InputContainer>
        <FieldName>Confirm Password</FieldName>
        <Input
          className={errors.confirmPassword && "input_error"}
          type={showConfirmPassword ? "text" : "password"}
          placeholder="Confirm Password"
          {...register("confirmPassword", {
            validate: (value) =>
              value === password.current || "The passwords do not match",
          })}
        />
        <EyeIcon onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
          {showConfirmPassword ? (
            <AiOutlineEyeInvisible fontSize="18px" color='black' />
          ) : (
            <AiOutlineEye fontSize="18px" color='black' />
          )}
        </EyeIcon>
        {errors.confirmPassword && (
          <div className="requirements">{errors.confirmPassword.message}</div>
        )}
      </InputContainer>
    </Form>
  )
}

const Form = styled.form`
  margin-top: 24px;
  align-self: flex-start;
  padding: 40px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 1px 3px 20px 0px rgba(1, 1, 1, 0.07);

  .button-size {
    width: 208px;
  }

  input {
    background-color: #FFF;
    color: #1E1B2E;
    font-size: 14px;
  }

  p {
    color: #333;
  }

  @media (max-width: 600px) {
    padding: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Title = styled.p`
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;

  @media (max-width: 424px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

const EyeIcon = styled.span`
  float: right;
  margin-right: 10px;
  margin-top: -30px;
  position: relative;
`;

export default EditPasswordProfile