import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import InputWrapper from "../inputWrapper";
import Button from "../button";
import { login, hideModal, postRequest } from "../../redux/action";
import styles from "./auth.module.css";
import { logoWhiteImg, whiteEyeIcon, whiteCloseEyeIcon } from "../../images";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });


  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Set a cookie that expires in 10 minutes
const expirationTimeInMinutes = 10;
const expirationDate = new Date(new Date().getTime() + (expirationTimeInMinutes * 60 * 1000));

 const onSubmit = async (data) => {
  const formData = {
    identifier: data.email,
    password: data.password,
  };
  try {
    setLoading(true);
    const [success, response] = await postRequest("/api/auth/local", formData);
    if (response?.error?.message) {
      setLoading(false);
      toast.error(`${response?.error?.message}`, { autoClose: 2000 });
    } else {
      setLoading(false);
      const { jwt, user } = response;

      // Save the JWT token in a cookie to a day for now
      Cookies.set("token", jwt, {
        expires: expirationDate,
      });

      // Check if the account is confirmed
      if (user?.confirmed) {
        reset();
        toast.success(`User Succesfully logged in!`, { autoClose: 2000 });
        dispatch(login(user));
        dispatch(hideModal());
        navigate("/dashboard");
      } else {
        // Account is not confirmed, handle accordingly
        toast.error(`Wrong email or password`, { autoClose: 2000 });
      }
    }
  } catch (error) {
    console.error(error);
    setLoading(false);
    toast.error(`An error occurred submitting your info`, { autoClose: 2000 });
  }
};


  return (
    <section>
      <div className={styles["authContentModal"]}>
        <Link to={'/'} className={styles["imageWrapper"]}>
          <img src={logoWhiteImg} alt="logo" onClick={()=> dispatch(hideModal()) }/>
        </Link>
        <h2 className={styles["authHeader"]}>Log in</h2>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles["formWrapper"]}
        >
          <div className="w-full">
            <InputWrapper
              title="Email"
              type="email"
              placeholder="Example@example.com"
              fieldName="email"
              requiredMessage="Email is required"
              patternMessage="Invalid email address"
              register={register}
              errors={errors}
            />
            <InputWrapper
              inputTag="Password"
              title="Password"
              type={showPassword ? "text" : "password"}
              fieldName="password"
              requiredMessage="Password is required"
              register={register}
              errors={errors}
              icon={showPassword ? whiteEyeIcon : whiteCloseEyeIcon}
              onImageClick={handleShowPassword}
              className={"!text-[14px]"}
              url={"/reset-password"}
              inputLink={"Forgot Password"}
            />
          </div>
          <Button
            type="submit"
            variant={isValid ? "secondary" : "disabled"}
            size
            disabled={!isValid} 
            loading={loading}           
          >
            Log in
          </Button>
        </form>
        <div className={styles["accountTextWrapper"]}>
          <p className={styles["accountText"]}>Don't have an account?</p>
          <Link
            to={"/signup"}
            onClick={() => dispatch(hideModal())}
            className={`${styles["accountText"]} ${styles["signUpText"]}`}
          >
            Sign Up
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Login;