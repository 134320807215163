import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { YellowCheck } from "../../../images";
import XCloseIcon from "../../../images/svg/xCloseIcon";
import { setFormData, postRequest } from "../../../redux/action";
import { FirstLastName } from "../../../styles/common";
import InputWrapper from "../../inputWrapper";
import styles from "./fast-track.module.css";

const FastTrackForm = ({ onClose }) => {
  const modalRef = useRef();
  const { formData } = useSelector((state) => state);
  const dispatch = useDispatch();

    useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const { control, register, handleSubmit, reset, formState: { errors } } = useForm({
    mode: "all",
    defaultValues: { phone_number: "+1" },
  });

 const onSubmit = async (formData) => {
  const enrollResponse = await postRequest("/api/form-fast-tracks", {
    firstname: formData.firstname,
    lastname: formData.lastname,
    email: formData.email,
    phoneNumber: formData.phone_number,
  });

  if (enrollResponse[0] === true) {
    toast.success(enrollResponse[1].message);
    reset();
  } else {
    if (enrollResponse[1]?.details?.errors?.length > 0) {
      toast.error(enrollResponse[1].message);
      for (const error of enrollResponse[1].details.errors) {
        toast.error(error.message);
      }
    } else {
      toast.error("An error occurred. Please try again.", errors);
    }
  }
};


  return (
    <section className={styles["modal-shadow"]}>
      <div className={styles["inner"]} ref={modalRef}>
        <span className={styles["top_btn"]}>
          <XCloseIcon onClick={onClose} fill="white" />
        </span>
        <div className={styles["modal_body"]}>
          <div className={styles["modal_body_left"]}>
            <div className={styles["top_span"]}>
              No previous work experience needed!
            </div>

            <div className={styles["modal_header"]}>
              <span className={styles["sub_header"]}>Business Analysis Fast-Track Course</span>
            </div>
            <div className={styles["modal_desc"]}>
              Fill out this form to register for the upcoming Business Analysis fast-track course.
               You will receive a confirmation email after registration. Subsequently,
               a link will be sent to you to access live session. 
            </div>
            <div className={styles["modal_list"]}>
              <img src={YellowCheck} alt="yellow check" loading="lazy" />
              This course is FREE
            </div>
            <div className={styles["modal_list"]}>
              <img src={YellowCheck} alt="yellow check" loading="lazy" />
              Addresses the fundamentals of business analysis
            </div>
            <div className={styles["modal_list"]}>
              <img src={YellowCheck} alt="yellow check" loading="lazy" />
              Suitable for anyone who wants to gain clarity
            </div>
          </div>

          <div className={styles["modal_body_right"]}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FirstLastName>
                <InputWrapper
                  title="First Name"
                  placeholder="Philips"
                  fieldName="firstname"
                  requiredMessage="Enter a valid firstname"
                  register={register}
                  errors={errors}
                />

                <InputWrapper
                  title="Last Name"
                  placeholder="Dankano"
                  fieldName="lastname"
                  requiredMessage="Enter a valid lastname"
                  register={register}
                  errors={errors}
                />
              </FirstLastName>

              <InputWrapper
                title="Email"
                type="email"
                placeholder="Example@example.com"
                fieldName="email"
                requiredMessage="Email is required"
                patternValue={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
                patternMessage="Invalid email address"
                compoundValidation={true}
                register={register}
                errors={errors}
              />

              <InputWrapper
                inputTag="Phone Input"
                title="Phone Number"
                fieldName="phone_number"
                requiredMessage="Phone number is required"
                compoundValidation={true}
                control={control}
                register={register}
                errors={errors}
              />

              <InputWrapper
                inputTag="Check Box"
                register={register}
                errors={errors}
              />
              <button type="submit" className={styles["submit_btn"]}>
                Register Now
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className={styles.overlay} onClick={onClose} />
    </section>
  )
}

export default FastTrackForm