import React, { useState, useRef } from "react";
import styles from "./cookie.module.css";
import CookieConsent from "react-cookie-consent";
import CookiePreference from "./CookiePreference";
import Button from "../../button";

export default function CookiePopUp() {
  const bannerRef = useRef();
  const [isPrefOpen, setPrefOpen] = useState(false);

  function acceptClicked() {
    bannerRef.current.accept();
  }

  function rejectClicked() {
    bannerRef.current.decline();
  }

  function cookiePrefer() {
    setPrefOpen(true);
  }

  function handleClose() {
    setPrefOpen(false);
  }
  return (
    <div>
      <CookieConsent
        ref={bannerRef}
        overlay={false}
        disableButtonStyles={true}
        buttonText=" "
        containerClasses={styles["container"]}
        cookieName="tsg_Cookie"
        style={{ backgroundColor: "black" }}
      >
        <div
         className={styles['main']}
        >
          <div className={styles['text']}>
            <p>
              This website uses cookies to enhance the user experience.{" "}
              <a
                href="/terms-and-conditions"
                style={{ textDecorationLine: "underline" }}
              >
                <b>Learn More</b>
              </a>
            </p>
          </div>
          <div className={styles.actionbtn}>
            <div>
              <Button
                className={styles.managebtn}
                onClick={cookiePrefer}
              >
                <b>Manage Cookies</b>
              </Button>
            </div>
            <div
             className={styles['buttons']}
            >
              <div>
              <Button
                 outline="white"
                 className={styles['declinebtn']}             
                 onClick={rejectClicked}
              >
                Reject All
              </Button>
              </div>
              <div>
              <Button className={styles['acceptbtn']} variant="secondary" onClick={acceptClicked}>
                  Accept All
              </Button>
              </div>
            </div>
          </div>
        </div>
        {isPrefOpen && <CookiePreference bannerRef={bannerRef} onClose={handleClose} />}
      </CookieConsent>
    </div>
  );
}
