import React from 'react';
import styled from 'styled-components';
import { dotEllipse } from '../../../images';
import styles from './article.module.css';
import Button from '../../button';
import { Link } from 'react-router-dom'; // Add this if you're using react-router

const Card = ({ 
  category, 
  image, 
  author, 
  date, 
  readTime, 
  title, 
  description, 
  link 
}) => {
  return (
    <CardContainer>
      <HeaderContent>
        <CardImage src={image} alt={title} />
        <CardCategory>{category}</CardCategory>
      </HeaderContent>
      <CardContent>
        <CardBody>
          <CardText>
            <span>{author}</span> 
            <img className={styles.cardtext__dot} src={dotEllipse} alt="dot" loading='lazy'/>
            <span>{date}</span> 
            <img className={styles.cardtext__dot} src={dotEllipse} alt="dot" loading='lazy'/>
            <span>{readTime}</span>
          </CardText>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </CardBody>
        <CardFooter>
          <Link to={link}> 
            <Button
              variant='bg-transparent'
              outline='black'
              className={styles['read__more']}
              icon='blackArrowRight'
            >
              Read More
            </Button>
          </Link>
        </CardFooter>
      </CardContent>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  border-radius: 5px 5px 4px 4px;
  overflow: hidden;
  box-shadow: 1px 3px 20px 0 rgba(1, 1, 1, 0.07);
  width: 380px;
  height: 406px;

  @media only screen and (max-width: 992px) {
    width: fit-content;
    height: 430px;
 }
`;

const HeaderContent = styled.div`
  position: relative;
  border-radius: 5px;
`;

const CardImage = styled.img`
  width: 100%;
  height: 180px;
  object-fit: cover;
`;

const CardContent = styled.div`
  padding: 16px 24px 32px;

  @media only screen and (max-width: 992px) {
    height: 250px;
 }
`;

const CardCategory = styled.div`
  background: #000;
  color: #fff;
  padding: 4px 16px;
  position: absolute;
  top: 8px;
  left: 8px;
  border-radius: 16px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
`;

const CardBody = styled.div`
 display: flex;
 flex-direction: column;
 gap: 4px;
 margin-bottom: 40px;
`;

const CardText = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  width: 100%;
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  align-items: center;
  color: #333333;
  opacity: 70%;
`;

const CardTitle = styled.h3`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
`;

const CardDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`;

const CardFooter = styled.div`
`;

export default Card;
