import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { YellowCheck } from '../../../images';
import XCloseIcon from '../../../images/svg/xCloseIcon';
import { FirstLastName } from "../../../styles/common";
import Button from '../../button';
import InputWrapper from '../../inputWrapper';
import styles from './downloadCta.module.css';
import ThankYouk from '../thankYou';
import { postRequest, downloadPdfRequest } from '../../../redux/action';
import { renderValidUrl, downloadPDF } from "../../../utils/constants";
import { toast } from "react-toastify";

const DownloadCta = ({ onClose, courses }) => {
  const modalRef = useRef();
  const [loading, setLoading] = useState(false);
   const [showEnrolNow, setShowEnrolNow] = useState(true);
  const [openThankYou, setOpenThankYou] = useState(false);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all", defaultValues: { phoneNumber: "+1" } });

const onSubmit = async (data) => {
  try {
    const apiUrl = '/api/brochure-downloads';
    const [success, response] = await postRequest(apiUrl, data);

    if (success && response?.message === 'Success') {
      setOpenThankYou(true);
      await downloadPDF(renderValidUrl(courses.course_brochure));
      setShowEnrolNow(false);

      const pabblyData = new URLSearchParams({
        First_name: data.firstName,
        Last_name: data.lastName,
        Email: data.email,
        Phone_number: data.phoneNumber,
        Subscription: data.subscription,
        Course: courses.title
      }).toString();

      const webhookUrl = process.env.REACT_APP_PABBLY_WEBHOOK_URL_BROCHURE;
      const webhookResponse = await fetch(webhookUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: pabblyData,
      });

      if (!webhookResponse.ok) {
        throw new Error('Webhook submission failed');
      }

      const responseText = await webhookResponse.text();
      console.log('Response from Pabbly:', responseText);
    } else {
      throw new Error(response.message || response.error || 'There was an error submitting your info.');
    }
  } catch (error) {
    console.error('Error submitting form:', error);
    toast.error('There was an error submitting your info.');
  }
};

  return (
    <>
    {showEnrolNow && (
    <section className={styles['modal-shadow']}>
      <div className={styles['inner']} ref={modalRef}>
        <span className={styles['top_btn']}>
          <XCloseIcon onClick={onClose} fill="white" />
        </span>
        <div className={styles['modal_body']}>
          <div className={styles['modal_body_left']}>

            <div className={styles['modal_header']}>
             Ready for your {courses.title}  {' '}
              <span className={styles['sub_header']}>Journey?  </span>
            </div>
            <div className={styles['modal_desc']}>
            Download our brochure to see our course offerings.
            </div>
          </div>

          <div className={styles['modal_body_right']}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FirstLastName>
                <InputWrapper
                  title="First Name"
                  placeholder="Philips"
                  fieldName="firstName"
                  requiredMessage="Enter valid firstname"
                  register={register}
                  errors={errors}
                />

                <InputWrapper
                  title="Last Name"
                  placeholder="Dankano"
                  fieldName="lastName"
                  requiredMessage="Enter valid lastname"
                  register={register}
                  errors={errors}
                />
              </FirstLastName>

              <InputWrapper
                title="Email"
                type="email"
                placeholder="Example@example.com"
                fieldName="email"
                requiredMessage="Email is required"
                patternValue={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
                patternMessage="Invalid email address"
                compoundValidation={true}
                register={register}
                errors={errors}
              />

              <InputWrapper
                inputTag="Phone Input"
                title="Phone Number"
                fieldName="phoneNumber"
                requiredMessage="Phone number is required"
                compoundValidation={true}
                control={control}
                register={register}
                errors={errors}
              />

              <InputWrapper
                inputTag="Check Box"
                register={register}
                errors={errors}
              />

              <Button type="submit" size variant="secondary">
                {loading ? 'Downloading...' : 'Get Brochure Now'}
              </Button>
            </form>
          </div>
        </div>
      </div>
      <div className={styles.overlay} onClick={onClose} />
    </section>
    )}
    {openThankYou && (
      <ThankYouk 
        course={courses.title} 
        brochure 
        onClose={() => {
          setOpenThankYou(false);
          onClose();
        }} 
      />
    )}
    </>
  );
};

export default DownloadCta;