import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import avatar from '../../images/avatar/avatar2.png';
import { AiFillDelete } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../button';
import axios from 'axios';
import { updateUser, putRequest, updateAvatar } from '../../redux/action';
import { getTokenFromCookie } from '../../utils/constants';
import { toast } from 'react-toastify';

const PhotoContainer = () => {
  const user = useSelector((state) => state.user);
  const hiddenFileInput = useRef(null);
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState(user?.avatarUrl || '');
  const [showSizeErrorDiv, setShowSizeErrorDiv] = useState(false);
  const dispatch = useDispatch();
  const token = getTokenFromCookie();
  const [isImageSelected, setIsImageSelected] = useState(false);



  useEffect(() => {
    // Free memory whenever this component is unmounted
    return () => {
      if (selectedFile) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [selectedFile, preview]);

  const handleUpload = async () => {
    if (selectedFile && user) {
      try {
        const formData = new FormData();
        formData.append('files', selectedFile);
        formData.append('userId', `${user.id}`);

        const response = await axios.post('/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        });

        const avatarUrl = response.data[0].url;
        await updateUserAvatar(avatarUrl);
      } catch (error) {
        console.error('Error uploading image:', error);
        toast.error('An error occurred while uploading your picture', {
          autoClose: 2000,
        });
      }
    }
  };

  const updateUserAvatar = async (avatarUrl) => {
    try {
      if (user) {
        const [success, responseData] = await putRequest(
          `/api/users/${user.id}`,
          { avatarUrl },
          token
        );

        if (!success || responseData?.error) {
          console.error(responseData?.error?.message);
          toast.error(
            `${
              responseData?.error?.message ||
              'An error occurred while updating your picture'
            }`,
            { autoClose: 2000 }
          );
        } else {
          dispatch(updateUser(responseData));
          toast.success('Profile picture successfully uploaded', {
            autoClose: 2000,
            hideProgressBar: true,
          });
        }
      }
    } catch (error) {
      toast.error('An error occurred while updating your picture', {
        autoClose: 2000,
      });
    }
  };

  const handleProfilePicChange = async ({ target: { files } }) => {
    if (files.length) {
      const imageUrl = URL.createObjectURL(files[0]);
      setSelectedFile(files[0]);
      setShowSizeErrorDiv(files[0].size > 5000000);
      setPreview(imageUrl);
      setIsImageSelected(true);
    }
  };

  const handleDelete = () => {
    setSelectedFile(undefined);
    setPreview('');
    dispatch(updateAvatar(null));
    setIsImageSelected(false);
  };

  const handleClick = () => {
    if (selectedFile) {
      handleUpload();
    } else {
      hiddenFileInput.current.click();
    }
  };

  return (
    <Container>
      <ImageContainer>
        <label htmlFor="upload-button">
          {preview ? (
            <Image src={preview} />
          ) : user?.avatarUrl ? (
            <Image src={user.avatarUrl} />
          ) : (
            <UserInitials>
              {user?.firstname?.[0] || ''} {user?.lastname?.[0] || ''}
            </UserInitials>
          )}
        </label>

        {(user?.avatarUrl || preview) && (
          <DeleteButton onClick={handleDelete}>
            <AiFillDelete color="white" size={24} />
          </DeleteButton>
        )}

        {showSizeErrorDiv && (
          <SizeErrorDiv>
            ⚠ File size too large. <br />
            Max size is 5MB
          </SizeErrorDiv>
        )}
      </ImageContainer>

      <ImageRequirements>
        Upload Image: JPEG or PNG.<br />
        Max size: 5MB.
      </ImageRequirements>

      <Button
        size
        variant="bg-transparent"
        outline="black"
        className="upload-photo"
        onClick={handleClick}
      >
        {isImageSelected ? 'Upload Photo' : 'Select Photo'}
      </Button>

      <input
        type="file"
        ref={hiddenFileInput}
        hidden
        onChange={handleProfilePicChange}
        accept="image/jpg, image/jpeg, image/png"
      />

      {user && (
        <>
          <UserInfo>
            Full Name<br />
            <span className="info-value">
              {user.firstname} {user.lastname}
            </span>
          </UserInfo>
          <UserInfo>
            Email<br />
            <span className="info-value">{user.email}</span>
          </UserInfo>
        </>
      )}
    </Container>
  );
};



const Container = styled.div`
  input[type="file"] { 
    opacity: 0;
    z-index: -1;
    position: absolute;
  }

  input[type="file"]:focus + label {
    outline: 2px solid;
  }
  
  .upload-photo{
    margin-bottom: 24px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  border-radius: 4px;
  background-color: #D8D8D8;
  text-align: center;
  line-height: 350px;

  @media (max-width: 1100px) {
    height: 300px;
    line-height: 300px;
  }
  
  @media (max-width: 800px) {
    height: 200px;
    line-height: 200px;
  }
  
  @media (max-width: 685px) {
    height: 350px;
    line-height: 350px;
  }
  
  @media (max-width: 400px) {
    height: 200px;
    line-height: 200px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 4px;
  object-fit: contain;
`;

const UserInitials = styled.div`
  color: #626262;
  font-size: 100px;
  font-weight: 700;
`;

const DeleteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  padding: 4px;
  margin: 4px;
  border-radius: 4px;
  cursor: pointer;
`;

const ImageRequirements = styled.div`
  width: 100%;
  color: #333333;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  word-wrap: 'break-word';
  margin: 8px auto;
`;

const UserInfo = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 8px;

  .info-value {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
`;

const SizeErrorDiv = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 8px 16px;
  border-radius: 0px 0px 4px 4px;
  background: rgba(233, 30, 38, 0.24);
  backdrop-filter: blur(8px);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`;

export default PhotoContainer;
