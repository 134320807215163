import { SEO, PageLayout, Contact } from "../components";

function ContactPage() {
    return (
        <>
          <SEO
            url="/contact"
            openGraphType="website"
            schemaType="website"
            title="Contact Page"
            description="Contact Us Page"
          />
          <PageLayout>
            <div className="bg-[#FCFCF5] w-full">
              <Contact title={"Get In touch"} />
            </div>
          </PageLayout>
        </>
      );
  }
  
  export default ContactPage;