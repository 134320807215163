import React from "react";

function MatchUpIcon({color}) {
  return (
    <svg
      width="8px"
      height="32px"
      viewBox="0 0 8 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={{color: color}}
    >
      <path d="M4 32V7" stroke="currentColor" strokeWidth="2" />
      <circle
        r="3.5"
        transform="matrix(1 0 0 -1 4 4)"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
}

export default MatchUpIcon;
