import React, { useEffect } from "react";
import Button from "../../button";
import styles from "./thankYou.module.css";
import XCloseIcon from "../../../images/svg/xCloseIcon";
import { imgArray } from "./icon";

const ThankYou = ({
  onClose,
  brochure,
  questAns,
  referSuccess,
  contact,
  partner,
  course
}) => {
  const ModalText = {
    registration:
      "Your form has been submitted, and we have sent you a confirmation email, pls check you inbox",
    brochure:
      "We hope you find the information helpful and informative. We look forward to hearing from you soon.",
    success:
      "Your referral code has been sent to your friend! Once your friend applies using your referral code, you will receive rewards.",
    contact: "We've received your request and will get back to you shortly.",
    partner: "We've received your request and will get back to you shortly.",
  };

  // Filter out WhatsApp icon if partner is true
  const filteredImgArray = partner
    ? imgArray.filter(({ title }) => title !== "WhatsApp")
    : imgArray;

  return (
    <section className={styles["modal-shadow"]}>
      <div className={referSuccess ? styles["success-inner"] : styles["inner"]}>
        <span className={styles["top_btn"]}>
          <XCloseIcon onClick={(e) => {
            e.stopPropagation();
            onClose();
          }} fill="white" />
        </span>
        <div
          className={
            contact || partner
              ? `${styles["modal_title_wrapper"]} ${styles["modal_title_wrapper2"]}`
              : styles["modal_title_wrapper"]
          }
        >
          {referSuccess ? (
            <p className={`${styles["modal_title3"]}`}>Success!</p>
          ) : brochure ? (
            <p className={`${styles["modal_title"]} ${styles["modal_title2"]}`}>
              Thank you for downloading our <br /><span>{course}</span> course brochure!
            </p>
          ) : questAns ? (
            <p className={styles["modal_title3"]}>
              Thank you for registering for our <span>Q & A session</span>
            </p>
          ) : partner ? (
            <p className={styles["modal_title"]}>
              Thank you for expressing interest in partnering with us!
            </p>
          ) : contact ? (
            <p className={styles["modal_title"]}>Thank You for reaching out!</p>
          ) : course ? (
            <p className={styles["modal_title"]}>
              Thank you for registering for our <span>{course}</span> course
            </p>
          ) : (
            <p className={styles["modal_title"]}>Thank you for your registration!</p>
          )}
          <p
            className={
              referSuccess
                ? styles["modal_subTitle3"]
                : brochure
                ? `${styles["modal_subTitle"]} ${styles["modal_subTitle2"]}`
                : styles["modal_subTitle"]
            }
          >
            {referSuccess
              ? ModalText["success"]
              : brochure
              ? ModalText["brochure"]
              : partner
              ? ModalText["partner"]
              : contact
              ? ModalText["contact"]
              : ModalText["registration"]}
          </p>
          <div
            className={
              referSuccess
                ? styles["refer_button_wrapper"]
                : contact || partner
                ? `${styles["modal_button_wrapper"]} ${styles["contact-wrapper"]}`
                : styles["modal_button_wrapper"]
            }
          >
{!partner && (
  <Button
    icon={"yellowTelegram"}
    outline="yellow"
    className={styles["modal_button"]}
    onClick={() => {
      let whatsappLink = "https://chat.whatsapp.com/Eh8Cw4HDftp1nTQq9NftsB"; // Default link

      if (course.toLowerCase().includes("data analysis")) {
        whatsappLink = "https://chat.whatsapp.com/E2MSoWk3C4XJgBT7awrHxU";
      } else if (course.toLowerCase().includes("business analysis")) {
        whatsappLink = "https://chat.whatsapp.com/FeZPTsjRIDTG5ayWyt264Y";
      } else if (course.toLowerCase().includes("agile")) {
        whatsappLink = "https://chat.whatsapp.com/Lhq6mhoHJeH3x9k7QKlpSQ";
      } else if (course.toLowerCase().includes("product management")) {
        whatsappLink = "https://chat.whatsapp.com/DfUYIU1H5neG5A8YQ0owsv";
      }

      window.open(whatsappLink, "_blank");
    }}
  >
    Join our WhatsApp Community
  </Button>
)}


          </div>
          <div className={styles["modal_icon_wrapper"]}>
            {/* Map through filtered image array */}
            {filteredImgArray.map(({ title, icon, url }) => (
              <a key={title} href={url} target="_blank" rel="noopener noreferrer">
                <img src={icon} alt={title} loading="lazy" />
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThankYou;
