import React from "react";
import { CARD_SECOND_COLUMN } from "../../../data";
import Button from "../../button";
import styles from "./course-info.module.css";
import ImageTag from "../../image-tag";

const CourseInfoBenefit = ({ setIsEnrolOpen, course, scrollToPayments, }) => {

  // Trim the course string to remove any leading or trailing whitespace
  const trimmedCourse = course


  return (
    <section className={styles["section_no_background"]}>
      <div className={styles["what_block_body"]}>
        <div className={styles["parent_card_column"]}>
          <div className={styles["column_block"]}>
            {course.text_and_cards.map(
              (item, index) =>
                item.cardFirstColumn &&
                item.cardFirstColumn.map((item, index) => (
                  <div className={styles["card_column_item"]} key={index}>
                    <ImageTag imageUrl={item.icon.url} alt={item.name} />
                    <div className={styles["column_item_title"]}>
                      {item.title}
                    </div>
                    {item.text}
                  </div>
                ))
            )}
          </div>

          <div className={`${styles["column_block"]} ${styles["right"]}`}>
            {course.text_and_cards.map(
              (item, index) =>
                item.secondColumnOne &&
                item.secondColumnOne.map((item, index) => (
                  <div className={styles["card_column_item"]} key={index}>
                    <ImageTag imageUrl={item.icon.url} alt={item.name} />
                    <div className={styles["column_item_title"]}>
                      {item.title}
                    </div>
                    {item.text}
                  </div>
                ))
            )}
          </div>
        </div>

        <div className={styles["what_text_parent"]}>
          <div className={`${styles["tag"]} ${styles["bonus_tag"]}`}>
            <span>+ BONUS:  </span>
             {course.text_and_cards[0]?.topPill}
          </div>

          <div className={styles["what_title"]}>{course.text_and_cards[0]?.sectionTitle}</div>

          <div className={styles["what_text"]}>
            {course.text_and_cards[0]?.description}
          </div>

          <Button className={styles["enrol_btn"]}  onClick={scrollToPayments}>Enrol Now</Button>
        </div>
      </div>
    </section>
  );
};

export default CourseInfoBenefit;
