import React from 'react';
import { useNavigate } from 'react-router-dom';
import { logoWhiteImg } from "../../../../images";
import { ModalContainer, ModalWrapper } from '../../../../styles/common';
import Button from '../../../button';

const RegisterVerifyEmailNotification = () => {
    const navigate = useNavigate();
    const handleLoginClick = () => {
        navigate('/login');
    }
    return (
        <ModalWrapper>
            <ModalContainer>
                <div className='inner_div'>
                    <img className='image' src={logoWhiteImg} alt="nav-logo" loading='lazy'/>
                    <div className='title'>Congratulations!</div>
                    <div className='description'>Your email address has been verified successfully.</div>
                    <div className='btn'>
                        <Button onClick={handleLoginClick} variant='secondary' size>Log in to your account</Button>
                    </div>
                </div>
            </ModalContainer>
        </ModalWrapper>
    );
}

export default RegisterVerifyEmailNotification;