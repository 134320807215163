import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./banner.module.css";
import Button from "../../button";
import { useNavigate } from "react-router-dom";
import {
  PolygonImage
} from "../../../images";

// to handle copy function
async function copyTextToClipboard(text) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}

// to calculate time remaining frm expiry time
function getTimeRemaining(expiry_time) {
  const total = Date.parse(expiry_time) - Date.parse(new Date());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return { days, hours, minutes, seconds };
}

const TimerPromoBanner = ({ expanded, expiry_time, btn_type, btn_message, discount, heading, message, couponCode, rel_link, setShowBanner, courseBanner=false }) => {
  /*
    This component implements the timer banners
    Inputs
    rel_link - Takes input of link within website that redirects when the banner/button is clicked. Default is null
    couponCode - It indicates the Discount Code to be copied to clipboard 
    btn_type - Takes values Copy/Link and indicates instructions of the button.
    btn_message - Message on button.
    expiry_time - Gives the time of Discount expiry for timer.
    expanded - For mobile only. 
    discount - Discount text on banner.
    heading - Discount heading on banner. 
    message - common for all kinds of banner. For copy banner accepts html with tags in <span> indicating to be emboldened. 
    setShowBanner - will be needed to turn off the banner when the time expires
    courseBanner - will be used to change the styles for course banners
  */

  // to handle timer banner click
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const [clickable, setClickable] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (pageWidth > 1000) {
      setClickable(false)
      return
    }

    if (pageWidth <= 500 && expanded) {
      setClickable(false)
      return
    }

    setClickable(true)
  }, [pageWidth]);

  // to handle link click
  const navigate = useNavigate()
 const handleLink = (e) => {
  window.open(rel_link, '_blank', 'noopener noreferrer');
  e.stopPropagation();
}


  // to handle copy click
  const [isCopied, setIsCopied] = useState(false)
  const handleCopy = (e) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(couponCode)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => { setIsCopied(false); }, 1500);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // to handle button click
  const handleClick = (e) => {
    if (btn_type === 'Link') {
      handleLink()
    }
    if (btn_type === 'Copy') {
      handleCopy()
    }
    e.stopPropagation()
  }

  // to handle remaining time in timer
  const [remainingTime, setRemainingTime] = useState(getTimeRemaining(expiry_time))

  // to handle time changes
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime(getTimeRemaining(expiry_time));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [expiry_time]);

  // to check the expiry time is ahead of current time
  const checkExpiry = () => {
    if (remainingTime.days <= -1 && remainingTime.hours <= -1 && remainingTime.minutes <= -1 && remainingTime.seconds <= -1) {
      setShowBanner(false);
    }
  };

  useEffect(() => {
    // component won't show if time has passed expiry time
    checkExpiry()
  }, [remainingTime]
  )

  return (
    <>
      <div
        className={`${styles["banner"]} ${expanded && styles["expanded_banner"]} ${clickable && styles["clickable_banner"]}`}
        onClick={(e) => clickable ? handleClick(e) : null}
      >
        <section className={`${styles['banner_front']} ${expanded && styles['expanded_banner_front']}`}>
          <h1 className={styles["banner_discount"]}>
            {discount}
          </h1>
          <div className={`${styles["banner_description"]} ${expanded && styles["expanded_banner_description"]}`}>
            <h1>{heading}</h1>
            <p>{message}</p>
          </div>
        </section>
        <section className={`${styles["banner_timer"]} ${expanded && styles["expanded_banner_timer"]}`}>
          <div>
            <h1>{remainingTime.days}</h1>
            <p>Days</p>
          </div>
          <span> : </span>
          <div>
            <h1>{remainingTime.hours}</h1>
            <p>Hours</p>
          </div>
          <span> : </span>
          <div>
            <h1>{remainingTime.minutes}</h1>
            <p>Mins</p>
          </div>
          <span> : </span>
          <div>
            <h1 className={styles["animate"]}>{remainingTime.seconds}</h1>
            <p>seconds</p>
          </div>
        </section>
        <section className={expanded && styles["expanded_section"]}>
          {
            (btn_type === 'Copy' || btn_type === 'Link') &&
            <Button
              className={`${styles["btn"]} ${expanded && styles["expanded_btn"]}`}
              onClick={handleClick}
            >
              {btn_message}
            </Button>
          }
          {
            isCopied &&
            <div
              className={`${styles["copy_popup"]} ${expanded && styles["expanded_copy_popup"]} ${courseBanner && styles["course_banner"]}`}
              onClick={() => setIsCopied(false)}
            >
              {/* <img src={PolygonImage} alt="polygon" />
              <div className={styles["copy_popup_message"]}><p>Code copied to the clipboard! 🥳</p></div> */}
            </div>
          }
        </section>
      </div>
    </>
  );
};

TimerPromoBanner.propTypes = {
  heading: PropTypes.string.isRequired,
  discount: PropTypes.string.isRequired,
  btn_type: PropTypes.string.isRequired,
  btn_message: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  expiry_time: PropTypes.string.isRequired,
  couponCode: PropTypes.string,
  rel_link: PropTypes.string,
  expanded: PropTypes.bool,
  setShowBanner: PropTypes.func.isRequired
};

export default TimerPromoBanner;
