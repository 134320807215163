import { SEO, AuthenticatedLayout, Dashboard } from "../components";

function DashboardPage() {
    return (
        <>
          <SEO
            url="/dashboard"
            openGraphType="website"
            schemaType="website"
            title="Dashboard Page"
            description="Dashboard Page"
          />
          <AuthenticatedLayout>
            <div className="bg-[#FCFCF5] w-full">
                <Dashboard/>
            </div>
          </AuthenticatedLayout>
        </>
      );
  }
  
  export default DashboardPage;