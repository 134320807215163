import React from 'react';
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import TSGBg from '../../tsg-bg';
import InstructorCarousel from '../../carousel/instructorsCarousel';


const Instructors = () => {
  const {  courseInstructors } = useSelector((state) => state);
  const isMobile = useMediaQuery({ maxWidth: 992 });

  return (
    <TSGBg bgStyle='slopeLeftHigh' style={{ backgroundSize: isMobile ? '100% 980px' : '100% 560px'}}>
        {courseInstructors &&  <InstructorCarousel items={courseInstructors} />}
    </TSGBg>
  )
}

export default Instructors;