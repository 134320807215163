import { SEO, PageLayout, AllCoursePage } from "../components";
import React from 'react'

const allCoursePages = () => {
  return (
    <>
    <SEO
      url="/all-course"
      openGraphType="website"
      schemaType="website"
      title= "All courses page"
      description="We help you transition into tech seamlessly."
    />
    <PageLayout>
      <div className="bg-[#FCFCF5] w-full">
        <AllCoursePage/>
      </div>
    </PageLayout>
      
    </>
  )
}

export default allCoursePages