import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from 'js-cookie';
import {
  logoWhiteImg,
  cancelWhiteIcon,
  menuBarImg,
  arrowDownWhite,
  arrowUpYellowIcon,
  loginIcon,
  signupIcon
} from "../../images";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "./dropdown";
import { NEW_MENU_DETAILS } from "../../data/menu-header";
import styles from "./navbar.module.css";
import { PopupModal } from "react-calendly";
import Button from "../button";
import Banner from "../banner";
import { CALENDLY_URL } from "../../utils/constants";
import { logout, showModalFunc } from "../../redux/action";

const AuthBar = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isAuthenticated } = useSelector((state) => state);

  const handleLogOut = () => {
    Cookies.remove('token');
    dispatch(logout());
  }


  return (
    <div className={styles["auth_bar"]}>
      {/* <div className={styles["auth"]}>
        {
          isAuthenticated ?
            <div alt="logout" onClick={() => handleLogOut()}><img src={loginIcon} alt='logoutIcon' />Log Out</div>
            :
            <>
              <div alt="login" onClick={() => dispatch(showModalFunc())}><img src={loginIcon} alt='loginIcon' />Log In</div>
              <div alt="signup" onClick={() => navigate('/signup')}><img src={signupIcon} alt='signupIcon' />Sign Up</div>
            </>
        }
      </div> */}
    </div >
  )
}

const Navbar = ({ showBanner, setShowBanner, bannerData, setHeight }) => {
  const [width, setWidth] = useState(window.innerWidth)
  const [menu, setMenu] = useState(window.innerWidth < 992 ? true : false);
  const handleMenu = () => setMenu(window.innerWidth < 992 ? !menu : false);
  const [isOpen, setIsOpen] = useState(false);
  const rootElement = document.getElementById("root");

  const [bootcampDropdown, setBootcampDropdown] = useState(false);
  const [coursesDropdown, setCoursesDropdown] = useState(false);
  const [certificationDropdown, setCertificationDropdown] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
    width < 992 && handleMenu();
  };
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  //choose the screen size
  const handleResize = () => {
    setWidth(window.innerWidth)
    if (window.innerWidth < 992) {
      setMenu(true);
    } else {
      setMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // scrolling

  const handleMenuScroll = () => {
    handleMenu();
  }

  const navbarRef = useRef(null);
  useLayoutEffect(() => {
    // Access the height of the component using ref
    if (navbarRef.current) {
      const navbarHeight = navbarRef.current.clientHeight;
      setHeight(navbarHeight)
    }
  }, [navbarRef?.current?.clientHeight, showBanner, width, menu]);

  const courses = useSelector(state => state.courses);
  const [courseList, setCourses] = useState([]);

  useLayoutEffect(() => {
    if (courses) {
      // Create a Set to store unique course IDs
      const uniqueCourseIds = new Set();

      // Flatten the courses from regions and filter out duplicates
      const uniqueCourses = Object.values(courses)
        .flatMap(region => region.courses)
        .filter(course => {
          // Check if the course ID is already in the Set
          if (uniqueCourseIds.has(course.id)) {
            return false; // Skip duplicates
          } else {
            uniqueCourseIds.add(course.id); // Add the course ID to the Set
            return true;
          }
        });
      setCourses(uniqueCourses);
    }
  }, [courses]);

  // Define handleBootcampMenuClick function
  const handleBootcampMenuClick = (e) => {
    setBootcampDropdown(!bootcampDropdown);
    e.stopPropagation();
  };

  // Define handleCoursesMenuClick function
  const handleCoursesMenuClick = (e) => {
    setCoursesDropdown(!coursesDropdown);
    e.stopPropagation();
  };

  // Define handleCertificationMenuClick function
  const handleCertificationMenuClick = (e) => {
    setCertificationDropdown(!certificationDropdown);
    e.stopPropagation();
  };

  const bootcampList = [
    {
      id: 1,
      title: "Jira and Confluence Training",
      courseDescription: "Description for Bootcamp 1",
      links: [
        { url: "/bootcamp-1/module-1", label: "Cohort 1" },
      ],
    },
    {
      id: 2,
      title: "Business Analysis Fast Track",
      courseDescription: "Description for Bootcamp 2",
      links: [
        { url: "/fast-track", label: "Cohort 1" },
      ],
    },
    // Add more bootcamps as needed
  ];

  const certificationList = [
    {
      id: 1,
      title: "Certified Scrum Product Owner CSPO Certification",
      // courseDescription: "Description for Certification 1",
      links: [
        { url: "/", label: "See Schedule" },
      ],
    },
    {
      id: 2,
      title: "Certified Scrum Master Certification CSM",
      // courseDescription: "Description for Certification 2",
      links: [
        { url: "/", label: "See Schedule" },
      ],
    },
    // Add more certifications as needed
  ];

  return (
    <section className={styles['main']} ref={navbarRef}>
      {
        showBanner &&
        <Banner
          showBanner={showBanner}
          setShowBanner={setShowBanner}
          {...bannerData}
        />
      }
      {
        (width >= 992 || menu) &&
        <AuthBar />
      }
      <div className={styles["header"]}>
        <div className={styles["header_body"]}>
          <div className={styles["logo"]}>
            <Link to="/">
              <img src={logoWhiteImg} alt="nav-logo" loading="lazy" onClick={() => window.scrollTo(0, 0)} />
            </Link>
          </div>
          <div className={!menu ? styles["back_filter"] : ""} onClick={handleMenu} />
          {menu && (
            <button
              title="menu icon"
              className={`${styles["btn"]} ${styles["menu_icon"]} ${styles["cursor_pointer"]}`}
              onClick={handleMenu}
            >
              <img src={menuBarImg} alt="menu icon" loading="lazy" />
            </button>
          )}
          {!menu && (
            <div className={`${styles["nav"]} ${bootcampDropdown || coursesDropdown || certificationDropdown ? styles["long_nav"] : ''}`}>
              <div className={styles["menu_items"]}>
                {
                  !(width >= 992 || menu) &&
                  <AuthBar />
                }
                                  {
                    !(width < 992 && (bootcampDropdown || coursesDropdown || certificationDropdown)) && 
                    <section>
                      <div className={styles["mobile_menu"]}>
                        <img
                          src={cancelWhiteIcon}
                          alt="cancel icon"
                          loading="lazy"
                          onClick={handleMenu}
                          className={styles["arrow_icon"]}
                        />
                      </div>
                      <div
                        className={styles["button_parent"]}
                        onMouseOver={handleCoursesMenuClick}
                        onClick={handleCoursesMenuClick}
                      >
                        <button
                          className={`${styles["nav_title"]} ${styles["btn"]} ${coursesDropdown ? styles["active"] : ''}`}
                        >
                          <span>Courses</span>
                          <img src={coursesDropdown ? arrowUpYellowIcon : arrowDownWhite} alt="down arrow" loading="lazy" />
                        </button>
                      </div>
                      {/* <div
                        className={styles["button_parent"]}
                        onMouseOver={handleCertificationMenuClick}
                        onClick={handleCertificationMenuClick}
                      >
                        <button
                          className={`${styles["nav_title"]} ${styles["btn"]} ${certificationDropdown ? styles["active"] : ''}`}
                        >
                          <span>Certifications</span>
                          <img src={certificationDropdown ? arrowUpYellowIcon : arrowDownWhite} alt="" />
                        </button>
                      </div> */}

                      <Link
                        to={'/about'}
                        className={`${styles["menu_items_border"]} ${styles["nav_title"]} ${styles["shadow_border"]}`}
                      >
                        About Us
                      </Link>
                      {/* <Link
                        to={'/testimonies'}
                        className={`${styles["menu_items_border"]} ${styles["nav_title"]} ${styles["shadow_border"]}`}
                      >
                        Testimonies
                      </Link> */}
                    </section>
                  }
                {
                  (width < 992) &&
                  <Dropdown show={coursesDropdown} menu={courseList} handleMenu={handleMenuScroll} setDropdown={setCoursesDropdown} />
                }
                 {
                (width < 992) &&
                <Dropdown show={certificationDropdown} menu={certificationList} handleMenu={handleMenuScroll} setDropdown={setCertificationDropdown} />
              }
              </ div>

              <div className={styles["buttons_block"]}>
                <Button variant="secondary" onClick={handleOpenModal}>
                  Get Started
                </Button>
              </div>
                {
                  !(width < 992) &&
                  <Dropdown show={bootcampDropdown} menu={bootcampList} handleMenu={handleMenuScroll} setDropdown={setBootcampDropdown} />
                }
              {
                // not mobile
                !(width < 992) &&
                <Dropdown show={coursesDropdown} menu={courseList} handleMenu={handleMenuScroll} setDropdown={setCoursesDropdown} />
              }
              {
                // not mobile
                !(width < 992) &&
                <Dropdown show={certificationDropdown} menu={certificationList} handleMenu={handleMenuScroll} setDropdown={setCertificationDropdown} />
              }
            </div>
          )}
        </div>
      </div >
      <PopupModal
        url={CALENDLY_URL}
        onModalClose={handleCloseModal}
        open={isOpen}
        rootElement={rootElement}
      />
    </section>
  );
};

export default Navbar;
