import React from 'react';
import NumberRoll from '../numberRoll';
import styles from './testimonial.module.css';

const TestimonialNumberRoll = () => {
  return (
    <section className={styles["section_no_background"]}>
    <div className={styles["centered_block"]}>
      <NumberRoll/>
    </div>
  </section>
  )
}

export default TestimonialNumberRoll;