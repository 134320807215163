import { SEO, ResendEmail } from "../components";

function ResendEmailPage() {
  return(
    <>
      <SEO 
        url="/resend-email"
        openGraphType="website"
        schemaType="website"
        title= "Resend Email Page"
        description="Resend Email Page"
      />
        <div className="bg-[#000] w-full h-full lg:h-auto">
          <ResendEmail/>
        </div>
    </>
  )
}

export default ResendEmailPage;