import React, { useState } from 'react';
import styled from 'styled-components';
import styles from './banner.module.css';
import { dot, shareICon } from '../../../images';
import Button from '../../button';
import ShareMenu from '../sharePost';

const Banner = ({
  bannerTitle,
  author,
  date,
  readTime,
  topBanner = styles["top_banner"],
  blockBanner = styles["inf_block"],
  pageTitleStyle = styles["banner_title"],
  iconImg = "",
  shareText = "",
}) => {

  const [isShareMenuVisible, setIsShareMenuVisible] = useState(false);

  const handleShareButton = () => {
    setIsShareMenuVisible(!isShareMenuVisible);
  };

  return (
    <section className={styles["section_no_background"]}>
      <div className={topBanner}>
        <div className={blockBanner}>
          <BannerHead>
            <div className={pageTitleStyle}>
              {bannerTitle}
            </div>
            {author && (
              <BannerDetails>
                <span>{author}</span>
                <img className={styles.dot} src={dot} alt="dot" loading='lazy'/>
                <span>{date}</span>
                <img className={styles.dot} src={dot} alt="dot" loading='lazy'/>
                <span>{readTime}</span>
              </BannerDetails>
            )}
          </BannerHead>
          <Button
            variant='bg-transparent'
            outline='none'
            className={styles['shr_button']}
            onClick={handleShareButton}
            icon={iconImg}
          >
            <p>{shareText}</p>
          </Button>
          {isShareMenuVisible && (
            <ShareMenu />
          )}
        </div>
      </div>
    </section>
  );
}

const BannerHead = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

const BannerDetails = styled.div`
  display: flex;
  gap: 8px;
  font-size: 12px;
  color: #333333;
  align-items: center;
`;

export default Banner;
