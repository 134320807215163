import React, { useState } from 'react';
import styles from './refreshCoursePage.module.css';
import ReactPlayer from 'react-player';
import { StudentSuccess } from "../../components";
import { fastTrackImg } from '../../images';
import { CARD_SECOND_COLUMN } from '../../data';
import { FAST_TRACK_DATA } from '../../data/fastTrack';
import Button from '../button';
import EnrolNow from '../modals/fastTrack';
import Payments from "./refresherPayments";
import { regionPrice, formData } from "../../data/refresherPayment";
import FastTrackSchedule from '../fast-track-schedule/fastTrackSchedules';

const RefresherCourseHero = ({ paymentSectionRef, scrollToPaymentSection }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRegisterClick = () => {
    scrollToPaymentSection(); // Use the shared scroll function
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const { courseDuration, courseTime, bonus, rating } = FAST_TRACK_DATA[1];

  return (
    <>
      <section className={styles["section_no_background"]}>
        <div className={styles["top_banner"]}>
          <div className={styles["banner_info"]}>
            <div className={styles["banner_text"]}>
              <div className='flex gap-2'>
                <div className={styles["tag"]}>Refresher</div>
                <div className={styles["tag"]}>Aspiring tech talent</div>
              </div>

              <div className={`${styles["banner_title"]}`}>
                Business Analysis and Product Ownership Refresher Course
              </div>

              <div className={styles["banner_message"]}>
                Reignite your passion for Business Analysis & Product Ownership! Master essentials + AI & LinkedIn strategies to land your dream job fast!
              </div>
            </div>

            <div className={styles["banner_button"]}>
              <Button
                outline="black"
                variant="secondary"
                className={styles["enrol_now"]}
                onClick={handleRegisterClick}
              >
                Register Now
              </Button>
            </div>
          </div>

          <div className={styles["banner_photo"]}>
            <img src={fastTrackImg} alt="banner" />
          </div>
        </div>
        <div className={styles["first_course_inf"]}>
          <div className={styles["inf_point"]}>
            <div className={styles["point"]}>Course Date</div>
            <div className={styles["info"]}>{courseDuration}</div>
          </div>
          <div className={styles["inf_point"]}>
            <div className={styles["point"]}>Day 1 Course Time</div>
            <div className={styles["info"]}>{courseTime}</div>
          </div>
          <div className={styles["inf_point"]}>
            <div className={styles["point"]}>Day 2 Course Time</div>
            <div className={styles["info"]}>{rating}</div>
          </div>
          <div className={styles["inf_point"]}>
            <div className={styles["point"]}>Includes Bonus</div>
            <div className={styles["info"]}>{bonus}</div>
          </div>
        </div>
      </section>
      <section className={styles["section_first_background"]}>
        <div className={styles["background_section_body"]}>
          <div className={styles["card_row"]}>
            {CARD_SECOND_COLUMN.map(
              (item, index) =>
                item.firstColumn &&
                item.firstColumn.map((item, index) => (
                  <div className={styles["card_item"]} key={index}>
                    <div className={styles["icon_card"]}>
                      <div className={styles['icon-wrapper']}>
                            {typeof item.icon === 'string' ? (
                              <img
                                className={styles['img']}
                                src={item.icon}
                                alt="item icon"
                                loading="lazy"
                              />
                            ) : (
                              <item.icon className={styles['img']} />
                            )}
                      </div>

                      <div className={styles["card_title"]}>{item.title}</div>
                      <div className={styles["card_text"]}>{item.text}</div>
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
      </section>
     

      <FastTrackSchedule />
        <StudentSuccess title="Testimonials"/>
      <div ref={paymentSectionRef}>
        <Payments
          setIsEnrolOpen={() => {}}
          isEnrolOpen={false}
          regionPrice={regionPrice}
          showBanner={false}
          courseBanner={{ title: "Special Offer", description: "Limited Time Only" }}
          setShowBanner={() => {}}
        />
      </div>
       <section className={styles["video_section"]}>
      <p className="font-bold text-[28px] p-9 leading-[36px] text-center text-[#1e1b2e]">Watch this intro video</p>
        <div className={styles["video_container"]}>
          <ReactPlayer
            url="https://youtu.be/bA3TBzW-mlw?si=Us1IlFHrz1noJLGa"
            controls={true}
            width="100%"
            height="100%"
          />
        </div>
      </section>
    </>
  );
};

export default RefresherCourseHero;
